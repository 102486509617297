.personal_affairs_container {
    min-height: 900px;
    max-width: 780px;
    margin: 20px auto;
    padding: 70px 45px;
    border: 1px solid gainsboro;
    border-radius: 10px;
    background-image: url("../../assets/images/bg_template.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.personal_affairs_container_front_cover,.personal_affairs_container_last_cover {
    max-width: 780px;
    margin: 20px auto;
    padding: 70px 40px;
    background-color: #000000 !important;
    background-size: 100% 100%;
}
.personal_affairs_container_last_cover{
    position: relative;
}
.personal_affairs_container_last_cover .last_page_cover_image img{
    width: 780px;
}
.personal_affairs_container_last_cover .last_page_logo_image{
    position: absolute;
    left: 200px;
    top: 700px;
}
.personal_affairs_container_last_cover .last_page_logo_image img{
    width: 400px;
}

.personal_affairs_body_front_cover {
    color: #47C5F3;
    text-align: center;
    font-family: "Merriweather", serif;
    font-weight: 900;
    font-style: normal;
    font-size: 70px;
    user-select: none;
    line-height: normal;
}

.personal_affairs_link_front_cover {
    font-size: medium;
    letter-spacing: 3px;
}

.personal_affairs_link_front_cover:hover {
    color: #47C5F3;
}

.personal_affairs_container .info_header {
    color: #00a2e8;
    text-align: center;
    border-bottom: 2px solid #00a2e8;
}

.personal_affairs_container .info_header h3 {
    margin-bottom: 15px;
    font-weight: bold;
    text-transform: uppercase;
}

.personal_affairs_container .content p {
    margin-top: 20px;
    font-size: 19px;
}

.personal_affairs_container .content .bold {
    font-weight: 700;
}

.personal_affairs_container .MuiInputBase-input, .personal_affairs_container .MuiFilledInput-root {
    padding-top: 6px !important;
    background-color: #f1f4ff;
}

.personal_affairs_container .Mui-focused .MuiInput-input {
    padding-top: 6px !important;
    background-color: #FFFFFF !important;
}

.personal_affairs_container .MuiInputBase-input:hover, .personal_affairs_container .MuiFilledInput-root:hover {
    padding-top: 6px !important;
    background-color: #f1f4ff;
}

.personal_affairs_container .MuiFormControl-fullWidth {
    width: 100% !important;
}

.personal_affairs_container .display-contents {
    display: contents;
}
