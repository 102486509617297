.mainContainer {
    text-align: center;
}
.HandleCheckBtn {
    margin-top: 50px;
}
.HandleCheckBtn h4{
    display: grid;
    justify-content: center;
    height: 20px;
    color: #7030a0;
}

.HandleCheckBtn Button {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    font-weight: bold;
    background-color: #7030a0;
}

.HandleCheckBtn h4{
    font-weight: bold;
}

.HandleCheckBtn p{
    display: grid;
    justify-content: center;
    font-size: 18px;
}
.checkIN p{
    display: grid;
    justify-content: center;
    font-size: 18px;
}
.mainContainer p{
    font-weight: bold;
    font-size: 18px;
    display: grid;
    justify-content: center;
    margin: 0;
    color: black;
}
.datatext {
    padding-top: 60px;
}
.btnall {
    padding-top:10px !important;
}

.message_sent .message_body_text{
    font-size: 14px;
}

.message_sent .message_header_text{
    font-size: 20px;
    font-weight: 600;
}
.message_sent ,.modal-footer{
    border: 0px;
}

.message_sent .invite_btn{
    border-radius: 50px;
    margin: auto;
    margin-top: 10px;
    padding: 5px 30px 5px 30px;
}

@media (max-width: 2780px)  {
    .HandleCheckBtn Button {
        width: 300px;
        height: 300px;
        border-radius: 50%;
        font-weight: bold;
        background-color: #7030a0;
    }
    .mainContainer p{
        font-size: 28px;
    }
}

@media (max-width: 2000px) {
    .HandleCheckBtn Button {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        font-weight: bold;
        background-color: #7030a0;
    }
    .mainContainer p{
        font-size: 18px;
    }
}


@media (max-width: 576px) {
    .user_message{
        display: block !important;
    }
}
