.new_landing_page .scenarios_section title{
    color: #101010;
    text-align: center;
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
}

.scenarios_content .scenarios_card > div {
    height: 100%;
}


@media (max-width: 768px){
    .new_landing_page .scenarios_section title{
        font-size: 30px;
    }
}
@media (max-width: 576px){
    .new_landing_page .scenarios_section title{
        font-size: 28px;
    }
    .new_landing_page .scenarios_section {
        padding: 30px 0 0;
    }
}