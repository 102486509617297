.reviews_section{
    padding-top: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
    padding-bottom: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
}
.reviews_title h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-weight: 600;
    text-align: center;
    color: #000;
}

.slider-box {
    padding: 20px;
    text-align: center;
}

.slider-contentbox {
    position: relative;
}

.slider-contentbox::before {
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    border-radius: 15px;
    border: 2px solid transparent;
    background: linear-gradient(to right, #007BFF, #92BCEA);
    box-shadow: rgba(0, 0, 0, 0.2);
    -webkit-mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    z-index: -1;
}

.reviews_section .scenarios_content .carousel .control-prev {
    left: 42%;
}

.reviews_section .scenarios_content  .carousel .control-arrow {
    background: #d9eafc;
}

.reviews_section .scenarios_content .carousel .control-prev:before {
    background: url(../assets/images/left_side_arrow.svg) no-repeat;
    background-position: 20%, 50%;
    width: 100%;
    height: 100%;
}
.reviews_section .scenarios_content .carousel .control-next:before {
    background: url(../assets/images/right_side_arrow.svg) no-repeat;
    background-position: 20%, 50%;
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 992px){
    .slider-box{
        padding: 10px;
    }
    .slider-box p{
       font-size: 13px;
    }
}

@media screen and (max-width: 768px) {
    .reviews_title h1 {
        font-size: 28px;
    }

}