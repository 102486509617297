.advance_features_container {
    width: 100%;
    height: fit-content;
    padding: 56px 0;
    max-width: 1060px;
    margin: auto;
}
.advance_features_content{
    width: 90%;
    margin: auto;
}
.advance_features_heading {
    color: #101010;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    font-family: 'Bai Jamjuree', sans-serif;
}

.advance_features_card {
    height: 191px;
    border-radius: 22px;
    background: #FFF;
    box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.07);
    border: none;
    margin-top:24px;
}

.advance_features_img .bg-blue{
    background: rgba(0, 142, 255, 0.10);
}
.advance_features_img .bg-pink{
    background: rgba(255, 35, 140, 0.15)
}
.advance_features_img .bg-green{
    background: rgba(0, 216, 35, 0.10);
}
.advance_features_img .bg-yellow{
    background: rgba(255, 184, 0, 0.10);
}
.advance_features_img .bg-valerian{
    background: rgba(198, 77, 255, 0.10);
}
.advance_features_img.bg-info{
    background: rgba(198, 77, 255, 0.10);
}
.advance_features_img .bg-light-green{
    background: rgba(0, 218, 232, 0.10);
}

.features_icon {
    width: 64px;
    height: 64px;
    flex-shrink: 0;
    border-radius: 14px;
    line-height: 64px;
    text-align: center;
}

.advance_features_title {
    color: #101010;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    font-family: 'Bai Jamjuree', sans-serif;
    margin-top: 20px;
}

.advance_features_description {
    color: #797979;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    font-family: 'Bai Jamjuree', sans-serif;
    line-height: normal;
    text-transform: capitalize;
}


.choose_btn {
    width: 196px;
    background: #FF238C;
    border-radius: 12px;
    padding: 18px 0px;
    text-align: center  ;
    color: white;
    font-size: 16px;
    font-weight: 600;
    margin-top: 56px;
}

.choose_btn:hover {
    color: white;
    background: #FF238C;
}

@media (max-width: 768px) {
    .advance_features_heading {
        font-size: 30px;
    }
}

@media (max-width: 576px) {
    .advance_features_heading {
        font-size: 28px;
    }
}

