

.contact_home_section {
    width: 100%;
    padding: 120px 150px 70px;
    background-color: var(--dark);
}

.contact_home_section h2{
    font-weight: 700;
    font-size: 46px;
    font-size: calc(36px + (46 - 36) * ((100vw - 300px) / (1920 - 300)));
    line-height: 1.4;
}

.contact_home_section .sub_title{
    font-size: 18px;
    font-weight: 400;
    color: var(--white);
}

.contact_home_section .mauntains_img{
    width: 550px;
}

.contact_web_section{
    background-image: url('../../../assets/images/contactWeb/img_1.jpg');
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    padding: 35px 120px;
}

.contact_web_section .grediant-btn{
    font-size: 18px;
    font-weight: 500;   
    padding: 10px 26px;
    font-size: 16px;
    background-color: #007bff;
    background: #007bff;
    color: #fff;
    border-radius: 10px;
    border: 1px solid var(--primary);
    cursor: pointer;
}

.contact_web_section .contact_form {
    padding: 35px 0;
    background-color: transparent;
}

.contact_web_section .contact_form .form-control{
    border-radius: 6px;
    background-color: var(--white);
    border-color: rgb(223, 229, 232) !important;
}

.contact_info_container{
    width: 100%;
    padding: 28px;
    max-width: 300px;
    background-color: var(--white);
    box-shadow: 0 16px 68px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
}

.contact_info_container .contact_info_box{
    border-bottom: 1px solid #dfe5e8;
    margin: 0 0 28px 0;
    padding: 0 0 28px 0;
}
.contact_info_container .contact_info_box p{
    margin-bottom: 0;
}
.contact_info_container .contact_info_box:last-child{
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
.contact_info_box svg{
    position: absolute;
    border: 1px solid #DCDCDC;
    border-radius: 50%;
    padding: 4px;
    fill: var(--primary);
    width: 30px !important;
    height: 30px !important;
}

.contact_info_box .info_inner_box{
    position: relative;
    padding-left: 42px;
}

@media screen and (max-width:1440px){
    .contact_home_section{
        padding: 60px 65px 60px;
    }
    .contact_web_section {
        padding: 35px 10px;
        background-size: contain;
    }
}
@media screen and (max-width:1199px){
    .contact_web_section{
        background-image: none;  
    }
    .contact_home_section {
        padding: 45px 50px 45px;
    }
    .contact_web_section .justify-content-end  {
        justify-content: center !important;
    }
    .contact_web_section .contact_info_container{
        max-width: 100%;
    }

}
@media screen and (max-width:575px){
    .contact_home_section {
        padding: 45px 20px 45px;
    }

    .contact_home_section .sub_title{
        margin-top: 1rem;
        font-size: 16px;
    }
}

@media screen and (max-width:400px){
    .contact_info_container{
        padding: 15px;
    }
    .contact_home_section {
        padding: 20px 14px 36px;
    }

    .contact_form .sub_title{
        font-size: 16px;
    }
    .contact_web_section .grediant-btn{
        padding: 8px 12px;
        font-size: 14px;
    }
    .contact_form_btn img{
        width: 17px;
    }
}