a {
    text-decoration: none !important;
}

ul {
    padding: 0;
    margin: 0;
}

.c-dark {
    color: #123769;
    fill: #123769;
}

.c-grey {
    color: #6a6f77;
    fill: #6a6f77;
}

.c-grey-1 {
    color: #666b6d;
    fill: #6a6f77;
}

.c-purple {
    color: #999dfd;
    fill: #999dfd;
}

.c-pink {
    color: #ffb7f6;;
    fill: #ffb7f6;;
}

.c-blue {
    color: #5178e8;
    fill: #5178e8;
}

.c-white {
    color: #fff;
    fill: #fff;
}

.upper {
    text-transform: uppercase;
}

.ls-1 {
    letter-spacing: 1px;
}

.ls-2 {
    letter-spacing: -1px;
}

.section-heading .main-heading h1 {
    font-size: 60px;
}

.section-heading-2 .main-heading h1 {
    font-size: 24px;
}

@media (max-width: 992px) {
    .offset-1 {
        margin-left: 0;
    }
}

.section-heading.width-55 {
    width: 55%;
}

@media (max-width: 992px) {
    .section-heading.width-55 {
        width: 100%;
    }
}

.line {
    border: 1px solid rgba(0, 0, 0, .1);
    width: 100%;
    margin: 30px 0;
}


/*  =============== navigation-wrapper  ================*/

.navigation-wrapper {
    width: 100%;
    position: fixed;
    padding: 24px 0;
    left: 0;
    z-index: 500;
    filter: drop-shadow(0px 35px 25px rgba(94, 215, 254, 0.2));
    transition: all 0.25s ease-out 0s;
}

.navigation-inner {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    transition: all 0.25s ease-out 0s;
}

.navigation .social {
    display: none;
    align-items: center;
    position: absolute;
    bottom: 27px;
    z-index: 2;
    padding: 0 30px 0 40px;
}

.navigation .social h6 {
    font-weight: 700;
    color: #fff;
}

.navigation .social ul {
    list-style: none;
    margin-left: 30px;
}

.navigation .social ul li {
    display: inline-block;
}

.navigation .social ul li:not(:last-child) {
    margin-right: 10px;
}

.navigation .social ul li a {
    display: block;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background-color: #fff;
    position: relative;
    transition: all 0.25s ease-out 0s;
}

.navigation .social ul li a i {
    font-size: 20px;
    color: #5ed7fe;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.navigation .social ul li a:hover {
    background-color: #ff92f2;
}

.navigation .social ul li a:hover i {
    color: #fff;
}

@media (max-width: 350px) {
    .navigation .social {
        width: 100%;
        flex-direction: column;
        justify-content: center;
    }

    .navigation .social ul {
        margin: 16px 0 0 0;
    }

    .imageContainer .img1 {
        width: 50%;
    }

    .imageContainer .img2 {
        width: 5%;
    }
}

.navigation-menu .parent {
    margin: 0;
}

.navigation-menu .parent > li {
    list-style: none;
    display: inline-block;
    position: relative;
}

.navigation-menu .parent > li > a {
    position: relative;
    z-index: 50;
    text-decoration: none;
}

.navigation-menu .parent > li > a > span {
    font-size: 16px;
    font-weight: 700;
    color: white;
}

.navigation-menu .parent > li + li {
    margin-left: 72px;
}

.navigation-menu .parent > li::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 1;
}

.navigation-menu .parent > li .child {
    position: absolute;
    top: 80px;
    opacity: 0;
    visibility: hidden;
    min-width: 250px;
    background-color: #fff;
    filter: drop-shadow(0px 35px 25px rgba(142, 139, 245, 0.1));
    background: linear-gradient(90deg, rgba(153, 157, 253, 1) 0%, rgba(128, 226, 255, 1) 100%);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    transition: all 0.25s ease-out 0s;
    padding: 3px;
}

.navigation-menu .parent > li .child ul {
    background-color: #fff;
    padding: 10px 40px 10px 20px;
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
    border-bottom-left-radius: 17px;
}

.navigation-menu .parent > li .child li {
    list-style: none;
}

.navigation-menu .parent > li .child li a {
    display: block;
    padding: 15px 0 15px 20px;
    transition: all 0.25s ease-out 0s;
    text-decoration: none;
}

.navigation-menu .parent > li .child li a span {
    font-size: 15px;
    color: #6a6f77;
    transition: all 0.25s ease-out 0s;
}

.navigation-menu .parent > li .child li a span::before {
    background-color: #123769;
}

.navigation-menu .parent > li .child li a:hover {
    background-color: #fff;
}

.navigation-menu .parent > li .child li a:hover span {
    color: #123769;
}

.navigation-menu .parent > li:hover .child {
    top: 40px;
    opacity: 1;
    visibility: visible;
}

.navigation-menu .mobile-header {
    display: none;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px 0 20px;
    margin: 21px 0 24px 0;
}

.navigation-menu .mobile-header ul {
    list-style: none;
    margin: 0;
}

.navigation-menu .mobile-header ul li {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.navigation-menu .mobile-header ul li i {
    font-size: 24px;
    color: white;
}

.navigation-menu .mobile-header ul li:nth-child(n + 2) {
    margin-left: 5px;
}

.navigation-menu .search {
    display: none;
    position: relative;
}

.navigation-menu .search input {
    width: 100%;
    height: 55px;
    border: none;
    outline-color: transparent;
    font-size: 16px;
    color: #6a6f77;
    padding: 0 50px 0 28px;
}

.navigation-menu .search button {
    background: transparent;
    border: none;
    position: absolute;
    top: 50%;
    right: 28px;
    transform: translateY(-50%);
}

.navigation-menu .search button i {
    font-size: 16px;
}

.navigation-menu .tagline {
    width: 35%;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #fff;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 23px;
    display: none;
}

.navigation-menu .background-pattern {
    display: none;
}

@media (max-width: 991px) {
    .navigation-menu {
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100vh;
        transition: all 0.25s ease-out 0s;
    }

    .active.navigation-menu {
        position: absolute;
        top: 0;
        left: 0%;
        width: 100%;
        height: 100vh;
        transition: all 0.25s ease-out 0s;
        z-index: 99;
    }

    .navigation-menu .close-button {
        display: none;
        position: relative;
        z-index: 2;
    }

    .navigation-menu .mobile-header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        position: relative;
        z-index: 2;
    }

    .navigation-menu .tagline {
        display: block;
    }

    .navigation-menu .background-pattern {
        display: block;
        top: 0;
        left: 0;
        width: 100%;
    }

    .navigation-menu .parent li {
        display: block;
    }

    .navigation-menu .parent li::after {
        display: none;
    }

    .navigation-menu .parent li > a {
        display: block;
    }

    .navigation-menu .parent li > a > span {
        display: block;
        color: #fff;
        width: 100%;
        padding: 15px 40px;
        font-size: 20px;
        font-weight: 500;
    }

    .navigation-menu .parent li span::before {
        display: none;
    }

    .navigation-menu .parent li.main-search {
        display: none;
    }

    .navigation-menu .parent li.has-child a {
        position: relative;
    }

    .navigation-menu .parent li.has-child a::after {
        content: "\f107";
        font-family: "Font Awesome 5 Free";
        font-size: 20px;
        font-weight: 900;
        color: #fff;
        position: absolute;
        top: 50%;
        right: 40px;
        transform: translateY(-50%);
        transition: all 0.25s ease-out 0s;
    }

    .navigation-menu .parent li.has-child .child {
        box-shadow: none;
        border: none;
        border-radius: 0;
        z-index: 2;
    }

    .navigation-menu .parent li.has-child .child ul {
        border-radius: 0;
    }

    .navigation-menu .parent li.has-child .child a::after {
        content: "\f105";
    }

    .navigation-menu .parent li .child {
        position: relative;
        top: 0 !important;
        opacity: 1 !important;
        visibility: visible;
        display: none;
        padding: 0;
        background-color: #fff;
    }

    .navigation-menu .parent li .child li a {
        padding: 0;
    }

    .navigation-menu .parent li .child li a span {
        display: inline-block;
        color: #6a6f77;
        font-size: 20px;
        font-weight: 500;
        padding: 11.5px 40px;
    }

    .navigation-menu .parent li .child li:first-child {
        padding-top: 19.75px;
    }

    .navigation-menu .parent li .child li:last-child {
        padding-bottom: 19.75px;
    }

    .navigation-menu .parent li + li {
        margin-left: 0;
    }

    .navigation-menu .parent li.has-child.dropped > a::after {
        -webkit-transform: translateY(-50%) rotate(180deg);
        transform: translateY(-50%) rotate(180deg);
    }

    .contact-form-section-pattern .left, .right {
        display: none;
        visibility: hidden;
    }

    .contact-form-wrapper {
        width: 100%;
    }

    .titleContainer {
        visibility: hidden;
    }
}

@media (min-width: 992px) {
    .navigation-menu .has-child .child {
        position: absolute !important;
        max-height: initial !important;
        transition: all 0.25s ease !important;
    }
}

.navigation.scrolled .navigation-wrapper {
    padding: 0 0;
    background-color: #fff;
}

.navigation.scrolled .navigation-inner {
    padding: 17px 0 31px 0;
}

@media (max-width: 991px) {
    .navigation.scrolled .navigation-inner {
        padding: 31px 20px 31px 20px;
    }
}

.navigation.scrolled .navigation-menu .parent li:hover .child {
    top: 57px;
}

.navigation-bar {
    width: 37px;
    height: 16px;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.25s ease-out 0s;
}

.navigation-bar div {
    display: block;
    width: 100%;
    height: 2px;
    background-color: #123769;
}

@media (max-width: 991px) {
    .navigation-bar {
        display: flex;
    }
}

.navigation-logo a img {
    max-width: 100%;
}

.navigation-1 .navigation-logo {
    position: relative;
    height: 33px;
}

.navigation-1:not(.scrolled) .navigation-logo a {
    position: absolute;
    -webkit-transition: all 0.25s ease-out 0s;
    transition: all 0.25s ease-out 0s;
}

.navigation-1:not(.scrolled) .navigation-logo .logo {
    opacity: 0;
    visibility: none;
}

.navigation-1:not(.scrolled) span::before {
    background-color: #fff;
}

.navigation-1:not(.scrolled) .navigation-menu .parent > li > a > span {
    color: #fff;
}

.navigation-1:not(.scrolled) .navigation-bar div {
    background-color: #fff;
}

.navigation-1.scrolled .navigation-logo .logo {
    opacity: 1;
    visibility: visible;
}

.navigation-1.scrolled .navigation-logo .logo-white {
    opacity: 0;
    visibility: hidden;
}

.navigation.shown .navigation-menu {
    top: -36px;
    left: 0;
}

.link-underline > span {
    position: relative;
    transition: all 0.25s ease-out 0s;
}

.link-underline > span::before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    right: 0px;
    bottom: -2px;
    height: 1px;
    background-color: #6a6f77;
    transform-origin: right center;
    transform: scale(0, 1);
    transition: transform 0.3s ease;
}

.link-underline:hover > span {
    text-decoration: none;
}

.link-underline:hover > span::before {
    transform-origin: left center;
    transform: scale(1, 1);
}

@media (max-width: 991px) {
    .navigation.shown.scrolled .navigation-menu {
        top: 0;
    }
}

@media (max-width: 991px) {
    .navigation-inner {
        top: 80px;
        align-items: center;
        padding: 0 20px 0 20px;
    }

    .navigation-inner .search {
        display: block;
    }

    .navigation .social {
        display: flex;
    }


}

@media (max-width: 730px) {
    .navigation-inner {
        top: 50px;
    }

    .questions {
        padding: 0px 0 0 !important;
    }
}

@media (max-width: 576px) {
    .navigation-menu .logo img {
        height: 37px !important;
    }

    .active .keep_img {
        display: none;
    }

    .questions {
        padding: 0px 0 0 !important;
    }

    .titleContainer {
        visibility: hidden;
    }
}

/*========== hero ==========*/
.hero {
    overflow: hidden;
}

.hero .container {
    position: relative;
}

.hero .container .row {
    align-items: center;
}

.hero-image {
    width: 100%;
    height: 100%;
    padding: 100px 0;
    display: flex;
    justify-content: start;
    position: relative;
}

.hero-image img {
    position: relative;
    z-index: 2;
}

@media (max-width: 576px) {
    .hero-image {
        display: block;
        align-items: center;
    }

    .hero-image > img {
        max-width: 100%;
    }
}


@media (max-width: 991px) {
    .hero-image {
        justify-content: center;
        padding: 166px 0 46px 0;
    }
}

@media (max-width: 575px) {
    .hero-image {
        position: static;
        padding: 41px 0 0 0;
    }

    .hero-image > img {
        transform: scale(1);
    }

    .hero-image .hero-absolute-image img {
        transform-origin: top center;
        transform: scale(1);
    }
}

.hero-content {
    position: relative;
    z-index: 2;
    padding: 100px 0;
}

.hero-content img {
    max-width: 100%;
}

.hero-content h1 {
    color: #123769;
    font-size: 60px;
}

@media (max-width: 992px) {
    .hero-content h1 {
        color: #123769;
        font-size: 40px;
    }
}

.hero-content p {
    margin: 30px 0 48px 0;
    font-size: 18px;
    color: #6a6f77;
}
@media (max-width: 658px) {
    .hero-content p {
        margin: 28px 0;
    }
}
@media (max-width: 991px) {
    .hero-content {
        padding: 0 0 93px 0;
        text-align: center;
    }
    .hero-content p {
        margin: 28px 0;
    }
}

@media (max-width: 575px) {
    .hero-content {
        padding: 0 0 82px 0;
        margin-top: 41px;
    }
    .checkin-container .dashboard-card1 {
        padding-top: 0 !important;
    }
    .carousel-root{
         height: 100%;
     }

}


.hero-1 .hero-absolute-image {
    position: absolute;
    top: -1.6px;
    right: -8%;
    z-index: 1;
    overflow: hidden;
}

@media (max-width: 3000px) {
    .hero-1 .hero-absolute-image {
        right: 41%;
    }
}

@media (max-width: 2733px) {
    .hero-1 .hero-absolute-image {
        right: 36%;
    }
}

@media (max-width: 2560px) {
    .hero-1 .hero-absolute-image {
        right: 31%;
    }
}

@media (max-width: 2000px) {
    .hero-1 .hero-absolute-image {
        right: 7%;
    }
}

@media (max-width: 1900px) {
    .hero-1 .hero-absolute-image {
        right: 6%;
    }
}

@media (max-width: 1800px) {
    .hero-1 .hero-absolute-image {
        right: 0%;
    }
}

@media (max-width: 1700px) {
    .hero-1 .hero-absolute-image {
        right: -8%;
    }
}

@media (max-width: 1600px) {
    .hero-1 .hero-absolute-image {
        right: -12%;
    }
}

@media (max-width: 1500px) {
    .hero-1 .hero-absolute-image {
        right: -21%;
    }
}

@media (max-width: 1440px) {
    .hero-1 .hero-absolute-image {
        right: -26%;
    }
}

@media (max-width: 1400px) {
    .hero-1 .hero-absolute-image {
        right: -30%;
    }
}

@media (max-width: 1366px) {
    .hero-1 .hero-absolute-image {
        right: -34%;
    }
}

@media (max-width: 1300px) {
    .hero-1 .hero-absolute-image {
        right: -40%;
    }
}

@media (max-width: 1200px) {
    .hero-1 .hero-absolute-image {
        right: -53%;
    }
}

@media (max-width: 1199px) {
    .hero-1 .hero-absolute-image {
        right: -53%;
    }
}

@media (max-width: 1024px) {
    .hero-1 .hero-absolute-image {
        right: -82%;
    }
}

@media (max-width: 992px) {
    .hero-1 .hero-absolute-image {
        right: -89%;
    }
}

@media (max-width: 991px) {
    .hero-1 .hero-absolute-image {
        right: -8%;
    }
}

@media (max-width: 768px) {
    .hero-1 .hero-absolute-image {
        right: -27%;
    }
}

@media (max-width: 767px) {
    .hero-1 .hero-absolute-image {
        right: -27%;
    }
}

@media (max-width: 600px) {
    .hero-1 .hero-absolute-image {
        right: -22%;
    }
}

@media (max-width: 576px) {
    .hero-1 .hero-absolute-image {
        right: -28%;
    }
}

@media (max-width: 480px) {
    .hero-1 .hero-absolute-image {
        right: -41%;
    }
}

@media (max-width: 480px) {
    .hero-1 .hero-absolute-image {
        right: -72%;
    }
}

@media (max-width: 320px) {
    .hero-1 .hero-absolute-image {
        right: -89%;
    }
}

.download-button-group {
    z-index: 9;
}

.download-button-group .download-button:not(:last-child) {
    margin-right: 18px;
}

.download-button {
    display: inline-block;
    width: max-content;
    padding: 3px;
    text-align: left;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
}

@media (max-width: 576px) {
    .download-button-group .download-button:not(:last-child) {
        margin-right: auto;
    }

    .download-button-group {
        margin-top: 20px !important;
    }

    .download-button {
        margin: 0 auto;
    }
}

@media (max-width: 991px) {
    .download-button-group:not(.download-button-group-1) {
        margin-top: -9px;
    }

    .download-button-group:not(.download-button-group-1) .download-button {
        margin-top: 9px;
    }
}

.download-button-inner::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #fff;
    -webkit-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.download-button-inner {
    display: flex;
    align-items: center;
    padding: 18px 30px 16px 34px;
    background-color: transparent;
    position: relative;
    overflow: hidden;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
}

@media (max-width: 576px) {
    .download-button-inner {
        padding: 10px 30px 10px 34px;
    }

    .download-button-apple .download-button-icon i {
        font-size: 32px;
    }

    .download-button-apple .download-button-icon i {
        font-size: 32px;
    }
}

.download-button-icon {
    position: relative;
    z-index: 2;
}

.download-button-icon i {
    transition: all 0.25s ease-out 0.1s;
    color: #999dfd;
}

.download-button-content {
    position: relative;
    z-index: 2;
    margin-left: 12px;
}

.download-button-content h5 {
    font-size: 10px;
    font-weight: 700;
    color: #6a6f77;
    margin: 0;
    transition: all 0.25s ease-out 0.1s;
}

.download-button-content h3 {
    font-size: 20px;
    color: #123769;
    font-weight: 700;
    margin: 3px 0 0 0;
    transition: all 0.25s ease-out 0.1s;
}

.download-button-google {
    background: rgb(153, 157, 253);
    background: linear-gradient(90deg, rgba(153, 157, 253, 1) 0%, rgba(128, 226, 255, 1) 100%);
    filter: drop-shadow(0px 10px 10px rgba(142, 139, 245, 0.1));
}

.download-button-google .download-button-icon i {
    font-size: 34px;
}

.download-button-apple {
    background: rgba(255, 183, 246, 1);
    background: linear-gradient(90deg, rgba(255, 183, 246, 1) 0%, rgba(153, 157, 253, 1) 100%);
    filter: drop-shadow(0px 10px 10px rgba(255, 86, 234, 0.1));
}

.download-button-apple .download-button-icon i {
    font-size: 34px;
}

.download-button:hover .download-button-inner::after {
    left: 100%;
}

.download-button:hover .download-button-icon i {
    color: #fff;
}

.download-button:hover .download-button-content h3,
.download-button:hover .download-button-content h5 {
    color: #fff;
}

.download-button-1 {
    padding: 0;
    background: transparent;
    box-shadow: inset 0px 0px 0px 5px #fff;
}

.download-button-1 .download-button-inner {
    padding: calc(18px + 3px) calc(30px + 3px) calc(16px + 3px) calc(34px + 3px);
}

.download-button-1 .download-button-inner::after {
    left: 100%;
}

.download-button-1 .download-button-icon i {
    color: #fff;
}

.download-button-1 .download-button-content h3,
.download-button-1 .download-button-content h5 {
    color: #fff;
}

.download-button-1:hover .download-button-inner::after {
    left: 0;
}

.download-button-1:hover .download-button-icon i {
    color: unset;
}

.download-button-1:hover .download-button-content h3 {
    color: #123769;
}

.download-button-1:hover .download-button-content h5 {
    color: #6a6f77;
}

.download-button-1:not(:last-child) {
    margin: 0 0 16px 0 !important;
}

.download-button-1-group {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
}

@media (max-width: 991px) {
    .download-button-1-group {
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        margin: 0 0 0 -20px;
    }

    .download-button-1,
    .download-button-1:not(:last-child) {
        margin: 0 0 0 -32px !important;
    }
}

@media (max-width: 991px) {
    .download-button-group:not(.download-button-group-1) {
        margin-top: -16px;
    }

    .download-button-group:not(.download-button-group-1) .download-button {
        margin-top: 16px;
    }
}

.our_command img {
    max-width: 100%;
    z-index: 2;
}

/* ================== menu_img ==================== */
.menu_img .main_img_box img {
    max-width: 100%;
}


/* ============= app-feature-1 =================*/
.app-feature .col-lg-4 {
    margin-top: 30px;
}

.app-feature-1 .app-feature-wrapper {
    padding: 90px 0 50px 0;
}


.app-feature-2 .app-feature-wrapper {
    padding: 0 0 50px 0;
}

@media (max-width: 991px) {
    .app-feature-2 .app-feature-wrapper {
        padding: 0;
    }
}

.app-feature .section-heading {
    margin: 0 auto 95px;
}

@media (max-width: 991px) {
    .app-feature .section-heading {
        margin-bottom: 30px;
    }
}

.app-feature-row {
    margin-top: 90px;
}

@media (max-width: 991px) {
    .app-feature-row {
        margin-top: 0;
    }
}

.app-feature-single {
    text-align: center;
    position: relative;
    height: 100%;
    overflow: hidden;
    padding: 4px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    filter: drop-shadow(0px 35px 25px rgba(0, 0, 0, 0));
    transition: all 0.25s ease-out 0s;
}

.app-feature-single::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(153, 157, 253);
    background: linear-gradient(0deg, rgba(153, 157, 253, 1) 0%, rgba(128, 226, 255, 1) 50%);
    transition: all 0.4s ease-out 0s;
}

.app-feature-single::after {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 183, 246, 1);
    background: linear-gradient(0deg, rgba(255, 183, 246, 1) 50%, rgba(153, 157, 253, 1) 100%);
    transition: all 0.4s ease-out 0s;
}

.app-feature-single-wrapper {
    padding: 35px 30px 57px 30px;
    height: 100%;
    background-color: #fff;
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
    border-bottom-left-radius: 17px;
    position: relative;
    z-index: 2;
}

@media (max-width: 991px) {
    .app-feature-single-wrapper {
        padding: 15px 30px 30px;
    }
}

.section-heading.width-64 {
    width: 64%;
}

@media (max-width: 991px) {
    .width-64 {
        width: 100% !important;
    }
}

.section-heading .sub-heading {
    display: flex;
    align-items: center;
    margin: 0 auto 18px 0;
}

.section-heading .sub-heading i {
    font-size: 24px;
    margin: 0 8px 0 0;
}

.section-heading .sub-heading h5 {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 3px 0 0 0;
}

@media (max-width: 991px) {
    .section-heading .sub-heading h5 {
        font-size: 16px;
    }
}

.video-section .section-heading-1 .main-heading h1 {
    font-size: 40px;
}

@media (max-width: 576px) {
    .video-section .section-heading-1 .main-heading h1 {
        font-size: 30px;
    }
}

@media (max-width: 576px) {
    .video-section .section-heading-1 .main-heading h1 {
        line-height: 40px;
    }
}

.section-heading.center {
    text-align: center;
    margin: 0 auto;
}

.section-heading.center .sub-heading {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

@media (max-width: 991px) {
    .section-heading.center-responsive {
        text-align: center;
        margin: 0 auto;
    }

    .section-heading.center-responsive .sub-heading {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}

.app-feature-single .icon {
    font-size: 80px;
}

.app-feature-single .icon i {
    font-size: 100px;
}

.app-feature-single .icon i::before {
    background: #ffb7f6;
    background: linear-gradient(to top right, #ffb7f6 30%, #999dfd 50%, #80e2ff 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.app-feature-single h3 {
    font-size: 20px;
    font-weight: 600;
    margin: 9px 0 23px 0;
}

.app-feature-single .circle {
    position: relative;
    overflow: hidden;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    background-color: #ffb7f6;
    font-size: 24px;
    color: #fff;
    position: absolute;
    bottom: -20%;
    right: -20%;
    z-index: 2;
    transition: all 0.25s ease-out 0s;
}

.app-feature-single .circle i {
    font-size: 24px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.3s ease-out 0s;
}

.app-feature-single .circle .hover {
    top: 125%;
}

.app-feature-single .circle:hover .hover,
.app-feature-single .circle.hover .hover {
    top: 50%;
}

.app-feature-single .circle:hover i:not(.hover),
.app-feature-single .circle.hover i:not(.hover) {
    top: -125%;
}

.app-feature-single:hover {
    filter: drop-shadow(0px 35px 25px rgba(255, 86, 234, 0.1));
}

.app-feature-single:hover .circle {
    bottom: 10px;
    right: 10px;
}

.app-feature-single:hover::before {
    bottom: 50%;
    left: 0;
}

.app-feature-single:hover::after {
    top: 50%;
    left: 0%;
}

.app-feature-single-1 {
    filter: drop-shadow(0px 35px 25px rgba(255, 86, 234, 0.1));
}

.app-feature-single-1 .app-feature-single-wrapper {
    padding: 24px 40px 46px;
}

.app-feature-single-1::before {
    bottom: 50%;
    left: 0;
}

.app-feature-single-1::after {
    top: 50%;
    left: 0%;
}

.app-feature-single-2 {
    filter: drop-shadow(0px 35px 25px rgba(255, 86, 234, 0.1));
}

.app-feature-single-2 .icon i::before {
    background: #ffb7f6;
    background: linear-gradient(45deg, #ffb7f6 10%, #999dfd 25%, #80e2ff 40%, #fff 55%, #fff 100%);
    background-size: 300%;
    background-position: bottom left;
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.app-feature-single-2 .icon i::before,
.app-feature-single-2 h3,
.app-feature-single-2 p {
    transition: all 0.25s ease-out 0s;
}

.app-feature-single-2 .circle {
    background-color: #fff;
}

.app-feature-single-2 .circle i {
    color: #ffb7f6;
}

.app-feature-single-2 .app-feature-single-wrapper::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: rgb(153, 157, 253);
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(128, 226, 255, 1)), color-stop(50%, rgba(153, 157, 253, 1)), to(rgba(255, 183, 246, 1)));
    background: linear-gradient(-180deg, rgba(128, 226, 255, 1) 0%, rgba(153, 157, 253, 1) 50%, rgba(255, 183, 246, 1) 100%);
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
    transition: all 0.25s ease-out 0s;
}

.app-feature-single-2:hover .icon i::before {
    background-position: top right;
}

.app-feature-single-2:hover h3,
.app-feature-single-2:hover p {
    color: #fff;
}

.app-feature-single-2:hover .app-feature-single-wrapper::after {
    top: 0;
    opacity: 1;
    visibility: visible;
}

.app-feature-single-2::before {
    bottom: 50%;
    left: 0;
}

.app-feature-single-2::after {
    top: 50%;
    left: 0%;
}


/* ============== Banner-silder =================*/
.banner_slider {
    padding: 170px 0 0;
}

.banner_slider .silder_title {
    color: #6665ca;
    margin-bottom: 2px;
}

.banner_slider .silder_title img {
    max-width: 100%;
}

.carousel_silder .silder_img img {
    max-width: 100%;
}

.banner_slider .carousel-slider {
    height: 70%;
}

.banner_slider .row:last-child {
    max-height: 518px;
}


/* ================= questions ================= */
.questions {
    padding: 40px 0 0;
}

.questions .qu-item img {
    width: 70px;
    margin-right: 20px;
}

.questions .qu-item .qu-text a {
    font-family: "Monotype Corsiva";
    color: #6868ae !important;
    font-weight: 600;
    font-size: 25px;
    text-decoration: underline;
}

@media (max-width: 992px) {
    .genie_img {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }
}

@media (max-width: 576px) {
    .questions .qu-item img {
        width: 40px;
        margin-right: 10px;
    }

    .questions .qu-item .qu-text a {
        font-size: 20px;
    }
}


/* ================= shadow ================= */
.shadow {
    height: 15px;
    background: #fcebfb;
    margin: 50px 0 0;
    filter: blur(2px);
}

/* ================= give_it ================= */
.give_it .give_it_img img {
    max-width: 100%;
}

.give_it .list_item img {
    height: 22px;
    position: relative;
    top: 10px;
}

.give_it .list_item span {
    font-size: 20px;
    font-weight: 500;
    color: black;
}

.give_sub_title {
    font-style: italic;
    font-size: 24px;
}


/* ========== video-section  ==============*/
.video-section-wrapper {
    margin: 100px 0;
}

.video-section .container {
    position: relative;
    padding-top: 10px;
}

.section-heading-1 .sub-heading {
    margin: 0 auto 0 0;
    color: white;
}

.video-section .container .row {
    position: relative;
    z-index: 3;
    padding: 40px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

@media (max-width: 991px) {
    .video-section .container {
        padding: 0 100px;
    }
}

@media (max-width: 360px) {
    .video-section .container {
        padding: 0 20px;
    }
}

/*@media (max-width: 768px) {*/
/*    .video-section .container {*/
/*        padding: 0 80px;*/
/*    }*/
/*}*/
/*@media (max-width: 576px) {*/
/*    .video-section .container {*/
/*        padding: 0 60px;*/
/*    }*/
/*}*/
@media (max-width: 320px) {
    .video-section .container {
        padding: 0 15px;
    }
}

.video-section-video {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

@media (max-width: 991px) {
    .video-section-video {
        justify-content: center;
    }
}

.video-section-video figure {
    position: relative;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.video-section-video figure img {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.video-section-video figure .play {
    position: absolute;
    top: 50%;
    left: -49px;
    transform: translateY(-50%);
    width: 98px;
    height: 98px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 183, 246, 1);
    background: linear-gradient(225deg, rgba(255, 183, 246, 1) 0%, rgba(153, 157, 253, 1) 100%);
}

.video-section-video figure .play a {
    border-radius: 50%;
    background-color: #fff;
    width: 72px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.25s ease-out 0s;
}

.video-section-video figure .play a i {
    font-size: 40px;
    color: #80e2ff;
    transition: all 0.25s ease-out 0s;
}

.video-section-video figure .play:hover a {
    width: 100%;
    height: 100%;
}

.video-section-video figure .play:hover a i {
    font-size: 30px;
}

@media (min-width: 991px) and (max-width: 1200px) {
    .video-section-video figure {
        width: 95%;
    }

    .video-section-video figure img {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .video-section-video {
        margin-bottom: 82px;
    }

    .video-section-video figure {
        width: 100%;
    }

    .video-section-video figure img {
        width: 100%;
    }

    .video-section-video figure .play {
        top: initial;
        bottom: -49px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
    }
}

.drop-shadow-1 {
    filter: drop-shadow(0px 35px 25px rgba(0, 114, 255, 0.4));
}

@media (max-width: 991px) {
    .video-section-content {
        margin-bottom: 5rem;
        text-align: center;
    }
}

.video-section .background-pattern,
.background-pattern-radius .background-pattern-img,
.background-pattern-radius .background-pattern-img,
.background-pattern-radius .background-pattern-gradient {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

@media (max-width: 991px) {
    .video-section .background-pattern {
        top: initial;
        bottom: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 83.3333333333%;
        height: 75%;
    }
}

@media (max-width: 991px) {
    .video-section .background-pattern {
        width: 95%;
    }
}

.background-pattern {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
}

.background-pattern-img {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.15;
    z-index: 2;
    mix-blend-mode: color-burn;
    background-repeat: repeat-x;
    background-position: 0 center;
    animation: animName 50s infinite linear;
}

@keyframes animName {
    0% {
        background-position: 0% center;
    }
    100% {
        background-position: 1000% center;
    }
}

.background-pattern-gradient {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(153, 157, 253);
    background: linear-gradient(45deg, rgba(128, 226, 255, 1) 20%, rgba(153, 157, 253, 1) 50%, rgba(255, 183, 246, 1) 80%);
    z-index: 1;
}

.background-pattern-bottom {
    width: 100%;
    height: 15rem;
    position: absolute;
    bottom: -15rem;
}

.button {
    display: inline-block;
    width: max-content;
    background-color: #000;
    border: none;
    overflow: hidden;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
}

.button-1 {
    background-color: transparent;
    box-shadow: inset 0px 0px 0px 3px #FFFFFF;
}

.video-section .button {
    margin-top: 33px;
}

.button-inner {
    display: flex;
    align-items: center;
    padding: 19px 56px;
    background-color: transparent;
    position: relative;
    overflow: hidden;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    border-bottom-left-radius: 40px;
    cursor: pointer;
}

.button-inner::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #fff;
    transition: all 0.4s ease-in-out 0s;
}

.button-content {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-1 .button-inner::after {
    left: 100%;
}

.button-content h4, .button-content .text {
    color: white;
    font-size: 14px;
    font-weight: 700;
    margin: 3px 0 0 0;
    transition: all .25s ease-out .1s;
}

.button-1:hover .button-inner::after {
    left: 0;
}

.button-1:hover .button-content h4 {
    color: #3a5d90;
}


/*=============== feature-section  ================*/
.feature-section-content .Spacing {
    height: 48px;
}

.feature-section-content .icon-text-group {
    margin-top: 35px;
}

.feature-section-content .button {
    margin-top: 40px;
}

@media (max-width: 991px) {
    .feature-section-content > p {
        padding-right: 0;
    }
}

.feature-section-image {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.feature-section-image .phone {
    position: relative;
    z-index: 3;
    filter: drop-shadow(0px 35px 25px rgba(0, 114, 255, 0.4));
    max-width: 100%;
}

.feature-section-image .image {
    position: absolute;
    top: 50%;
    left: -10%;
    transform: translateY(-50%);
    z-index: 2;
    filter: drop-shadow(0px 35px 25px rgba(0, 114, 255, 0.4));
    max-width: 100%;
}

.feature-section-image .background-pattern {
    width: 100%;
    height: 90%;
    left: initial;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: -70px;
}

.feature-section-spacing-1 .feature-section-wrapper {
    padding: 150px 0 100px 0;
}

@media (max-width: 991px) {
    .feature-section-spacing-1 .feature-section-wrapper {
        padding: 46px 0;
    }
}

.feature-section-spacing-2 .feature-section-wrapper {
    padding: 140px 0 187px 0;
}

@media (max-width: 991px) {
    .feature-section-spacing-2 .feature-section-wrapper {
        padding: 46px 0;
    }
}

.feature-section-spacing-3 .feature-section-wrapper {
    padding: 5rem 0 15rem 0;
}

@media (max-width: 991px) {
    .feature-section-spacing-3 .feature-section-wrapper {
        padding: 5rem 0;
    }
}

@media (max-width: 992px) {
    .feature-section .feature-section-image {
        justify-content: center;
        margin-bottom: 46px;
    }

    .feature-section .feature-section-image .background-pattern {
        left: 50%;
        right: initial;
        transform: translate(-50%, -50%);
    }
}

@media (max-width: 991px) {
    .section-heading .main-heading h1 {
        font-size: 22px;
    }
}

@media (max-width: 320px) {
    .section-heading .main-heading h1 {
        font-size: 25px !important;
        line-height: 1;
    }
}

.feature-section-0 .feature-section-image .image {
    border-top-right-radius: 19px;
    border-bottom-right-radius: 19px;
    border-bottom-left-radius: 19px;
}

@media (max-width: 992px) {
    .feature-section-0 .feature-section-image .image {
        left: -50%;
    }
}

@media (max-width: 991px) {
    .feature-section-0 .feature-section-image .image {
        left: 0%;
    }
}

@media (max-width: 768px) {
    .feature-section-0 .feature-section-image .image {
        left: 0%;
    }
    .carousel-root{
        height: 100%;
    }
}

@media (max-width: 600px) {
    .feature-section-0 .feature-section-image .image {
        left: -13%;
    }
}

@media (max-width: 576px) {
    .feature-section-0 .feature-section-image .image {
        left: -13%;
    }
}

@media (max-width: 575px) {
    .feature-section-0 .feature-section-image {
        justify-content: flex-end;
    }

    .feature-section-0 .feature-section-image .phone {
        max-width: 60%;
    }

    .feature-section-0 .feature-section-image .image {
        left: -5%;
        max-width: 50%;
    }
}

.feature-section-1 .feature-section-content > p {
    padding-right: 0;
}

.feature-section-1 .feature-section-content .icon-text-1-group {
    margin-top: -7px;
}

.feature-section-1 .feature-section-image {
    justify-content: flex-start;
}

.feature-section-1 .feature-section-image .image {
    left: initial;
    right: -20%;
    border-top-left-radius: 19px;
    border-bottom-right-radius: 19px;
    border-bottom-left-radius: 19px;
}

@media (max-width: 992px) {
    .feature-section-1 .feature-section-image .image {
        right: 0%;
    }
}

.feature-section-1 .feature-section-image .background-pattern {
    right: initial;
    left: -70px;
}

@media (max-width: 991px) {
    .feature-section-1 .feature-section-image {
        justify-content: center;
    }

    .feature-section-1 .feature-section-image .background-pattern {
        left: 50%;
        right: initial;
        transform: translate(-50%, -50%);
    }
}

@media (max-width: 600px) {
    .feature-section-1 .feature-section-image .image {
        right: -11%;
    }
}

@media (max-width: 575px) {
    .feature-section-1 .feature-section-image {
        justify-content: flex-start;
    }

    .feature-section-1 .feature-section-image .phone {
        max-width: 60%;
    }

    .feature-section-1 .feature-section-image .image {
        right: -5%;
        max-width: 50%;
    }
}

.icon-text {
    width: 41%;
    text-align: center;
}

.icon-text-group {
    display: flex;
}

.icon-text-group > *:nth-child(n + 2) {
    margin-left: 27px;
}

.icon-text i {
    font-size: 80px;
}

.icon-text i::before {
    background: #ffb7f6;
    background: -webkit-gradient(linear, left bottom, right top, color-stop(30%, #ffb7f6), color-stop(50%, #999dfd), color-stop(70%, #80e2ff));
    background: linear-gradient(to top right, #ffb7f6 30%, #999dfd 50%, #80e2ff 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.icon-text p {
    font-size: 22px;
    color: #3f48cc;
    font-weight: 500;
}

@media (max-width: 991px) {
    .icon-text {
        width: 50%;
    }
}

.icon-text-1 {
    display: flex;
    align-items: center;
}

.icon-text-1 > *:nth-child(n + 2) {
    margin-top: 32px;
}

.icon-text-1 i {
    font-size: 80px;
    margin-right: 26px;
}

.icon-text-1 i::before {
    background: #ffb7f6;
    background: -webkit-gradient(linear, left bottom, right top, color-stop(30%, #ffb7f6), color-stop(50%, #999dfd), color-stop(70%, #80e2ff));
    background: linear-gradient(to top right, #ffb7f6 30%, #999dfd 50%, #80e2ff 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.icon-text-1 svg {
    width: 7.5rem;
}

.icon-text-1 p {
    margin: 12px 0 0 0;
}

.button-2 {
    color: #fff;
    background: rgb(153, 157, 253);
    background: -webkit-gradient(linear, right top, left top, from(rgba(153, 157, 253, 1)), to(rgba(128, 226, 255, 1)));
    background: linear-gradient(270deg, rgba(153, 157, 253, 1) 0%, rgba(128, 226, 255, 1) 100%);
    filter: drop-shadow(0px 10px 10px rgba(142, 139, 245, 0.4));
}

.button-2 .button-inner::after {
    background-color: #999dfd;
}

.button:hover .button-inner::after {
    left: 100%;
}


/*==========  feature-section-1 ===========*/
.background-pattern-radius-reverse .background-pattern-img, .background-pattern-radius-reverse .background-pattern-gradient {
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

.button-3 .button-inner::after {
    background-color: #5ed7fe;
}

.button-3 {
    color: #fff;
    background: rgba(255, 183, 246, 1);
    background: -webkit-gradient(linear, left top, right top, from(rgba(153, 157, 253, 1)), to(rgba(255, 183, 246, 1)));
    background: linear-gradient(90deg, rgba(153, 157, 253, 1) 0%, rgba(255, 183, 246, 1) 100%);
    filter: drop-shadow(0px 10px 10px rgba(255, 147, 242, 0.4));
}


/*================== pricing-section =================*/
.pricing .switch {
    margin: 40px auto 50px auto;
    width: max-content;
}

.pricing .switch .form-switch {
    width: max-content;
}

.pricing .switch label {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
}

.pricing .pricing-slider .swiper-slide {
    width: 20%;
    padding: 0 15px;

}

@media (max-width: 992px) {
    .swiper-slide {
        margin-top: 20px !important;
    }

    .pricing .pricing-slider .swiper-slide {
        width: 50%;
        padding: 0 15px;
    }
}

@media (max-width: 768px) {
    .pricing .pricing-slider .swiper-slide {
        width: 100%;
        padding: 0 15px;
    }
}

@media (max-width: 360px) {
    .pricing-section .pricing .plan-card,
    .pricing-section .pricing .swiper-slide {
        padding: 0;
    }
}

/*.pricing .switch input,*/
/*.pricing .switch input:focus,*/
/*.pricing .switch input:active {*/
/*    background-color: transparent;*/
/*    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e");*/
/*}*/
/*.pricing .switch input:checked {*/
/*    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23999dfd'/%3e%3c/svg%3e");*/
/*    background-color: #fff;*/
/*}*/


.pricing .swiper-container {
    overflow: visible;
}

.pricing-single {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 40px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
}

@media (max-width: 480px) {
    .pricing-single {
        padding: 40px 10px;
    }
}

.pricing-single .plan {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
}

.pricing-single .price {
    font-size: 30px;
    font-weight: 500;
    color: #6a6f77;
    text-align: center;
    overflow: hidden;
    position: relative;
    height: 43px;
    width: 163px;
}
.free_plan{
    margin: 5px 0 50px ;
    color: black;
}
.freeplan_data {
    font-weight: 500;
}

.pricing-single .price .month {
    position: absolute;
    top: -100%;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.25s ease-out 0s;
}

.pricing-single .price .year {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.25s ease-out 0s;
}

.pricing-single .price sup {
    font-size: 14px;
}

@media (max-width: 991px) {
    .pricing-single .price sup {
        font-size: 15px;
    }
}

.pricing-single .price-month .month {
    top: 0;
}

.pricing-single .price-month .year {
    top: 100%;
}

.pricing-single .button {
    width: 100%;
}

.pricing-single .button-content {
    margin: 0 auto;
}

.pricing-single ul {
    list-style: none;
    margin: 60px 0 75px 0;
}

.pricing-single ul li {
    font-size: 16px;
    display: flex;
    font-weight: 500;
    color: #6a6f77;
}

.pricing-single ul li:nth-child(n + 2) {
    margin-top: 15px;
}

.pricing-single ul li::before {
    content: "\f00d";
    font-family: "Line Awesome Free";
    font-size: 14px;
    top: 4px;
    color: #ff3759;
    display: inline-block;
    font-weight: 900;
    width: auto;
    height: auto;
    position: relative;
    margin-right: 10px;
}

@media (max-width: 991px) {
    .pricing-single ul li::before {
        font-size: 16px;
    }
}

.pricing-single ul li.available::before {
    content: "\f00c";
    color: #4defa1;
}

.pricing-single h6 {
    font-size: 12px;
    text-align: center;
    color: #6a6f77;
}

.pricing-single.basic {
    filter: drop-shadow(0px 20px 25px rgba(255, 147, 242, 0.2));
}

.pricing-single.basic .plan {
    color: #ff93f2;
}

.pricing-single.standard {
    filter: drop-shadow(0px 20px 25px rgba(142, 139, 245, 0.2));
}

.pricing-single.standard .plan {
    color: #8e8bf5;
}

.pricing-single.premium {
    filter: drop-shadow(0px 20px 25px rgba(94, 215, 254, 0.2));
}

.pricing-single.premium .plan {
    color: #5ed7fe;
}

.pricing-section-wrapper {
    position: relative;
    padding: 150px 0 50px 0;
}

.pricing-section-wrapper .background-pattern {
    width: 100%;
    height: 56%;
}

.background-pattern .background-pattern-img {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.15;
    z-index: 2;
    mix-blend-mode: color-burn;
    background-position: 0 center;
    background-repeat: repeat-x;
    animation: animName_1 50s infinite linear;
}

@keyframes animName_1 {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -10000px 0;
    }
}

@media (max-width: 991px) {
    .pricing-section-wrapper {
        padding: 50px 0 0 0;
    }
}

.pricing-section .section-heading {
    position: relative;
    z-index: 2;
}

.pricing-section .pricing {
    position: relative;
    z-index: 2;
}

.button-basic {
    color: #fff;
    background: rgba(255, 147, 242, 1);
    background: -webkit-gradient(linear, left top, right top, from(rgba(153, 157, 253, 1)), to(rgba(255, 147, 242, 1)));
    background: linear-gradient(90deg, rgba(153, 157, 253, 1) 0%, rgba(255, 147, 242, 1) 100%);
    filter: drop-shadow(0px 10px 10px rgba(255, 147, 242, 0.4));
}

.button-basic .button-inner::after {
    background-color: #ff93f2;
}

.button-standard {
    color: #fff;
    background: rgba(255, 183, 246, 1);
    background: -webkit-gradient(linear, left top, right top, from(rgba(255, 183, 246, 1)), to(rgba(153, 157, 253, 1)));
    background: linear-gradient(90deg, rgba(255, 183, 246, 1) 0%, rgba(153, 157, 253, 1) 100%);
    filter: drop-shadow(0px 10px 10px rgba(142, 139, 245, 0.4));
}

.button-standard .button-inner::after {
    background-color: #8e8bf5;
}

.button-premium {
    color: #fff;
    background: rgba(94, 215, 254, 1);
    background: -webkit-gradient(linear, right top, left top, from(rgba(94, 215, 254, 1)), to(#00d8ab));
    background: linear-gradient(270deg, rgba(94, 215, 254, 1) 0%, #00d8ab 100%);
    filter: drop-shadow(0px 10px 10px rgba(94, 215, 254, 0.4));
}

.button-premium .button-inner::after {
    background-color: #5ed7fe;
}

.switch .MuiSwitch-colorSecondary.Mui-checked {
    color: #999dfd;
}

.switch .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: white;
}

.switch .MuiSwitch-track {
    border-radius: 16px;
}

.switch .MuiSwitch-root {
    width: 80px;
    height: 59px;
}

.switch .MuiSwitch-thumb {
    width: 27px;
    height: 27px;
}

.switch .MuiSwitch-switchBase {
    top: 16px;
    left: 16px;
    color: white;
}

.switch .MuiSwitch-switchBase.Mui-checked {
    transform: translateX(22px);
}

.switch .MuiIconButton-root {
    padding: 0;
}

.switch .MuiSwitch-track {
    background-color: transparent;
    border: 2px solid white;
    opacity: 1;
}

.switch .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    opacity: 1;
}


/* ================ testimonial-section =============== */
.testimonial-slide {
    display: flex;
    align-items: center;
    width: 100%;
}

@media (max-width: 991px) {
    .testimonial-slide {
        flex-direction: column;
    }
}

.testimonial-slide .image {
    background-color: transparent;
    border-radius: 50%;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.25s ease-out 0s;
}

@media (min-width: 992px) {
    .testimonial-slide .image {
        margin-right: 80px;
    }
}

.testimonial-slide .image-wrapper {
    background-color: #fff;
    border-radius: 50%;
    padding: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.25s ease-out 0s;
}

@media (max-width: 991px) {
    .testimonial-slide .image-wrapper {
        font-size: 16px;
    }
}

.swiper-slide .testimonial-slide .image-inner {
    margin: 0;
    width: 234px;
    height: 100%;
    border-radius: 50%;
    transform-origin: center center;
    transform: scale(1);
    transition: all 0.25s ease-out 0s;
    position: relative;
    overflow: hidden;
}

.testimonial-slide .image-inner img {
    width: 100%;
    height: 100%;
    border: 1px solid #fff;
    position: relative;
    z-index: 1;
    border-radius: 50%;
}

.testimonial-slide .image-inner::after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    border-radius: 50%;
    transition: all 0.25s ease-out 0s;
    opacity: 1;
    visibility: visible;
}

.testimonial-slide .content {
    width: 90%;
    opacity: 1;
    visibility: visible;
    transition: all 0.25s ease-out 0s;
}

.testimonial-slide .content p {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    color: #6a6f77;
}

.testimonial-slide .content h5 {
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    color: #123769;
    margin-bottom: 16px;
}

@media (max-width: 991px) {
    .testimonial-slide .content {
        width: 622px;
        margin-top: 50px;
        text-align: center;
    }
}

@media (max-width: 768px) {
    .testimonial-slide .content {
        width: auto;
    }
}

@media (max-width: 991px) {
    .testimonial-slider {
        margin-top: 10px;
    }
}

@media (max-width: 992px) {
    .testimonial-slider .swiper-slide {
        display: flex;
        height: auto;
    }
}

.testimonial-slider .swiper-slide .image {
    z-index: 10;
    padding: 4px;
    background: rgb(153, 157, 253);
    background: linear-gradient(45deg, rgba(128, 226, 255, 1) 20%, rgba(153, 157, 253, 1) 50%, rgba(255, 183, 246, 1) 80%);
}

.testimonial-slider .swiper-slide .image-inner::after {
    opacity: 0;
    visibility: hidden;
}

.testimonial-slider .swiper-slide-active .content {
    opacity: 1;
    visibility: visible;
}

.testimonial-slider .swiper-slide-prev .image {
    z-index: 1;
    margin-top: 17.5%;
    cursor: pointer;
}

.testimonial-slider .swiper-slide-prev .image-inner::after {
    background: #fff;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, #fff), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(180deg, #fff 20%, rgba(0, 0, 0, 0) 100%);
}

@media (max-width: 991px) {
    .testimonial-slider .swiper-slide-prev .image {
        margin: 0 -50.5% 0 0;
    }

    .testimonial-slider .swiper-slide-prev .image-inner::after {
        background: #fff;
        background: -webkit-gradient(linear, left top, right top, color-stop(20%, #fff), to(rgba(0, 0, 0, 0)));
        background: linear-gradient(90deg, #fff 20%, rgba(0, 0, 0, 0) 100%);
    }
}

.testimonial-slider .swiper-slide-next .image {
    z-index: 1;
    margin-top: -207px;
    cursor: pointer;
}

.testimonial-slider .swiper-slide-next .image-inner::after {
    background: #fff;
    background: -webkit-gradient(linear, left bottom, left top, color-stop(20%, #fff), to(rgba(0, 0, 0, 0)));
    background: linear-gradient(0deg, #fff 20%, rgba(0, 0, 0, 0) 100%);
}

@media (max-width: 991px) {
    .testimonial-slider .swiper-slide-next .image {
        margin: 0 0 0 -50.5%;
    }

    .testimonial-slider .swiper-slide-next .image-inner::after {
        background: #fff;
        background: -webkit-gradient(linear, right top, left top, color-stop(20%, #fff), to(rgba(0, 0, 0, 0)));
        background: linear-gradient(-90deg, #fff 20%, rgba(0, 0, 0, 0) 100%);
    }
}

.testimonial-section-wrapper {
    padding: 165px 0 140px 0;
}

@media (max-width: 991px) {
    .testimonial-section-wrapper {
        padding: 60px 0 50px 0;
    }
}

.testimonial-section-wrapper .swiper-wrapper {
    overflow: hidden;
}

.testimonial-section-wrapper .swiper-wrapper {
    flex-direction: column;
}

@media (max-width: 992px) {
    .swiper-wrapper {
        flex-direction: row;
    }

    .testimonial-slider {
        width: 100%;
    }
}


/* ======== silder ============ */
.testimonial-section .testimonial-slider .carousel {
    height: 100%;
}

.selected .testimonial-slide .image {
    z-index: 10;
    padding: 4px;
    background: rgb(153, 157, 253);
    background: linear-gradient(45deg, rgba(128, 226, 255, 1) 20%, rgba(153, 157, 253, 1) 50%, rgba(255, 183, 246, 1) 80%);
}

.selected .testimonial-slide .image-inner {
    transform: scale(1);
}

.testimonial-section .testimonial-slider .slider-wrapper {
    height: 624px !important;
}

.testimonial-slider .swiper-slide {
    display: flex;
}

@media (max-width: 992px) {
    .testimonial-section .testimonial-slider .slider-wrapper {
        height: auto !important;
    }

    .testimonial-slider .swiper-slide {
        display: flex;
        height: auto !important;
    }

    .testimonial-section-wrapper .swiper-wrapper {
        overflow: hidden;
        height: auto;
    }
}


/* =============== faq-section  ================*/
.faq {
    background-color: transparent;
    border-top-right-radius: 17px;
    border-bottom-right-radius: 17px;
    border-bottom-left-radius: 17px;
}

@media (max-width: 991px) {
    .faq {
        padding: 30px 50px;
    }
}

.faq-wrapper {
    margin: 0 0px;
}

@media (max-width: 991px) {
    .faq-wrapper {
        margin: 0;
    }
}

@media (max-width: 360px) {
    .faq {
        padding: 30px 25px;
    }
}

.faq .accordion-item {
    border: none;
}

.faq .accordion-item:not(:first-child) {
    margin-top: 7px;
}

.faq .accordion-button {
    box-shadow: none;
    font-size: 20px;
    font-weight: 500;
    color: white;
    line-height: 30px;
    width: 100%;
    position: relative;
    background: linear-gradient(to right, #007BFF, #92BCEA);
    border-radius: 12px;
    padding: 1rem 1.25rem !important;
    height: 100% ;
    min-height: 50px ;
}

.MuiAccordionSummary-content{
 margin: 0 !important;
}

.MuiTypography-body1{
    padding: 10px 0;
    color: #555555;
    font-size: 18px !important;
    font-weight: 400 !important;
}

.faq .accordion-button span {
    padding-right: 30px;
}

 .faq-section-wrapper .faq_loader{
    color: #007BFF !important;
    width: 50px !important;
    height: 50px !important;
}

/*.faq .accordion-button::after {*/
/*    content: "\f055";*/
/*    font-family: "Line Awesome Free";*/
/*    color: #5ed7fe;*/
/*    font-size: 32px;*/
/*    display: block;*/
/*    background: none;*/
/*    width: auto;*/
/*    height: auto;*/
/*    transition: all 0.25s ease-out 0s;*/
/*}*/

/*.faq .accordion-button:not(.collapsed)::after {*/
/*    content: "\f056";*/
/*    color: #ff93f2;*/
/*    transform: rotate(-180deg);*/
/*}*/

.faq .accordion-body {
    padding: 20px 30px 10px 12px;
}

.faq .accordion-body p {
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    color: #6a6f77;
}

.faq-wrapper {
    padding: 0.4rem;
    background: rgb(153, 157, 253);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, rgba(128, 226, 255, 1)), color-stop(50%, rgba(153, 157, 253, 1)), color-stop(80%, rgba(255, 183, 246, 1)));
    background: linear-gradient(180deg, rgba(128, 226, 255, 1) 20%, rgba(153, 157, 253, 1) 50%, rgba(255, 183, 246, 1) 80%);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    filter: drop-shadow(0px 35px 25px rgba(255, 86, 234, 0.1));
}

.faq-section-1 .faq-section-wrapper {
    padding-bottom: 15rem;
}

@media (max-width: 991px) {
    .faq-section-1 .faq-section-wrapper {
        padding-bottom: 5rem;
    }
}

.faq-section-2 .faq-section-wrapper {
    padding: 15rem 0;
}

@media (max-width: 991px) {
    .faq-section-2 .faq-section-wrapper {
        padding: 5rem 0;
    }
}

.faq-section-3 .faq-section-wrapper {
    padding: 5rem 0 15rem 0;
}

@media (max-width: 991px) {
    .faq-section-3 .faq-section-wrapper {
        padding: 5rem 0;
    }
}

.faq-section .section-heading {
    margin-bottom: 44px;
}

@media (max-width: 991px) {
    .faq-section .section-heading {
        margin-bottom: 20px;
    }
}

.accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
}

.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
}

.faq-section .MuiAccordionSummary-root {
    padding: 0 !important;
}

.faq-section .MuiAccordionDetails-root {
    display: flex;
    padding: 0px 16px 0px !important;
}

.faq-section .MuiAccordion-root {
    margin-bottom: 10px !important;
}


/* ============= contact-form-section  ================*/
.contact-form {
    background: rgb(153, 157, 253);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(20%, rgba(128, 226, 255, 1)), color-stop(50%, rgba(153, 157, 253, 1)), color-stop(80%, rgba(255, 183, 246, 1)));
    background: linear-gradient(180deg, rgba(128, 226, 255, 1) 20%, rgba(153, 157, 253, 1) 50%, rgba(255, 183, 246, 1) 80%);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 4px;
}

.contact-form-wrapper {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: #fff;
    padding: 54px 78px 76px 78px;
}

.contact-form-spacing {
    padding: 54px 140px 76px !important;
}

@media (max-width: 991px) {
    .contact-form-wrapper {
        padding: 50px 46px 46px 46px;
    }
    .contact-form-spacing {
        padding: 54px 40px !important;
    }
}

@media (max-width: 991px) {
    .contact-form-wrapper {
        padding: 50px 16px 46px;
    }
}

.contact-form .section-heading {
    margin-bottom: 25px;
}

@media (max-width: 991px) {
    .contact-form .section-heading {
        margin-bottom: 30px;
    }
}

.contact-form-icon {
    text-align: center;
}

.contact-form-icon i {
    font-size: 200px;
}

.contact-form-icon i::before {
    background: #ffb7f6;
    background: -webkit-gradient(linear, left bottom, right top, color-stop(30%, #ffb7f6), color-stop(50%, #999dfd), color-stop(70%, #80e2ff));
    background: linear-gradient(to top right, #ffb7f6 30%, #999dfd 50%, #80e2ff 70%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.contact-form .button {
    width: 100%;
    margin-top: 20px;
    padding: 0;
}

.contact-form .button-inner {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-form-1 .section-heading {
    margin-bottom: 42px;
}

@media (max-width: 991px) {
    .contact-form-1 .section-heading {
        margin-bottom: 10px;
    }
}

.contact-form-section-wrapper {
    position: relative;
    padding: 150px 0;
}

.contact-form-section-wrapper .container {
    position: relative;
    z-index: 2;
}

.contact-form-section-1 .contact-form-section-wrapper {
    padding: 150px 0 0 0;
}

.contact-form-section-pattern .left {
    position: absolute;
    top: 20%;
    left: 0;
    z-index: 1;
    width: 18.5%;
    height: 279px;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
}

.contact-form-section-pattern .right {
    position: absolute;
    bottom: 20%;
    right: 0;
    z-index: 1;
    width: 18.5%;
    height: 279px;
    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: cover;
}

@media (max-width: 991px) {
    .contact-form-section-pattern .left {
        width: 26%;
    }

    .contact-form-section-pattern .right {
        width: 26%;
    }
}

@media (max-width: 991px) {
    .contact-form-section-wrapper {
        padding: 30px 0 50px 0;
    }

    .contact-form-section-1 .contact-form-section-wrapper {
        padding: 30px 0 50px 0;
    }

    .contact-form-section-row div[class*="col"]:nth-child(n + 2) {
        margin-top: 30px;
    }
}

.contact-details .section-heading {
    margin-bottom: 45px;
}

@media (max-width: 991px) {
    .contact-details .details-row div[class*="col"]:nth-child(n + 2) {
        margin-top: 30px;
    }
}

.form-floating {
    position: relative;
}

.form-floating .input {
    height: 63px;
    font-size: 16px;
    font-weight: 500;
    color: #666b6d;
    padding: 0 10px;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #e1e1ec !important;
}

.form-floating .input:focus,
.form-floating .input:active {
    border-color: #999dfd;
    outline: none;
    box-shadow: none;
}

.form-floating label {
    padding: 19px 1px;
    font-size: 16px;
    font-weight: 500;
    color: #666b6d;
}

.form-floating:nth-child(n + 2) {
    margin-top: 27px;
}

.form-floating .textarea {
    height: 100%;
    padding: 23px 10px !important;
    resize: none;
}

.form-floating.textarea-form {
    height: 100%;
}

.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
    transform: scale(0.85) translateY(-20px) translateX(1px);
}

.form-floating > label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 10px 7px;
    pointer-events: none;
    border: 1px solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out;
}

.form-floating .input:focus, .form-floating .input:active {
    border-color: #999dfd !important;
    outline: none;
    box-shadow: none;
}

.contact-form-section input::-webkit-input-placeholder {
    color: #fff;
}

.contact-form-section input:-ms-input-placeholder {
    color: #fff;
}

.contact-form-section input::-ms-input-placeholder {
    color: #fff;
}

.contact-form-section input::placeholder {
    color: #fff;
}


/* ================== foote ===================== */
.footer-wrapper {
    position: relative;
    padding: 150px 0 150px 0;
}

@media (max-width: 991px) {
    .footer-wrapper {
        padding: 50px 0;
    }
}

@media (max-width: 576px) {
    .footer-wrapper {
        padding: 100px 0;
    }

    .imageContainer .img1 {
        width: 91%;
    }
}

.footer-row {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 2;
}

@media (max-width: 991px) {
    .footer-row {
        flex-direction: column;
    }

    .footer-row > div:nth-child(n + 2) {
        margin-top: 30px;
    }
}

.footer-detail {
    flex: 0.5;
}

.footer-detail a img {
    max-width: 100%;
}

.footer-detail p {
    margin: 40px 0;
}

@media (max-width: 991px) {
    .footer-detail p {
        margin: 20px 0;
    }
}

.footer-detail .link-underline {
    font-size: 16px;
    font-weight: 600;
    color: #666b6d;
    display: block;
}

.footer-detail .link-underline span::before {
    background-color: #666b6d;
}

.footer-detail .link-underline:nth-child(n + 2) {
    margin-top: 9px;
}

.footer-list h6 {
    font-weight: bold;
    color: #123769;
    margin-bottom: 50px;
}

.footer-list li {
    list-style: none;
}

.footer-list li a {
    font-size: 17px;
    font-weight: bold;
    line-height: 40px;
    color: #666b6d;
}

.footer-list li a i {
    margin-right: 4px;
}

.footer-list li a span::before {
    background-color: #666b6d;
}

@media (max-width: 991px) {
    .footer-list h6 {
        margin-bottom: 15px;
    }
}

.footer-social li i {
    font-size: 20px;
}

.footer-copyright {
    position: relative;
    z-index: 2;
}

.footer-copyright h6 {
    font-size: 10px;
}

@media (max-width: 991px) {
    .footer-copyright {
        margin-top: 50px;
    }
}

.footer-pattern {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 150px;
    background-position: bottom center;
    transform: rotate(180deg);
}

.social-gradient .twitter i {
    color: #81dfff;
}

.social-gradient .facebook i {
    color: #88b6fe;
}

.social-gradient .linkedin i {
    color: #ac9dfc;
}

.social-gradient .youtube i {
    color: #efb2f7;
}

.textconvey p {
    font-size: 28px !important;
    text-decoration: none !important;
    font-family: "Monotype Corsiva";
    color: #6868ae !important;
    font-weight: 600;
}

.contact-form-section1 .contact-form-section-wrapper1 {
    position: relative;
    padding: 0;
    top: 3px;
    width: 100%;
}

.packageData {
    color: black;
    font-size: 15px;
}

.modelcontact .modal-header .close {
    margin: -1.5rem -1rem -1rem auto !important;
}

.keep_img {
    cursor: pointer;
}

.titleContainer {
    cursor: pointer;
}

@media (max-width: 576px) {
    .navigation-logo img:first-child {
        width: 90%;
    }

    .control-dots {
        display: none;
    }
}

.app-feature-single:hover .data_card strong {
    color: white;
}

.imageContainer .img2 {
    cursor: pointer;
}

.modal-title p {
    margin-bottom: 0;
}

.group_model.modal-title p,
.group_model .close span {
    color: white;
}

.group_model .background-pattern {
    z-index: 0;
}

/*  ====== Header =====*/
.privacy-policy-section .header-section {
    background-image: linear-gradient(to right, #007BFF, #7bb2fd);
}

.privacy-policy-section header {
    position: relative;
}

.work_container {
    background: linear-gradient(45deg, rgba(128, 226, 255, 1) 20%, rgba(153, 157, 253, 1) 50%, rgba(255, 183, 246, 1) 80%);
    border-radius: 15px;
    border-top-left-radius: 0;
    padding: 5px;
    width: 1140px;
}

.work_container > div {
    background: white;
    border-radius: 15px;
    border-top-left-radius: 0;
    padding:  34px 80px;
}

.model_group_footer {
    background: #00aae4 !important;
    border-radius: 0px !important;
    font-weight: 700;
    border: none;
}

.model_group_footer:hover {
    background: #00aae4;
}

.app-feature-single:hover h5 {
    color: white !important;
}

.keepinTouch {
    font-size: 14px !important;
}

@media (min-width: 992px) {
    .massagemodel .modal-lg {
        max-width: 984px;
    }
}

.checkin-container .checkinMsg {
    color: #6868ae;
    font-weight: 600;
    font-size: 20px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

.navigation-wrapper .plusWord{
    color: #fff;
    font-family: cursive;
}

@media (max-width : 576px) {
    .work_container > div {
        padding: 20px;
    }
}
.data_keys {
    max-height: 550px;
}

.google-map-container{
    width: 100%;
    display: flex;
    justify-content: center;
}

.contact-form-container{
    max-width: 1024px;
    margin: 50px auto;
}

.contact-form-container .contact-form-wrapper{
    border-top: 1px solid gainsboro;;
}