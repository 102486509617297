/*.footer {*/
/*  display: none;*/
/*}*/

.custom-header {
  background-color: #7030a0;
  border-color: #7030a0;
  height: 48px;
  justify-content: center;
  align-items: center;
  color: white;
  display: flex;
}
.custom-link {
  background-color: #7030a0;
  border-color: #7030a0;
  color: white;
  justify-content: center;
  align-items: center;
}
.custom-link-btn {
  background-color: #00aae4;
  border-color: #00aae4;
  color: white;
  justify-content: center;
  align-items: center;
}
.hoy-to-link-btn{
  background-color: #0069d9;
  border-color: #0069d9;
  color: white;
  justify-content: center;
  align-items: center;
}
.hoy-to-link-btn:hover{
  background-color: #00aae4;
  border-color: #00aae4;
  color: white;
}
.header-title  {
  font-size: 22px;
}
.fixed-right {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
}
.genie{
  height:35px;
  cursor: pointer;
}
.legend-content {
  margin-left: 350px;
  width: 45%;
  line-height: 1.5;
  letter-spacing: 0.0425em;
  box-shadow: 5px 5px 7px grey;
  margin-bottom: 24px;
  position: relative;
}
.releaseDays-label {
  margin-left: 30px;
}

.HighLightIcon HighlightOffIcon{
  left: 50px !important;
}
.HighLightIcon{
  position: relative;
}

.HighLightIcon .abc{
  position: absolute;
  right: -4px;
  top: -3px;
  cursor: pointer;
}
.HighLightIcon .abc:hover{
  color:red;
}
.modelHeaders{
  background: #7030a0;
  color: #fff;
}
.modelHeaders span{
  color: #fff;
}
.icons{
  top: 5px;
  position: relative;
}
.MyWishes-TableContainer table td p{
  margin-bottom: 0;
}
.MyWishes-TableContainer{
  padding-left: 16px;
}
/*
.MyWishes-TableContainer .notes-content-stable,
.MyWishes-TableContainer .MuiTableCell-root {
  border: 1px solid #80808069;
}
*/

.MyWishes-TableContaine .card_header{
  background: #00a3e8;
}
