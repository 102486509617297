.features_container {
    height: fit-content;
    padding: 60px 0;
    background: #F9F9F9;
}

.features_content .features_heading {
    color: #101010;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    font-family: 'Bai Jamjuree', sans-serif;
    line-height: 64px;
    text-transform: capitalize;
    margin-bottom: 36px;
}

.features_card_container {
    max-width: 1064px;
    margin: auto;
}

.featured_first {
    display: flex;
}

.featured_second {
   display: flex;
}

.features_card_container .features_card {
    border-radius: 22px;
    background: #FFF;
    box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.06);
    border: none;
    width: 100%;
    margin-left: 20px;
    margin-top: 20px;
    min-height: 318px;
}

.features_card .features_title {
    color: #101010;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    font-family: 'Bai Jamjuree', sans-serif;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 20px;
}

.features_card .features_description {
    color: #797979;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    font-family: 'Bai Jamjuree', sans-serif;
    line-height: 36px;
    text-transform: capitalize;
}

@media (max-width: 1024px) {

    .featured_first,
    .featured_second {
        padding: 20px;
        grid-template-columns: 100%;
    }
}

@media (max-width: 768px) {

    .featured_first,
    .featured_second {
        padding: 20px;
        grid-template-columns: 100%;
    }

    .features_heading {
        font-size: 28px;
        line-height: normal;
    }
}