.paswword-not-match {
  text-transform: capitalize;
  margin-top: 10px;
  font-weight: 600;
}
.error {
  color: red;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 600;
}
.cancel-btn,
.submit-btn {
  border: 1px solid inherit;
  padding: 10px 20px;
  outline: none;
  background: transparent;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}
.cancel-btn {
  border: 1px solid #e5e5e5;
  color: black;
}
.cancel-btn:hover {
  border: 1px solid #e5e5e5;
  color: black;
  background: transparent;
}
.submit-btn {
  background: #0069d9;
  color: white;
  border-color: #0069d9;
  margin-left: 10px;
}

.submit-btn:hover {
  border: 1px solid #96d296;
  color: white;
  background-color: #7030a0;
}

.change-password-section .card-header {
  font-size: 21px;
  font-weight: bold;
}
.change-password-section ::placeholder {
  font-size: 18px;
}
.change-password-section label {
  font-size: 18px;
  font-weight: 600;
}

@media only screen and (max-width: 768px) {
  .change-password-section .card {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
}

@media only screen and (max-width: 540px) {
  .change-password-section .card {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .common-header-section h1 {
    font-size: 25px;
  }
  .common-header-section .title-section {
    padding: 30px 0 !important;
  }
}

.hederpassword {
  background-color: #c898ec;
  color: white;
}
.change-password-section .card {
  max-width: 35rem;
  border: 1px solid #c898ec !important;
}

.text-right .isLoading {
  width: 150px;
}

.PasswordHandleIcon {
  position: absolute;
  right: 15px;
  top: 44px;
  cursor: pointer;
}

.PasswordInfoIcon {
  position: absolute;
  right: 40px;
  top: 44px;
  cursor: pointer;
}

.changePasswordData .row{
  font-size: 15px
}

@media (max-width: 576px) {
  .hederpassword{
    padding: 10px !important;
  }
}