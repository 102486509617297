.receipt-container {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 800px;
    flex: 0 0 800px;
    margin: 40px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    position: relative;
}

.receipt-container h1 {
    text-align: center;
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    border-bottom: 2px solid #007bff;
    padding-bottom: 10px;
}

.receipt-container .mb-0 {
    margin-bottom: 0 !important;
}

.receipt-container .m-0 {
    margin: 0 !important;
}

.main_container{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.receipt-header,
.customer-info,
.order-details {
    margin-bottom: 20px;
}

.receipt-header div,
.customer-info p {
    margin: 0;
    line-height: 1.6;
    color: #555;
}

.customer-info strong,
.order-details strong {
    display: block;
    font-size: 18px;
    margin-bottom: 8px;
    color: #000;
}

.order-details table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}
.email-details{
    display: none;
}
.order-details th,
.order-details td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
    color: #444;
}

.order-details table td {
    background:#fff;
}
.order-details th {
    background-color: #f7f7f7;
    color: #333;
    border-bottom: 0;
}

.total-amount {
    text-align: right;
    font-size: 1.2em;
    margin-top: 10px;
    color: #000;
}

.receipt-container .btn {
    display: inline-block;
    padding: 10px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
}

/*.btn:hover {*/
/*    background-color: #0056b3;*/
/*}*/

.para {
    font-size: 20px;
}

.order-details .table tbody tr td {
     width: 90% !important;
}
.order-details .table thead th {
    border-color: darkgray !important;
    font-size: 14px !important;
}

.info p {
    font-size: 18px;
}

.info h3 {
    font-size: 26px;
}
