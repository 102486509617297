/* .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1230px;
} */

.last-wish{
    padding-top: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
    padding-bottom: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
}

.grediant-btn{
    border-radius: 40px;
    background: linear-gradient(to right, #007BFF, #92BCEA);
    color: var(--white);
    padding: 10px 30px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.6px;
}
.grediant-text{
    background: linear-gradient(to right, #007BFF, #92BCEA);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.grediant-btn:hover{
    color: #fff;
    -webkit-filter: hue-rotate(30deg);
    filter: hue-rotate(-15deg);
    transition: all 0.6s ease-in-out;
}
.about-right{
    flex-wrap: wrap;
    align-items: center;
    display: flex;
}
.about-right h3{
    font-family: 'Poppins', sans-serif;
    color: var(--black);
    font-size: calc(28px + (36 - 28) * ((100vw - 300px) / (1920 - 300)));
    font-weight: 600;
    text-transform: capitalize;
}.plan_bg_card_pricing

.about-right p{
    font-family: 'Poppins', sans-serif;
    color: #808080;
    font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1920 - 300)));
    font-style: normal;
    font-weight: 400;
}


.counter .details_content_card{
    font-family: 'Poppins', sans-serif;
    border: none;
    background-image: linear-gradient(to right, #007BFF, #92BCEA);
    text-align: center;
    padding: calc(25px + (35 - 25) * ((100vw - 300px) / (1920 - 300))) 0;
    border-radius: 30px;
}

.details_content_card h2{
    font-size: calc(35px + (50 - 35) * ((100vw - 300px) / (1920 - 300)));
    color: var(--white);
    font-weight: 600;
    margin-bottom: 0;
}

.details_content_card p{
    color: var(--white);
    font-size: calc(22px + (26 - 22) * ((100vw - 300px) / (1920 - 300)));
    margin-bottom: 0;
    margin-top: calc(25px + (30 - 25) * ((100vw - 300px) / (1920 - 300)));
}

@media (max-width: 992px) {
    .counter .col-lg-4:last-child{
        margin-top: 30px;
     }

}
@media (max-width: 768px) {
    .last-wish{
        text-align: center;
    }
    .grediant-btn{
        margin: 0 auto;
    }
    .counter .col-lg-4 + .col-lg-4{
        margin-top: 30px;
    }
}