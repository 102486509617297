.location_introduction_section{
    font-family: 'Poppins', sans-serif;
    padding-top: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
    padding-bottom: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
}

.introduction_map_content {
    width: 100%;
    height: 450px;
    border: 1.5px solid #007BFF !important;
    border-radius: 20px;
    box-shadow: 4px 5px 15px 0px rgba(0, 0, 0, 0.25);
    box-shadow: -4px 5px 15px 0px rgba(0, 0, 0, 0.25);
}

.introduction_map_content iframe {
    border-radius: 20px;
}

.location_introduction_left{
    margin: auto 0;
}

.location_introduction_left p{
    color: #808080;
    font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1920 - 300)));
    font-style: normal;
    font-weight: 400;
}

@media screen and (max-width: 768px) {
.introduction_map_content {
    height: 250px;
}

    .location_introduction_section {
        padding: 40px 20px;
    }

}

