.card_albums {
    width: 290px !important;
}

.card_albums_header {
    display: flex;
    padding: 2px;
    background-color: #00a3e8;
    font-size: 19px;
    font-weight: 700 !important;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    justify-content: center;
}
.card_albums_header div{
    text-overflow: ellipsis;
    white-space: pre;
    width: 260px;
    overflow: hidden;
    font-size: 14px;
    text-align: center;
}

.my-album-upload-button{
    color: black;
    font-weight: 500;
    background: white;
    padding: 3px 10px;
}

/*.card_albums .card_body img{*/
/*    width: 100%;*/
/*}*/
.card_albums .card_body {
    width: 100%;
}
.thumbnail-image{
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.albums_image {
    cursor: pointer;
    height: 140px;
}

.card_albums_body .card_body {
    height: 170px;
    width: auto !important;
}

.card_albums_Images {
    width: 220px;
    height: 152px;
}

.card_image_body .card_body {
    height: 160px;
}

.card_contain_image {
    padding: 0 !important;
    position: relative;
    height: 100%;
}

.alb_img {
    margin-top: 60px;
    max-width: 1802px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 15px;
}

.alb_img .MuiGrid-spacing-xs-2 {
    width: 100%;
}

.MuiGrid-spacing-xs-2 > .MuiGrid-item {
    padding: 6px !important;
}
.description_text{
    font-size: 20px;
}

.upload_btn {
    position: relative;
    overflow: hidden;
    border: none;
    font-weight: 700;
    min-width: 190px;
}

.upload_btn:hover {
    background-color: green;
    border: none;
}

.upload_btn:active {
    background-color: green;
    border: none;
}

.image_input {
    opacity: 0;
    position: absolute;
}

.image_back_btn {
    transform: rotate(181deg);
}

.image_download {
    /*height: 680px;*/
    width: auto !important;
}

.image_arraw {
    justify-content: center;
    align-items: center;
    display: flex;
}

.image_arraw img {
    cursor: pointer
}

.comment_box {
    width: 25%;
    height: 874px;
    position: absolute;
    right: 0;
    top: 0px;
    padding: 15px !important;
    background: #e0e8eb;
    overflow-y: scroll;
}
/*::-webkit-scrollbar {*/
/*    display: none;*/
/*}*/
.comment_header {
    justify-content: center;
    color: #afafaf;
    font-weight: 700;
    font-size: 18px;
}

.comment_contain {
    width: 100%;
    color: black;
    font-weight: 600;
    background: white;
    font-family: "Monotype Corsiva";
    font-size: 18px;

}

.like_image {
    height: 25px;
    width: 25px;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.main_container_download {
    padding: 0 15px;
}

.main_container_download .back-arrow {
    transform: rotate(180deg);
}

.ShareRelease-Modal-Container .modal-footer .MuiCircularProgress-indeterminate{
    width: 18px !important;
    height: 18px !important;
    position: relative;
    top: 2px;
}

.circular-progress{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 62px);
    position: relative;
}
.circular-progress:after{
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.3);
}

@media only screen and (max-width: 540px) {
    .card_albums_Images {
        width: 100%;
    }

    .back-arrow {
        max-width: 30px;
    }

    .forword-arrow {
        max-width: 30px;
    }
    .card_albums {
        width: 100% !important;
    }
    .album-contain {
        margin: 10px !important;
    }
}

.download_main {
    width: 75%;
}

@media only screen and (max-width: 992px) {
    .download_main {
        width: 100%;
        overflow: hidden;
    }

    .comment_box {
        width: 100%;
        margin-top: 20px;
        position: relative;
    }

    .image_download {
        width: 100% !important;
    }

    .comment_contain {
        height: auto;
    }
}

@media only screen and (max-width: 1130px) {
    .download_main {
        width: 100%;
        overflow: hidden;
    }

    .comment_box {
        width: 100%;
        margin-top: 20px;
        position: relative;
    }
}

.MuiGrid-spacing-xs-2 {
    width: 100% !important;
}

.image_preview {
    width: 100%;
    height: 183px;
    border: 1px solid darkgrey;
    overflow: hidden;
}

.delete_album {
    backdrop-filter: blur(5px);
    background: rgba(247, 247, 250, .2);
}

.card_albums_body {
    overflow: hidden;
}

.card_albums .card_body {
    position: relative;
    overflow: hidden;
}

.card_albums .card_body:hover .delete_album {
    transform: translateY(-26px);
}

.card_albums .delete_album {
    transform: translateY(30px);
    position: absolute;
    width: 100%;
    left: 0;
    z-index: 2;
}

.card_albums_Images {
    overflow: hidden;
}

.card_image_body {
    overflow: hidden;
    position: relative;
    height: 100%;
}
.card_image_body .MuiFormControlLabel-root{
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 5px;
}
.card_image_body .MuiIconButton-root{
    padding: 0;
}
.card_image_body .MuiSvgIcon-root {
    width: 30px !important;
    height: 30px !important;
}

.card_albums_Images .card_image_body:hover .delete_album_image {
    transform: translateY(-35px);
}

.card_albums_Images .delete_album_image {
    transform: translateY(0);
    position: absolute;
    right: 0;
    width: 100%;
    backdrop-filter: blur(5px);
    background: rgba(247, 247, 250, .2);

}

.back-arrow {
    max-width: 35px;
    cursor: pointer;
}

.forword-arrow {
    max-width: 35px;
    cursor: pointer;
}

.image-main {
    max-width: 850px;
    height: 580px;
    background: white;
    padding-top: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
}


@media only screen and (max-width: 576px) {
    .change-image {
        position: relative;
        width: 100%;
    }
    .change-image .arrow img {
        width: 40px;
    }
    .btn_header_download{
        width: 100% !important;
    }
}
@media only screen and (max-width: 320px) {
    .card-contain-album {
        justify-content: center;
    }
    .album-contain {
        margin-left:0 !important;
    }
}

.edit_btn_Album {
    background: red;
    color: whitesmoke;
    padding: 4px 8px;
    cursor: pointer;
    filter: drop-shadow(0 0 3px crimson);
}

.download_icon {
    cursor: pointer;
}

.album-name {
    line-height: 1.2;
    font-size: 12px;
    text-align: center;
}

.album-name strong {
    font-weight: 500;
}

.album_btn {
    height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.album_btn .MuiIconButton-root {
    padding: 0;
}


.imgIcon-indicator {
    cursor: pointer;
}

.btn_header_download {
    border: 1px solid darkgrey;
    display: flex;
    justify-content: space-between;
    padding: 5px 10px;
    width: 850px;

}
.main_download_container {
    position: relative;
}
.add_message {
    font-family: "Monotype Corsiva";
    font-size: 18px;
}
.video_album {
    height: 600px;
}
.album-contain {
    margin-left:24px;
}