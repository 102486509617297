.main-header {
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    padding: 36px;
}

.main-header .main_menu_list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.main-header .main_menu_list .menu_item {
    text-align: center;
    padding: 14px 25px;
    text-decoration: none;
    display: inline;
}

.menu_item a {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.menu_item.active a {
    color: #92BCEA;
    font-weight: bold;
}

.menu_item .header_menu_outlinebtn {
    border: 1px solid #007BFF;
    border-radius: 40px;
    padding: 12px 38px;
    color: #000;
    font-weight: 600;
    font-size: 16px;
}

.menu_item .header_menu_btn {
    color: #fff;
    border-radius: 40px;
    padding: 12px 30px;
    margin-left: 20px;
    font-weight: 600;
    font-size: 16px;
}


@media (max-width: 1200px) {
    .main-header .main_menu_list .menu_item {
        padding: 14px 6px;
    }
    .inroduction_details_video video {
        width: 100%;
        height: 100%;
    }
}

@media (max-width: 992px){
    .main-header{
        padding: 26px;
    }
    .menu_item .header_menu_outlinebtn {
        border-radius: 40px;
        padding: 8px 24px;
    }
    .menu_item .header_menu_btn {
        padding: 12px 18px;
        margin-left: 10px;
    }
}

@media (max-width: 768px){
    .main-header{
        padding: 20px 15px;
    }

    .main_menu_list{
        display: none;
    }
    .main-header .menu_bar {
        display: block;
    }
}




