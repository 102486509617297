.selected-row{
    background-color: #d4d4d4;
}

.wrapped-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Elipsis after one line*/
    -webkit-box-orient: vertical;
    word-break: break-all;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    --max-lines: 3 !important;
}


.custom-btn:disabled {
    opacity: 0.5;
}
.wrapped-2 p{
    margin: 0;
    -webkit-line-clamp: 3 !important;
}
.wrapped-2 {
    -webkit-line-clamp: 3 !important;
}

.circularData .MuiCircularProgress-colorPrimary{
    color: #f92121 !important;
}
.noData {
    width: 447%;
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.toolTipData p {
    margin-bottom: 0;
}

.MyWishes-TableContainer .tableheading th{
    font-size: 16px !important;
}
.MyWishes-TableContainer tbody .wrapped-2{
    font-size: 16px !important;
}

.MuiTableCell-head{
    min-width: auto;
}
.MyWishes-TableContainer .tableheading th:nth-child(1) {
    /*width: 60px;*/
    width: 23%;
}
.MyWishes-TableContainer .tableheading th:nth-child(1) span{
    margin-left: 5%;
}
    /* Card My Wishes */
.line-clamp {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.rdw-editor-wrapper{
    width: 100%;
}

.rdw-dropdown-selectedtext{
    color: black;
}
.box{

}

.card_content_header{
    background: #0069d9;
}
.card_content_header .edit_btn,
.card_content_header .delete_btn{
    background: white;
    border: none;
    color: black;
    font-weight: 500;
    padding: 2px 20px;
    text-decoration: none !important;
    cursor: pointer;
}
.card_content_header .edit_btn:hover,
.card_content_header .delete_btn:hover{
    background: white !important;
}
.card_content_header .delete_btn{
    color: black;
}
.contact_card_table {
    border-left: 1px solid #bac2af;
}
.contact_card_table .MuiCardActions-spacing{
    border-top: 1px solid #bac2af;
}

.MuiGrid-container .MuiPaper-rounded {
    border-radius: 0px !important;
}
.contact_card_table{
    box-shadow: 6px 6px 1px 1px rgb(0 0 120 / 20%) !important;
    border-top: none;
}
.user_details > p{
    height: 73px;
}
.user_name p,
.user_details p{
    text-overflow: ellipsis;
    overflow: hidden;
}
.contact_card_table .card_body{
    height: 100px;
    overflow: hidden;
}
.user_name p{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.tile_active_div{
    background: #c7c1cc;
    padding: 5px;
}
.tile_deactive_div{
    padding: 5px;
}
.bg-active {
    background: #c0c0ba;
    width: 35px !important;
    height: 35px !important;
}
.tile_parent{
    position: absolute;
    right: 15px;
    top: 0;
}
.notestable_card{
    width: 350px;
}
@media (max-width: 576px) {
    .notestable_card{
        width: 100%;
    }
    .wish_card_page{
        width: 50%;
    }
    .genie_parent_wish {
        position: relative !important;
        left: 0;
        top: 0;
        transform: translateY(0);
    }
}
@media (max-width: 576px) and (min-width: 500px){
    .tile_parent {
        top: -35px !important;
    }
}
@media (max-width: 376px) {
    .wish_card_page{
        width: 100%;
        margin-top: 58px !important;
    }
    .card-container-wishes {
        margin: 0px !important;
    }
}

.cardContainer .MuiPaper-rounded {
    border-radius: 0px;
}
.colorCode{
    color: rgb(0, 123, 255);
    font-size: 16px;
}

.wrapped-custom-line{
    overflow: hidden;
    word-break: break-all;
}

.new-line-text p{
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 0px;
    max-width: 415px !important;
}

.new-line-text p{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}


.notes-content-stable .MuiCardContent-root{
    max-height: 157px;
}

.notes-content-stable .MuiTableCell-stickyHeader{
    position: static !important;
    z-index: 0;
}


.tool {
    cursor: help;
    position: relative;
}


/*== common styles for both parts of tool tip ==*/
.tool::before,
.tool::after {
    left: 50%;
    opacity: 0;
    position: absolute;
    z-index: -100;
}
.tool:hover::before,
.tool:focus::before,
.tool:hover::after,
.tool:focus::after {
    opacity: 1;
    transform: scale(1) translateY(0);
    z-index: 100;
}


/*== pointer tip ==*/
.tool::before {
    border-style: solid;
    border-width: 1em 0.75em 0 0.75em;
    border-color: black transparent transparent transparent;
    top: 115%;
    content: "";
    margin-left: -0.5em;
    transform:  scale(.6) translateY(90%) rotate(180deg);

}

.tool:hover::before,
.tool:focus::before {
    transform: scale(1) translateY(0%) rotate(180deg);
}


/*== speech bubble ==*/
.tool::after {
    background: black;
    border-radius: 5px;
    top: 150%;
    color: white;
    content: attr(data-tip);
    margin-left: -17.75em;
    font-size: 10px;
    transform: scale(.6) translateY(50%);
    width: 33.5em;
    line-height: 14.5px;
    padding: 3px;
    text-align: center;
}
.tooltip_small::before {
    border-width: 7px;
    top: 100%;
}
.tooltip_small::after {
    top: 133%;
}

.genie_parent_wish {
    position: absolute;
    left: -20% !important;
    top: 50%;
    transform: translateY(-50%);
}
@media (max-width: 768px) {
    .genie_parent_wish {
        position: relative !important;
        left: 0 !important;
        top: 0;
        transform: translateY(0);
    }
}
.card_name {
    line-height: 1.2;
    font-size: 14px;
    text-align: center;
}
.card_name img{
    cursor: pointer;
}
.card_name strong{
    font-weight: 500;
}
.wishes_btn {
    height: 68px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.wishes_btn .MuiCheckbox-root{
    padding: 0;
}
.cancel_btn:hover{
    background: #6c757d;
    border-color: #6c757d;
}
.cancel_btn{
    color: #6c757d;
    background: white;
    border-color: #6c757d;
}

.gap-2{
    gap: 0.5rem!important;
}
