
.care-section,
.learn_more_section{
    padding-top: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
    padding-bottom: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
}

.details_title h2 {
    max-width: 1232px;
    font-size: 36px;
    line-height: 88px;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
}

.details_title h2 span {
    background: linear-gradient(to right, #007BFF, #92BCEA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.details_title p {
    max-width: 819px;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    margin: auto;
    color: #808080;
}

.learn_more_btn .btn_primarybtn {
    background-color:#007BFF !important;
    color: #fff;
    padding: 12px 45px;
    font-family: 'Poppins', sans-serif;
    border-radius: 40px;
    margin-left: 20px;
    font-weight: 600;
    font-size: 18px;
    margin-top: 14px;
}

.details_contentbox {
    margin-top: 58px;
    margin: 58px 80px;
}

.details_contentbox .details_content_card {
    border: none;
    background-image:linear-gradient(to right, #007BFF, #92BCEA);
    text-align: center;
    padding: 36px 0;
    border-radius: 30px;
}

.details_content_card h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 50px;
    color: #fff;
    line-height: 36px;
    font-weight: 600;

}

.details_content_card p {
    font-family: 'Poppins', sans-serif;
    color: #fff;
    font-size: 26px;
    line-height: 39px;
    margin-top: 32px;
    margin-bottom: 0;
}

.counter .details_content_card {
    border: none;
    background-image:linear-gradient(to right, #007BFF, #92BCEA);
    text-align: center;
    padding: calc(25px + (35 - 25) * ((100vw - 300px) / (1920 - 300))) 0;
    border-radius: 30px;
}

.details_content_card h2 {
    font-size: calc(35px + (50 - 35) * ((100vw - 300px) / (1920 - 300)));
    color: var(--white);
    font-weight: 600;
    margin-bottom: 0;
}

.details_content_card p {
    color: var(--white);
    font-size: calc(22px + (26 - 22) * ((100vw - 300px) / (1920 - 300)));
    margin-bottom: 0;
    margin-top: calc(25px + (30 - 25) * ((100vw - 300px) / (1920 - 300)));
}

@media (max-width: 768px){
    .helper_video_container .helper_video_frame {
        padding: 10px;
    }
}


