.free_trial{
    padding-top: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
    padding-bottom: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
}

.free_trial_section{
    padding: 87px 0;
    width: 100%;
    height: 100%;
    background-image: url("../../../assets/images/trail-image.svg");
    background-size: cover;
}

.free_trial_title h2{
    max-width: 1024px;
    font-size: 36px;
    line-height: 60px;
    color: #fff;
    font-style: italic;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
    text-align: center;
}

.free_trial_outlinebtn{
    color: #fff;
    border: 1px solid #fff;
    border-radius: 40px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    padding: 12px 28px;
    margin-top: 20px;
}

@media screen and (max-width: 768px) {
    .free_trial_title h2 {
        font-size: 28px;
    }
}