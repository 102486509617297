.plan_list_table{
    padding-top: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
    padding-bottom: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
}

.plan_list_table .container-fluid{
    max-width: 100%;
    overflow: visible;
}
.pricing_switch{
    display: flex;
    justify-content: center;
    gap: 20px;
}

.pricing_modal .modal-title{
    width: 100%;
}

.pricing_header{
    display: flex;
    justify-content: center;
    width: 100%;
    margin: auto;
}
.pricing_switch p{
    font-size: 16px;
    color: black;
    line-height: 32px;
    font-weight: 600;
}

.pricing_switch .react-switch-bg{
    background: linear-gradient(to right, #007BFF, #92BCEA) !important; 
}
.plan_bg_card_pricing{
  padding: 0 ;
}
.table td{
    font-size: 14px !important;
}
.table .feature_title{
    text-align: left;
    padding: 20px !important;
}
.check_mark_column{
  width: 50px ;
    padding: 16px !important;
}
.mobile_plans_table .pricing_table{
    font-family: 'DM Serif Display', serif;
    color: #909494 !important;
    font-weight: 600;
    font-size: 16px !important;
    margin-bottom: 0 ;
    padding-left: 5px;
    padding-right: 5px;
}
.plan_card{
    border-radius: 30px;
    max-width: 500px;
    margin: auto;
}

.special_button_offer{
    background-color: #007BFF;
    color: #fff;
    border-radius: 40px;
    display: flex;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 20px;
    padding: 7px 24px;
    border: 0;
}

.plan_card .plan_bg_card_pricing .plan_list_menu .price {
    font-weight: 700;
    background: linear-gradient(to right, #007BFF, #92BCEA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.mobile_plans_card{
  display: none;
}

.plans-table thead th {
    vertical-align: middle;
    border-bottom: 1px solid #CCCCCC;
}
.plans-table tbody .pricing_table{
    font-family: 'DM Serif Display', serif;
    color: #909494 !important;
    font-weight: 600;
    font-size: 16px !important;
}
.plans-table tbody .pricing_table_header{
    font-family: 'DM Serif Display', serif;
    color: #909494 !important;
    font-weight: 600;
    font-size: 18px !important;
}

.plans-table thead,td,th,tr,
.plans-table tbody,td,th,tr{
    text-align: center;
    border: 1px solid #CCCCCC;
    color: #2E2E2E;
    font-size: 14px;
}

.plans-table .bg-header{
    background-color: #54a0f3;
}

.book_buy_button{
    color: white;
    border: 0;
    background-color: #00d823;
}

.book_buy_button .one{
    background: linear-gradient(to right, #007BFF, #92BCEA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}

.book_buy_button .two{
    color: black;
    font-weight: 500;
}


.yearly_button{
    color: #008eff;
    border: 0;
    background-color: white;
}
.payment-instraction{
    color: white;
    font-weight: 400;
    margin-bottom: 0 !important;
    margin-top: 5px;
}
 .quartely_button {
    color: white;
    border: 0;
    background-color: #ff238c;
}
 .monthly_button{
    color: white;
    border: 0;
    background-color: #c64dff;
}
 .weekly_button{
    color: white;
    border: 0;
    background-color: #ffb800;
}

.bg-header .pricing-title{
    font-size: 18px;
    font-weight: 500;
}

.plans-table thead h4{
    color: var(--black);
    font-size: calc(18px + (24 - 18) * ((100vw - 300px) / (1920 - 300)));
    font-weight: 600;
    text-transform: capitalize;
}

.plans-table thead p{
    color: #808080;
    font-size: 14px;
    font-weight: 400;
}
.plans-table thead .price{
    font-size: calc(28px + (48 - 28) * ((100vw - 300px) / (1920 - 300)));
    font-weight: 700;
    background: linear-gradient(to right, #007BFF, #92BCEA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.plans-table thead .price span{
    font-size: 20px;
    font-weight: 500;
}

.plans-table tbody td{
    color: #2E2E2E;
    font-size: 14px !important;
    font-weight: 400;
    padding: 16px;
}

.plans-table tbody th h4{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
}

.plans-table tbody th:first-child,
.plans-table tbody td:first-child{
    text-align: start;
}


.offer-frame {
    height:auto;
}
.offer-frame img {
   width: 100%;
}

.grediant-outline-btn{
    position: relative;
    color: var(--white);
    padding: 10px 30px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.6px;
    background: linear-gradient(to right, #007BFF, #92BCEA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.grediant-outline-btn::before{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 40px;
    left: 0;
    top: 0;
    border: 2px solid transparent;
    background: linear-gradient(to right, #007BFF, #92BCEA);
    box-shadow:rgba(0, 0, 0, 0.2);
    -webkit-mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}
.plans-table{
    width: 50% !important;
    margin: auto !important;
}

.pricing_header_cards{
    width: 33.33% !important;
}

.table tbody tr td{
    width: 33.33% !important;

}

.book-title{
    font-weight: 700;
    font-size: 18px;
}

.book-subtitle{
    font-size: 14px;
    font-weight: 600;
}

@media (max-width: 1025px) {
   .plans-table{
       width: 100% !important;
       margin: 0 !important;
   }
}

@media (max-width: 800px) {
    .plans-table{
        display: none;
    }
    .mobile_plans_card{
        display: block;
    }
    .table .feature_title{
        padding: 20px 10px !important;
    }
    .check_mark_column{
        padding: 16px 10px !important;
    }
    .plan_list_menu{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}
