.easy_steps_section{
    max-width: 1168px;
    padding-top: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
    padding-bottom: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
}

.details_title h2{
    text-align: center;
    font-size: 36px;
    color: #000;
    font-weight: 600;
    font-family: 'Poppins', sans-serif;
}

.details_title p{
    text-align: center;
    font-size: 18px;
    color: #808080;
    font-family: 'Poppins', sans-serif;
}

.easy_steps .easy_steps_outlinebtn{
    border: 1px solid #92BCEA;
    border-radius: 40px;
    background-color: #fff;
    padding: 12px 44px;
    font-weight: 600;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(to right, #007BFF, #92BCEA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-top: 70px;
}

.steps_content{
    margin-top: 52px;
}

.steps_content img{
    width: 100%;
}

@media screen and (max-width: 768px) {
    .details_title h2 {
        font-size: 28px;
    }
    .details_title p{
        font-size: 16px;
    }
}