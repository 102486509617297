.how_works_container {
    width: 100%;
    height: fit-content;
    padding: 56px 0px;
}

.how_its_work {
    color: #101010;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
    font-family: 'Bai Jamjuree', sans-serif;
}

.map_container {
    margin-top: 64px;
    justify-content: center;
}

.how_works_btn {
    width: 196px;
    height: 56px;
    color: #FFF;
    border-radius: 12px;
    background: #FF238C;
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

@media (max-width: 768px) {
    .how_its_work {
        font-size: 30px;
    }
    .map_container svg{
        height: 100%;
    }
}

/* @media (max-width: 576px){
    .how_its_work{
        font-size: 30px;
    }
} */