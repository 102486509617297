.title {
    margin-bottom: 56px;
    padding: 0 15px;
}
.title h2 {
    font-weight: 700;
    font-size: 40px;
    color: #1F2A40;
}
.title p{
    color: #FF238C;
    font-weight: 500;
    font-size: 22px;
}
.banner_content,
.representatives_content{
    width: 80%;
    margin: auto;
}

/* ====== banner_section start ======*/
.banner_section {
    min-height: 85vh;
    min-height: 790px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f2f4f7;
    position: relative;
}

.banner_content {
    z-index: 2;
    position: relative;
}
.banner_details .our_plan {
    margin-left: 48px;
}
.banner_details .our_plan p{
   font-weight: 400;
}
.banner_details .banner_title {
    font-family: Arial, sans-serif;
    font-weight: 700;
    font-size: 55px;
}
.banner_details .banner_sub_title {
    font-weight: 500;
    font-size: 36px;
    color: #141F34;
    margin-bottom: 17px;
    width: 60%;
}

.banner_details .banner_description {
    font-size: 18px;
    color: black;
    margin-bottom: 23px;
    width: 90%;
}


.banner_details .download_btn_group a:not(:last-child) {
    margin-right: 16px;
}
.banner_details .download_btn_group img:hover {
        cursor: pointer;
}
.banner_right_img img{
    width: 80%;
}
.banner_section .banner_bg_image {
    position: absolute;
    width: 100%;
    height: 100%;
}

.banner_section .banner_bg_image .bg_img {
    position: absolute;
}

.banner_section .banner_bg_image .main_circle {
    left: 7%;
    top: 17%;
}

.banner_section .banner_bg_image .circle1 {
    left: 19%;
    top: 10%;
}

.banner_section .banner_bg_image .circle2 {
    left: 52%;
}

.banner_section .banner_bg_image .triangle1 {
    left: 40%;
    top: 15%;
}

.banner_section .banner_bg_image .triangle1_1 {
    right: 10%;
    top: 10%;
}

.banner_section .banner_bg_image .triangle3 {
    right: 10%;
    bottom: 10%;
}

.banner_section .banner_bg_image .triangle2 {
    left: 25%;
    bottom: 35%;
}

.banner_section .banner_bg_image .arrow_line {
    top: 38%;
    left: 40%;
}

/* ====== representatives_section start ======*/
.representatives_section {
    padding: 50px 0;
    /*display: flex;*/
    align-items: center;
    position: relative;
    overflow: hidden;
}
.representatives_content_header{
    font-size: 25px!important;
}

.representatives_section .representatives_content {
    padding: 35px;
    background: #FFFFFF;
    box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.03);
    border-radius: 22px;
    min-height: 235px;
    z-index: 2;
    overflow: hidden;
}
.representatives_section_list{
    margin-left: 50px!important;
}
.representatives_section .representatives_content .representatives_label {
    color: #FF238C;
    font-size: 20px;
}

.representatives_section .representatives_content .representatives_title {
    color: #141F34;
    font-size: 25px;
    font-weight: 700;
}

.representatives_section .representatives_content .tab_content {
    /*width: 50%;*/
    /*padding: 0 16px;*/
    width: 315px;
    margin-bottom: 6px;
}

.representatives_section .representatives_content .tab_content a {
    color: #3e3e3e;
    font-size: 20px;
    font-weight: 500;
}

.representatives_bg {
    width: 25%;
    position: absolute;
    top: -30%;
    z-index: -1;
}
.RepresentativesContentBg{
    position: absolute;
    right: -11%;
    bottom: -59%;
}

/* ====== thought_section start ======*/
.thought_section {
    padding-bottom: 150px;
}

.thought_content {
    width: 90%;
    margin: auto;
}
.thought_left img{
    width: 100%;
}
.thought_right {
    list-style: none;
    padding-left: 30px;
    height: 100%;
}

.thought_right .thought_item {
    position: relative;
}
.thought_right .thought_item:not(:last-child) {
    margin-bottom: 35px;
}
.thought_right .thought_item:after {
    position: absolute;
    content: "";
    top: 0;
    left: -30px;
    width: 26px;
    height: 26px;
    background: url("../../../assets/images/LandingPageNew/list_icon.svg");
}

.thought_right .thought_item p {
    margin-left: 10px;
    color: #3e3e3e;
}


/* ====== solution_section start ======*/
.solution_section {
    padding: 68px 0;
    background: #f2f4f7;
    position: relative;
    overflow: hidden;
}
.solution_section p{
    color: black;
}
.solution_section:after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    background: url("../../../assets/images/LandingPageNew/solution_bg_left_top.svg");
}
.representatives_label_main{
    max-width: 750px;
}
.solution_content{
    width: 90%;
    margin: auto;
    position: relative;
    z-index: 2;
}
.solution_content .solution_tab {
    padding: 0 16px;
    width: 20%;
    margin-bottom: 20px;
}
.solution_content .solution_tab .solution_tab_content{
    color: #797979;
    background: #FFFFFF;
    box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.03);
    border-radius: 10px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 266px;
    min-height: 98px;
    margin: auto;
}
.solution_tab_content span{
    text-align: center;
}
.solution_content .solution_tab .solution_tab_content:not(:last-child){
    margin-bottom: 50px;
}
.solutionBGLeftTop{
    width: 30%;
    position: absolute;
    top: -30%;
    left: -20%;
}
.solutionBGRight{
    position: absolute;
    bottom: -30%;
    right: -20%;
    width: 40%;
}

/* ====== features_section start ======*/
.features_section{
    padding: 50px 0;
    position: relative;
}
.FeaturesLeftBg {
    width: 20%;
    position: absolute;
    bottom: 0;
    z-index: -1;
}
.FeaturesRightBg {
    width: 20%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
}
.features_content{
    width: 90%;
    margin: auto;
}
.features_content .features_card{
    padding: 15px;
    /*min-height: 356px;*/
    background: #FFFFFF;
    box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
    height: 100%;
}
.features_content .features_card .features_img_content{
    margin-bottom: 16px;
    position: relative;
}
.features_content .features_card .features_img_content .features_img{
    margin-left: 35px;
    position: relative;
    z-index: 1;
}
.features_content .features_card .features_img_content:after{
    position: absolute;
    content: "";
    top: 10px;
    left: 10px;
    width: 50px;
    height: 50px;
    background: transparent;
    border-radius: 4px;
    transform: rotate(-45deg);
}
.features_content .features_card .features_img_content_1:after{
    border: 1px solid #FF238C;
}
.features_content .features_card .features_img_content_2:after{
    border: 1px solid #008EFF;
}
.features_content .features_card .features_img_content_3:after{
    border: 1px solid #FFDC05;
}
.features_content .features_card .features_img_content_4:after{
    border: 1px solid #FFB800;
}
.features_content .features_card .features_img_content_5:after{
    border: 1px solid #C64DFF;
}
.features_content .features_card .features_img_content_6:after{
    border: 1px solid #00DAE8;
}
.features_content .features_card .features_title{
    font-weight: 600;
    font-size: 20px;
    color: #1F2A40;
}
.features_content .features_card .features_description{
    color: #3e3e3e;
    line-height: 30px;
}
.features_content .features_card .representatives_link{
    color: #008EFF;
    font-weight: 500;
}


/* ====== service_plan_section start ======*/
.service_plan_section{
    padding: 0 100px;
    background: url("../../../assets/images/LandingPageNew/service_plan_bg.svg") no-repeat bottom;
    background-size: contain;
}
@media (max-width: 1400px) {
    .service_plan_section{
        padding: 0 0px;
        width: calc(100% - 100px);
        margin: auto;
    }
}
@media (max-width: 575px) {
    .service_plan_section{
        padding: 0 0px;
        width: calc(100% - 20px);
        margin: auto;
    }
}

@media (max-width: 1300px) {
    .service_plan_section{
      overflow: auto;
    }
    .service_plan_main{

        width: 1500px;
    }
    .service_plan_wrepper{
        position: relative;
        overflow: auto;
        border: 1px solid black;
        white-space: nowrap;
    }
    .sticky-col {
        position: -webkit-sticky;
        position: sticky;
        background-color: white;
        width: 200px;
        min-width: 200px;
        max-width: 200px;
        left: 0px;
    }
}

@media (max-width: 575px) {
     .sticky-col{
         display: none!important;
     }
}

.service_plan_table tr td:first-child{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    padding-left: 15px!important;
}
.service_plan_table .price-lable{
    font-weight: 700!important;
    font-size: 20px!important;
    line-height: 29px!important;
    text-align: center;
    color: #1F2A40;
    background: #F6FAFF;
}
.service_plan_table tr:nth-child(4){
   background: #F6FAFF;
}
.service_plan_table tr:nth-child(2) td{
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #1F2A40;
    padding: 22px;
}
.service_plan_table tr:nth-child(3) td{
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    padding-bottom: 30px;
}
.service_plan_table tr:nth-child(5) td{
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    text-align: center;
    padding: 30px 0 20px 0;
}
.service_plan_table tr:nth-child(3) td:nth-child(3){
    color:#008EFF;
}
.service_plan_table tr:nth-child(3) td:nth-child(4){
    color: #FF238C;
}
.service_plan_table tr:nth-child(3) td:nth-child(5){
    color: #C64DFF;
}
.service_plan_table tr:nth-child(3) td:nth-child(6){
    color: #FFB800;
}
.service_plan_table tr:nth-child(3) td:nth-child(2){
    color: #00D823;
}
.service_plan_table tr:nth-child(3) td:nth-child(7){
    color: #C90189;
}
.service_plan_box{
    background: #F9F9F9;
    width: 106px;
    height: 106px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.service_plan_section .title .header-title{
    color: #FF238C;
    font-weight: 500;
    font-style: normal;
    font-size: 22px;
    text-transform: uppercase;
}
.service_plan_section .title h2{
    margin-bottom: 20px;
}
.service_plan_title{
    font-weight: 700;
    font-size: 32px!important;
    line-height: 50px;
}
.plan_btn_group{
    width: fit-content;
    border-radius: 32px;
    background: #F6FAFF;
    padding: 5px 22px;
    margin: auto;
}
.service_plan_des{
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #A2A2A2;
    margin-bottom: 30px;
}
.service_plan_section .plan_btn_group .btn{
    border-radius: 8px;
    color: #9D9D9D;
    padding: 11px 47px;
    border: 1px solid transparent;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
}
.service_plan_section .plan_btn_group .active{
    background: #FF238C;
    border: 1px solid white;
    box-shadow: none;
    color: white;
    border-radius: 32px;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

}
.service_plan_content{
    width: 65%;
    display: flex;
    margin: auto;
}
.service_plan_section .service_plan_content [class*="col-"], .service_plan_section .service_plan_content [class*="col"]{
    padding: 0;
}
.service_plan_content .checkin_time_content{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}
.service_plan_content .checkin_time_content .checkin_time{
    position: relative;
    min-height: 277px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.service_plan_content .checkin_time_content .checkin_time .content_line{
    position: absolute;
    width: 100%;
    border: 5px solid #C64DFF;
}

/*.service_plan_content .checkin_time_content .service_plan_card_1 .content_line:after,*/
/*.service_plan_content .checkin_time_content .service_plan_card_1 .content_line:before,*/
.service_plan_content .checkin_time_content .service_plan_card_5 .content_line:after,
.service_plan_content .checkin_time_content .service_plan_card_5 .content_line:before,
.service_plan_content .checkin_time_content .service_plan_card_6 .content_line:before,
.service_plan_content .checkin_time_content .service_plan_card_6 .content_line:after{
    position: absolute;
    content: "";
    border: 5px solid #32A071;
}
/*.service_plan_content .checkin_time_content .service_plan_card_1 .content_line:after,*/
.service_plan_content .checkin_time_content .service_plan_card_6 .content_line:after,
.service_plan_content .checkin_time_content .service_plan_card_5 .content_line:after{
    top: -5px;
    right: -15%;
    width: 15%;
    border: 5px solid #32A071;
}
.service_plan_content .checkin_time_content .service_plan_card_1 .content_line:before{
    background: url("../../../assets/images/LandingPageNew/content_line_1.svg") no-repeat;
    height: 310px;
    right: -43%;
}
.service_plan_content .checkin_time_content .service_plan_card_5 .content_line:before,
.service_plan_content .checkin_time_content .service_plan_card_6 .content_line:before{
    /*background: url("../../../assets/images/LandingPageNew/content_line_2.svg") no-repeat;*/
    height: 310px;
    right: -15%;
}
.service_plan_content .checkin_time_content .service_plan_card_5 .content_line:before{
    top: -305px;
}
.service_plan_content .checkin_time_content .checkin_time .time_content{
    height: 60px;
    border-radius: 61px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 331px;
    font-weight: 700;
    font-size: 19px;
}
.service_plan_content .checkin_time_content .checkin_time .time_content img{
    margin-right: 13px;
}
.service_plan_content .features_content_card{
    display: flex;
}
.service_plan_content .features_content_card .features_content{
    border: 10px solid #32A071;
    border-top: none;
    width: 100%;
}
.service_plan_content .features_content_card .features_content .features_content_header{
    background: #32A071;
    font-weight: 600;
    font-size: 34px;
    color: white;
    text-align: center;
    padding: 30px 0;
}

.service_plan_content .features_content_card .features_content .features_content_body{
    padding: 40px 0 40px 40px;
    background: white;
}
.service_plan_content .features_content_card .features_content .features_content_body ul{
    list-style: none;
}
.service_plan_content .features_content_card .features_content .features_content_body ul li{
    margin-bottom: 30px;
    color: #797979;
    font-size: 18px;
    position: relative;
    margin-left: 30px;
}
.service_plan_content .features_content_card .features_content .features_content_body ul li::after{
    position: absolute;
    content: "";
    top: 5px;
    left: -30px;
    width: 30px;
    height: 30px;
    background: url("../../../assets/images/LandingPageNew/right_icon.svg") no-repeat;
}


.service_plan_content .service_plan_card{
    background: #FFFFFF;
    box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.04);
    border-radius: 22px;
    overflow: hidden;
}
.service_plan_content .col:not(:last-child) .service_plan_card{
    margin-bottom: 30px;
}
.service_plan_content .service_plan_card .service_plan_top_content{
    max-height: 282px;
    max-width: 100%;
    padding: 23px 0;
}
.service_plan_content .service_plan_top_content .service_plan_title{
    font-weight: 700;
    font-size: 26px;
}
.plan_price{
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    padding: 12px 20px;
    text-align: center;
    color: #1F2A40;
    margin-bottom: 0;
}
.service_plan_content .service_plan_top_content .plan_description{
    font-weight: 500;
    font-size: 17px;
    color: #797979;
}
.plan_start_btn{
    padding-top: 40px;
}
 .plan_start_btn .btn{
    border-radius: 8px;
    padding: 12px 30px;
    border: 1px solid;
     font-weight: 600;
     font-size: 16px;
     line-height: 19px;
}
.service_plan_content .service_plan_top_content .plan_time_content{
    display: none;
    margin: 0;
}
.service_plan_content .service_plan_top_content .plan_time_content .plan_time{
    font-weight: 700;
    font-size: 23px;
    margin: 0;
}
.service_plan_content .service_plan_card_1 .service_plan_top_content,
.service_plan_card_1 .time_content{
    background: #FFF0F7;
}
.service_plan_table tr:last-child td:nth-child(3) .plan_start_btn .btn{
    color: white !important;
    border: none;
    background-color: #008EFF;
}
.service_plan_table tr:last-child td:nth-child(4) .plan_start_btn .btn{
    color: white !important;
    border: none;
    background-color: #FF238C;
}
.service_plan_table tr:last-child td:nth-child(5) .plan_start_btn .btn{
    color: white !important;
    border: none;
    background-color: #C64DFF;
}
.service_plan_table tr:last-child td:nth-child(6) .plan_start_btn .btn{
    color: white !important;
    border: none;
    background-color:#FFB800;
}
.service_plan_table tr:last-child td:nth-child(2) .btn{
    color: white !important;
    border: none;
    background-color: #00D823;
}
.service_plan_table tr:last-child td:nth-child(7) .plan_start_btn .btn{
    color: white !important;
    border: none;
    background-color:#C90189;
}

.service_plan_content .service_plan_card_6 .service_plan_top_content,
.service_plan_card_6 .time_content{
    background: #e4fff3;
}
.service_plan_content .service_plan_card_6 .service_plan_top_content .service_plan_title,
.service_plan_content .service_plan_card_6 .service_plan_top_content .plan_start_btn .btn,
.service_plan_content .service_plan_card_6 .service_plan_top_content .plan_time_content .plan_time,
.service_plan_card_6 .time_content,
.service_plan_card_6 .content_line{
    color: #32A071;
    border-color: #32A071 !important;
}
.service_plan_content .service_plan_card_1 .service_plan_top_content .service_plan_title,
.service_plan_content .service_plan_card_1 .service_plan_top_content .plan_start_btn .btn,
.service_plan_content .service_plan_card_1 .service_plan_top_content .plan_time_content .plan_time,
.service_plan_card_1 .time_content,
.service_plan_card_1 .content_line,
.service_plan_content .checkin_time_content .service_plan_card_1 .content_line:after,
.service_plan_content .checkin_time_content .service_plan_card_1 .content_line:before{
    color: #FF238C;
    border-color: #ff238c !important;
}
.service_plan_content .service_plan_card_2 .service_plan_top_content,
.service_plan_card_2 .time_content{
    background: #FBF3FF;
}
.service_plan_content .service_plan_card_2 .service_plan_top_content .service_plan_title,
.service_plan_content .service_plan_card_2 .service_plan_top_content .plan_start_btn .btn,
.service_plan_content .service_plan_card_2 .service_plan_top_content .plan_time_content .plan_time,
.service_plan_card_2 .time_content,
.service_plan_card_2 .content_line{
    color: #C64DFF;
    border-color: #C64DFF !important;
}
.service_plan_content .service_plan_card_3 .service_plan_top_content,
.service_plan_card_3 .time_content{
    background: #FEF5E0;
}
.service_plan_content .service_plan_card_3 .service_plan_top_content .service_plan_title,
.service_plan_content .service_plan_card_3 .service_plan_top_content .plan_start_btn .btn,
.service_plan_content .service_plan_card_3 .service_plan_top_content .plan_time_content .plan_time,
.service_plan_card_3 .time_content,
.service_plan_card_3 .content_line{
    color: #FFB800;
    border-color: #FFB800 !important;
}
.service_plan_content .service_plan_card_4 .service_plan_top_content,
.service_plan_card_4 .time_content{
    background: #E0F1FF;
}
.service_plan_content .service_plan_card_4 .service_plan_top_content .service_plan_title,
.service_plan_content .service_plan_card_4 .service_plan_top_content .plan_start_btn .btn,
.service_plan_content .service_plan_card_4 .service_plan_top_content .plan_time_content .plan_time,
.service_plan_card_4 .time_content,
.service_plan_card_4 .content_line{
    color: #008EFF;
    border-color: #008EFF !important;
}
.service_plan_content .service_plan_card_5 .service_plan_top_content,
.service_plan_card_5 .time_content{
    background: #C7F7FA;
}
.service_plan_content .service_plan_card_5 .service_plan_top_content .service_plan_title,
.service_plan_content .service_plan_card_5 .service_plan_top_content .plan_start_btn .btn,
.service_plan_content .service_plan_card_5 .service_plan_top_content .plan_time_content .plan_time,
.service_plan_card_5 .time_content,
.service_plan_card_5 .content_line,
.service_plan_content .checkin_time_content .service_plan_card_5 .content_line:after,
.service_plan_content .checkin_time_content .service_plan_card_5 .content_line:before{
    color: #00DAE8;
    border-color: #00DAE8 !important;
}
.service_plan_content .service_plan_card_6 .service_plan_top_content .service_plan_title,
.service_plan_content .service_plan_card_6 .service_plan_top_content .plan_start_btn .btn,
.service_plan_content .service_plan_card_6 .service_plan_top_content .plan_time_content .plan_time,
.service_plan_card_6 .time_content,
.service_plan_card_6 .content_line,
.service_plan_content .checkin_time_content .service_plan_card_6 .content_line:after,
.service_plan_content .checkin_time_content .service_plan_card_6 .content_line:before{
    color: #32A071;
    border-color: #32A071 !important;
}
.service_plan_content .service_plan_top_content .plan_time_content .plan_list_item{
    font-weight: 500;
    font-size: 22px;
    color: #797979;
    position: relative;
}
.service_plan_bottom_content{
    padding: 15px 20px;
}
.service_plan_bottom_content .service_plan_details_list{
    margin-left: 10px;
    list-style: none;
}
.service_plan_bottom_content .service_plan_details_list .plan_list {
    margin-bottom: 15px;
}
.service_plan_bottom_content .service_plan_details_list .plan_list .plan_list_item{
    font-size: 18px;
    color: #797979;
    display: flex;
    align-items: start;
}
.service_plan_bottom_content .service_plan_details_list .plan_list .plan_list_item img{
    margin-top: 5px;
}


/* ====== scenarios_section start ======*/
.scenarios_section{
    padding: 150px 0;
    padding-top: 50px;
    position: relative;
}
.scenarios_content{
    width: 80%;
    margin: auto;
}
.scenarios_content .scenarios_card{
    padding: 10px;
    margin-bottom: 20px;
    width: 50%;
}
.scenarios_content .scenarios_card > div{
    box-shadow: 0px 4px 70px rgba(0, 0, 0, 0.04);
    padding: 15px;
    border-radius: 15px;
    background: white;
}
.scenarios_content .scenarios_card .scenarios_card_img{
    margin-bottom: 10px;
}
.scenarios_content .scenarios_card .scenarios_card_title{
    font-weight: 600;
    font-size: 20px;
    color: #FF238C;
}
.scenarios_content .scenarios_card .scenarios_card_description{
    color: #3e3e3e;
}
.scenarios_content .scenarios_card .solution_link{
    font-weight: 700;
    font-size: 18px;
    color: #008EFF;
}
.scenarios_content .carousel-root {
    width: 100%;
    padding: 0 15px;
}
.scenarios_content .carousel .slide{
    text-align: start;
}
.scenarios_content .carousel .slide img{
    width: auto;
}
.scenarios_content .carousel.carousel-slider{
    overflow: inherit;
}
.scenarios_content .carousel .control-arrow{
    position: absolute;
    top: auto;
    bottom: -60px;
    color: white;
    width: 50px;
    height: 50px;
    background: rgba(255, 35, 140, 0.1);
    border-radius: 50%;
    opacity: 1;
}
.scenarios_content .carousel .control-next{
    right: 45%;
}
.scenarios_content .carousel .control-prev{
    left: 45%;
}
.scenarios_content .carousel .control-arrow:before{
    border: none;
}

.scenarios_content .carousel .control-prev:before{
    background: url("../../../assets/images/LandingPageNew/left_arrow.svg") no-repeat;
    background-position: 20%, 50%;
    width: 100%;
    height: 100%;
}
.scenarios_content .carousel .control-next:before{
    background: url("../../../assets/images/LandingPageNew/right_arrow.svg") no-repeat;
    background-position: 20%, 50%;
    width: 100%;
    height: 100%;
}
.scenarios_mobile_content{
    display: none;
}


/* ====== contactUs_section start ======*/
.contactUs_section{
    padding-bottom: 150px;
}
.contactUs_content{
    width: 80%;
    margin: auto;
}
.contactUs_content .tag{
    margin-bottom: 20px;
}
.contactUs_content .tag .email_tag{
    margin-right: 40px;
}
.map_content{
    width: 100%;
    height: 100%;
}
.contactUs_form,
.map_content{
    border-radius: 22px;
    overflow: hidden;
}
.contactUs_form{
    background: #FFFFFF;
    border: 1px solid #B6C2D1;
}
.contactUs_form .form_content{
    padding: 20px;
}
.contactUs_form .form_content .email_tag,
.contactUs_form .form_content .phone_tag{
    font-size: 18px;
    color: #797979;
    font-weight: 500;
}
.contactUs_form .form_content .form_label{
    font-weight: 600;
    font-size: 24px;
    color: #1F2A40;
    margin-bottom: 20px;
}
.contactUs_form .form_content .form-group .form-control{
    border: 1px solid #DADADA !important;
    border-radius: 6px;
    font-size: 14px !important;
    padding: 15px 10px;
    height: auto;
}
.contactUs_form .textArea{
    margin-bottom: 0;
}
.contactUs_form .textArea .form-control{
    resize: none;
}
.contactUs_form .btn{
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    padding: 20px 12px;
    border-radius: 0;
}

/*=========== scroll To Top ===============*/
.scroll-top{
    width: 45px;
    height: 45px;
    border-radius: 5px;
    background: rgb(0, 123, 255);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 40px;
    bottom: 40px;
    transition: all .3s;
    border: none;
}
.scroll-top:hover{
    color: white;
    background: #0066d3;
}


@media (max-width: 1440px) {
    .service_plan_content .service_plan_top_content .service_plan_title {
        font-size: 28px;
    }
    .service_plan_content .service_plan_top_content .plan_price {
        font-size: 38px;
    }
    .service_plan_content .service_plan_card .service_plan_top_content .plan_start_btn .btn {
        padding: 10px 40px;
    }
    .service_plan_content .features_content_card .features_content .features_content_body{
        padding: 40px 0 40px 20px;
    }
    .service_plan_content .checkin_time_content .checkin_time .time_content {
        height: 70px;
        width: 310px;
        font-size: 20px;
    }
    .service_plan_content .features_content_card .features_content .features_content_header {
        font-size: 30px;
        padding: 25px 0;
    }
}
@media (max-width: 1200px) {
    .scenarios_content .carousel .control-next{
        right: 35%;
    }
    .scenarios_content .carousel .control-prev{
        left: 35%;
    }
    .service_plan_content {
        width: 98%;
    }
}
@media (max-width: 992px) {
    .title h2 {
        font-size: 35px;
    }
    .banner_section{
        padding: 40px 0;
    }
    .banner_section .banner_content .banner_title{
        font-size: 50px;
    }
    .banner_section .banner_content .banner_right_img img{
        width: 80%;
    }
    .download_btn_group a{
        display: block;
        margin-bottom: 10px;
    }
    .representatives_section .representatives_content .tab_content{
        margin-bottom: 20px;
        width: 50%;
    }
    .solution_content .row{
        align-items: start !important;
    }
    .solution_content .solution_tab {
        width: 100%;
    }
    .solution_content .solution_tab .solution_tab_content:not(:last-child) {
        margin-bottom: 20px;
    }
    .solutionBGLeftTop{
        width: 50%;
        position: absolute;
        top: -12%;
        left: -30%;
    }
    .solutionBGRight{
        position: absolute;
        bottom: -10%;
        right: -15%;
        width: 50%;
    }
    .your_date_content{
        flex-wrap: wrap;
    }
    .your_date_content div{
        width: 100% !important;
    }
    .service_plan_content .service_plan_top_content .service_plan_title {
        font-size: 25px;
    }
    .service_plan_content .service_plan_top_content .plan_price {
        font-size: 30px;
    }
    .service_plan_content .service_plan_top_content .plan_description {
        font-size: 17px;
    }
    .service_plan_content .service_plan_card .service_plan_top_content .plan_start_btn .btn {
        padding: 6px 30px;
        font-size: 16px;
    }
    .service_plan_content .checkin_time_content .checkin_time {
        min-height: 224px;
    }
    .service_plan_content .checkin_time_content .checkin_time .time_content {
        height: 60px;
        width: 260px;
        font-size: 17px;
    }
    .service_plan_content .features_content_card .features_content .features_content_header {
        font-size: 25px;
        padding: 20px 0;
    }
}
@media (max-width: 768px) {
    .title {
        margin-bottom: 26px;
    }
    .title h2 {
        font-size: 30px;
    }
    .banner_section .banner_content .banner_title{
        font-size: 40px;
    }
    .banner_details .banner_sub_title {
        font-size: 28px;
        width: 100%;
    }
    .banner_details .banner_description {
        width: 100%;
    }
    .banner_content,
    .features_content,
    .service_plan_content,
    .scenarios_content,
    .contactUs_content{
        width: 100%;
    }
    .representatives_content{
        width: 95%;
    }
    .representatives_section .representatives_content{
        padding: 20px;
    }
    .thought_right .thought_item:not(:last-child) {
        margin-bottom: 20px;
    }
    .service_plan_section .plan_btn_group .btn {
        padding: 7px 37px;
    }
    .service_plan_content .service_plan_top_content .service_plan_title {
        font-size: 28px;
    }
    .service_plan_content .service_plan_top_content .plan_price {
        font-size: 30px;
    }
    .service_plan_content .service_plan_top_content .plan_description {
        font-size: 18px;
    }
    .service_plan_content .service_plan_card .service_plan_top_content .plan_start_btn .btn {
        padding: 8px 22px;
    }
    .service_plan_content .service_plan_top_content .plan_time_content .plan_time {
        font-size: 20px;
    }
    .service_plan_content .service_plan_top_content .plan_time_content .plan_list_item {
        font-size: 18px;
    }
    .service_plan_bottom_content .service_plan_details_list{
        margin: 0;
    }
    .representatives_section,
    .thought_section,
    .solution_section,
    .scenarios_section,
    .features_section{
        padding: 50px 0;
    }
    .scenarios_section{
        padding: 30px 0 100px;
    }
    .scenarios_content .carousel .control-next{
        right: 35%;
    }
    .scenarios_content .carousel .control-prev{
        left: 35%;
    }
    .solution_content .solution_tab .solution_tab_content{
        font-size: 22px;
    }
    .FeaturesLeftBg {
        width: 30%;
        bottom: -70px;
    }
    .contactUs_section {
        padding-bottom: 50px;
    }
    .contactUs_content{
        padding: 0 16px;
    }
    .map_content {
        width: 100%;
        height: 450px;
        margin-bottom: 20px;
    }
    .form_content .tag{
        flex-wrap: wrap;
    }
    .form_content .email_tag{
        margin-bottom: 10px;
    }
    .form_content .email_tag, .form_content .phone_tag{
        width: 100%;
    }
    .contactUs_form, .map_content {
        border-radius: 10px;
    }
    .contactUs_form .btn {
        padding: 10px 12px;
    }
    .checkin_time_content{
        display: none !important;
    }
    .service_plan_content {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
        padding: 0 15px;
    }
    .features_content_card{
        margin-bottom: 20px;
    }
    .service_plan_content .features_content_card .features_content .features_content_body {
        padding: 20px 0 20px 20px;
    }
    .service_plan_content .service_plan_card .service_plan_top_content {
        padding: 10px 0;
    }
    .service_plan_content .col:not(:last-child) .service_plan_card {
        margin-bottom: 20px;
    }
    .service_plan_content .features_content_card .features_content .features_content_body ul li {
        margin-bottom: 20px;
    }
}

@media (max-width: 576px) {
    .scenarios_content .scenarios-slider{
        display: none;
    }
    .scenarios_mobile_content{
        padding: 0 15px;
        display: block;
    }
    .scenarios_mobile_content .scenarios_card{
        width: 100%;
    }
     .representatives_section_list{
         margin-left: 0!important;
     }
    .banner_section .banner_content .banner_title{
        font-size: 35px;
    }
    .banner_details .banner_sub_title {
        font-size: 23px;
    }
    .banner_section .banner_bg_image .circle1,
    .banner_section .banner_bg_image .circle2,
    .banner_section .banner_bg_image .triangle1,
    .banner_section .banner_bg_image .triangle1_1,
    .banner_section .banner_bg_image .triangle2,
    .banner_section .banner_bg_image .triangle3{
        display: none;
    }
    .banner_section .banner_bg_image .main_circle {
        top: 3%;
        left: 10px;
    }
    .banner_section .banner_bg_image .arrow_line {
        top: 30%;
        left: 20%;
    }

    .representatives_section .representatives_content .tab_content{
        width: 100%;
    }
    .representatives_section .representatives_content .representatives_title {
        font-size: 25px;
        margin-bottom: 20px;
    }
    .RepresentativesContentBg {
        position: absolute;
        right: -30%;
        bottom: -22%;
    }
    .representatives_section,
    .thought_section,
    .solution_section,
    .features_section{
        padding: 30px 0;
    }
    .solution_content .solution_tab .solution_tab_content{
        min-height: 70px;
    }
    .solution_content .solution_tab .solution_tab_content{
        font-size: 20px;
    }
    .scenarios_content .carousel .control-next{
        right: 30%;
    }
    .scenarios_content .carousel .control-prev{
        left: 30%;
    }
    .solutionBGLeftTop{
        width: 80%;
        position: absolute;
        top: -10%;
        left: -57%;
        z-index: 0;
    }
    .solutionBGRight{
        position: absolute;
        bottom: -5%;
        right: -35%;
        width: 80%;
    }
    .FeaturesRightBg,
    .FeaturesLeftBg{
        width: 30%;
    }
    .FeaturesLeftBg {
        bottom: -30px;
    }
    .main_footer {
        padding: 28px 15px 0;
    }
    .logo_img img{
        width: 60%;
    }
    .social_media_icons {
        margin-bottom: 0;
    }
    .footer_menu {
        padding: 20px 10px;
    }
    .footer_menu  ul{
        display: block;
    }
    .footer_menu ul li a {
        font-size: 18px;
    }
}

/*responsive header plan*/
@media (max-width: 576px) {
    .service_plan_content .service_plan_top_content .plan_time_content{
        display: block;
    }
    .service_plan_card .service_plan_top_content{
        padding: 0 !important;
        overflow: hidden;
    }
    .service_plan_card .service_plan_top_content .plan_start_btn{
        padding-bottom: 32px;
        margin: 0;
    }
    .service_plan_card_1 .service_plan_top_content .plan_time{
        color: white !important;
        background-color: #FF238C;
        padding: 12px;
        margin-bottom: 23px !important;
    }
    .service_plan_card_2 .service_plan_top_content .plan_time{
        color: white !important;
        background-color: #c64dff;
        padding: 12px;
        margin-bottom: 23px !important;
    }
    .service_plan_card_3 .service_plan_top_content .plan_time{
        color: white !important;
        background-color: #FFB800;
        padding: 12px;
        margin-bottom: 23px !important;
    }
    .service_plan_card_4 .service_plan_top_content .plan_time{
        color: white !important;
        background-color: #008EFF;
        padding: 12px;
        margin-bottom: 23px !important;
    }
    .service_plan_card_5 .service_plan_top_content .plan_time{
        color: white !important;
        background-color: #00DAE8;
        padding: 12px;
        margin-bottom: 23px !important;
    }
}

.Standard-features-main{
    background: #F9F9F9;
    padding: 50px 0;
    margin-top: 10px;
}

.Standard-features-main .Standard-features-icon{
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #F9F9F9;
    display: flex;
    align-items: center;
    justify-content: center;

}
.Standard-features-title{
    margin-top: 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #1F2A40;

}
.Standard-features-container{
    padding: 0 150px;
}

.Standard-features-box{
    width: 20%;
    padding: 10px;
}

.Standard-features-main-3{
    background: #F9F9F9;
    padding: 30px 0;
    margin-top: 50px;
}
.Standard-features-main-3 .Standard-features-icon-3{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #F9F9F9;
    display: flex;
    align-items: center;
    justify-content: center;

}
.Standard-features-title-3{
    margin-top: 15px;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #1F2A40;
}
.Standard-features-container-3{
    padding: 0 10px;
}

.Standard-features-box-3{
    width: 19%;
    padding: 5px;
}
.service_plan_des-3{
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: black;
    /*color: #A2A2A2;*/
    margin-bottom: 15px;
}
@media (max-width: 1150px) {
    .Standard-features-box{
        width: 25%;
    }
    .Standard-features-container{
        padding: 0 50px;
    }
}

@media (max-width: 991px) {
    .Standard-features-box{
        width: 33.33%;
    }
    .Standard-features-container{
        padding: 0 50px;
    }
}

@media (max-width: 767px) {
    .Standard-features-box{
        width: 50%;
    }
    .Standard-features-container{
        padding: 0 50px;
    }
}

@media (max-width: 575px) {
    .Standard-features-box{
        width: 90%;
        margin: auto;
    }
    .Standard-features-container{
        padding: 0 20px;
    }
}





.Standard-features-inner{
    background: white;
    border-radius: 22px;
    padding: 40px 10px;
    height: 100%;

}
.your-info-section {
    font-weight: 500;
    /*background: white;*/
    padding: 8px;
    width: 70%;
    margin-left: 33px;
    margin-bottom: 17px;
}

.representHeding{
    font-size: 40px;
    color: #e83e8c;
    margin-left: 60px;
}

