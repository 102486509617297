.contact-section .col-md-3 {
    max-width: 30% !important;
}

.contact-section .col-md-9 {
    padding-left: 0px;
}

.contact-section .my-table {
    cursor: pointer;
}

.lable-case {
    margin-bottom: 1px;
    font-size: 18px;
}

.white-spaces-pre{
    white-space: pre;
}

.contact-section input {
    font-size: 17px;
    font-family: "Open Sans", sans-serif;
}

.contact-section .table-bordered td,
.table-bordered th {
    border: 1px solid darkgray;
}

.contact-section .table-bordered td {
    font-size: 15px;
    vertical-align: middle;
    text-align: center;
}

/*.contact-section .table-bordered td:first-child {*/
/*    text-align: left;*/
/*}*/

.contact-section .table-bordered th {
    font-size: 15px;
    padding: 10px 10px;
}

.contact-section .table-bordered th:first-child {
    width: 30%;
}

.contact-section .react-bootstrap-table th .order > .dropdown > .caret {
    margin: 10px 0 10px 5px;
    color: #cccccc;
}

.contact-section .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid \9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}

.contact-section .dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
    content: "";
    border-top: 0;
    border-bottom: 4px dashed;
    border-bottom: 4px solid \9;
}

.contact-section .react-bootstrap-table {
    cursor: pointer;
}

.contact-section .text-end {
    text-align: end;
}

.contact-section .table-bordered th:nth-child(5n + 4),
.table-bordered td:nth-child(5n + 4) {
    width: 25%;
}

.contact-section .table-bordered th:nth-child(9) {
    width: 19%;
}

.contact-section .table-bordered th:nth-child(5n + 2) {
    width: 6%;
}

.contact-section .contact-form-data select,
.zip-input-field {
    width: 50%;
}

.contact-section .table-bordered th:nth-child(5n + 3) {
    width: 25%;
}
.contact-section .table-bordered th:nth-child(8) {
    width: 8%;
}

.contact-section .table-bordered th:nth-child(6) {
    width: 8%;
}

.contact-section .table-bordered td:nth-child(3),
.contact-section .table-bordered td:nth-child(4),
.contact-section .table-bordered td:nth-child(5),
.contact-section .table-bordered td:nth-child(6),
.contact-section .table-bordered td:nth-child(7),
.contact-section .table-bordered td:nth-child(9) {
    width: 12%;
    text-align: left;
}

.contact-section .table-bordered th:first-child {
    width: 8%;
}

.contact-section .delete-contact-table-row {
    cursor: not-allowed;
}

.contact-section .contact-form-data .form-group {
    margin-bottom: 10px;
}

.contact-section .btn-add-to-contact {
    padding-left: 71px;
}

.contact-section .form-control {
    height: calc(1.2em + 0.75rem + 2px);
}

.contact-section .row {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.contact-section .logout-btn {
    color: #7030a0;
    font-weight: 700;
    border: 1px solid #eee;
}

.contact-section .back-btn {
    border: 1px solid #eee;
    font-weight: 600;
}

.contact-section .checkbox-flex {
    display: flex;
    align-items: center;
    width: 72.5%;
}

.contact-section .checkbox-flex label {
    margin: 0;
}

.checkbox-flex .emer_check_box {
    width: 20px;
    height: 20px;
}

.contact-section .inner-right-side-section {
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.1);
    padding: 20px 23px 20px 0;
    border-radius: 5px;
    max-width: 780px;
    float: right;
    border: 1px solid darkgray;
}

.contact-section textarea {
    height: calc(6em + 0.75rem + 2px) !important;
}

.contact-section select {
    height: calc(1.4em + 0.75rem + 2px) !important;
    font-size: 16px;
}

.contact-section .btn-hover:hover {
    background: transparent;
    border: 2px solid #7030a0;
    color: #7030a0;
    font-weight: 600;
}

.contact-section input[type="checkbox"],
.profile-main-section input[type="checkbox"] {
    cursor: pointer !important;
    width: 15px;
    height: 18px;
}

.contact-section .react-bootstrap-table .table th:nth-child(6n + 5),
.contact-section .react-bootstrap-table .table th:nth-child(6n + 6),
.contact-section .react-bootstrap-table .table th:nth-child(6n + 7) {
    width: 28%;
}

.contact-section label {
    font-weight: 600;
    margin-bottom: 0;
    padding-top: 0 !important;
}

.contact-person-field {
    display: flex;
    align-items: center;
}

.PhoneInputInput {
    border: 0;
    border-bottom: 1px solid darkgray;
    border-radius: 0px;
    padding: 4px 0;
}

.contact-order-person .btn-order-contact {
    width: 21%;
    background: none;
    border: none;
    padding: 0;
    text-align: center !important;
}

.contact-order-person .btn-order-contact input {
    padding: 5px;
    text-align: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.contact-order-person .btn-contact-lable {
    background: none;
    border: none;
    padding: 0;
    color: black;
    margin-right: 7px;
}

.contact-section
.contact-order-person
.btn-primary:not(:disabled):not(.disabled).active,
.contact-order-person .btn-primary:not(:disabled):not(.disabled):active,
.contact-order-person .show > .btn-primary.dropdown-toggle {
    background: none;
    border: none;
    padding: 0;
    color: black;
}

.contact-section
.contact-order-person
.btn-primary:not(:disabled):not(.disabled).active:focus,
.contact-order-person .btn-primary:not(:disabled):not(.disabled):active:focus,
.contact-order-person .show > .btn-primary.dropdown-toggle:focus {
    outline: none !important;
    box-shadow: none;
}

.contact-section .btn-primary.focus,
.contact-order-person .btn-primary:focus {
    box-shadow: none;
}

.contact-section .conatacty-person-label {
    margin: 0;
}
.contact-table-sec {
    padding-left: 16px;
}

.add_contact_content {
    display: flex;
    align-items: center;
    width: 100%;
}
.add_contact_content .shared_content {
    display: flex;
    align-items: center;
    margin-left: 100px;
    width: 100%;
}
.add_contact_content .shared_content label {
    font-size: 20px;
    font-weight: 700;
    margin: 0 10px 0 0;
}
.add_contact_content .shared_content .selectOption {
    border-width: 3px;
    border-color: black !important;
}
.shared_content .Mui-focused .MuiSelect-selectMenu {
    background: transparent;
}

/*.contact-table-sec .MuiGrid-spacing-xs-2 {*/
/*     width: 100%;*/
/*     margin: 0px;*/
/*}*/
.contact_data_table {
    /*overflow: scroll;*/
}
.contact_data_table table th{
    width: unset!important;
}
.contact_data_table table th:first-child{
    width: 80px!important;
}
.contact_data_table table th:nth-child(2){
    width: 50px!important;
}
.contact_data_table table th:nth-child(3),
.contact_data_table table th:nth-child(4){
    width: 120px!important;
}
.contact_data_table table th:nth-child(5){
    width: 130px !important;
}
/*.contact_data_table table th:nth-child(6){*/
/*  width: 100%!important;*/
/*}*/
.contact_data_table table th:nth-child(7),
.contact_data_table table th:nth-child(8),
.contact_data_table table th:nth-child(9)
{
    width: 130px!important;
    text-align: center;
}

@media only screen and (max-width: 1366px) {
    .contact-table-sec {
        overflow: auto;
    }

    .contact-section .react-bootstrap-table {
        width: 1580px;
        overflow: auto;
    }
    .contact-section .react-bootstrap-table .table {
        width: 100%;
    }

    .contact-section label {
        font-size: 14px;
    }
}

.PhoneInputCountry {
    border: 0;
    border-bottom: 1px solid darkgrey;
    padding: 4px;
    border-radius: 0px;
}
.sendBtn {
    margin-bottom: 5px;
}
.inviteBtn {
    display: flex;
    justify-content: center;
}

.close {
    position: absolute;
    right: 16px;
}

.New-Contact-form-data label {
    font-weight: bold;
}
.contact_person_model .modal-footer {
    border-color: black;
}
.modal-footer .contact_person_footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.modal-footer .contact_person_footer .btn_group {
    display: flex;
    align-items: center;
}

.modal-footer .contact_person_footer .btn_group .custom-btn {
    width: 178px;
    font-size: 16px;
    padding: 5px 20px;
    box-shadow: rgb(0 0 120 / 20%) 4px 3px 1px 1px;
    margin: 0 10px 0 0 !important;
}
.modal-footer .contact_person_footer .share-container .invite-button {
    width: 188px;
    padding: 5px 40px;
    margin: 0 !important;
}

.btnContainer {
    flex-wrap: wrap;
}

.btnContainer .btn {
    width: 160px;
    margin-bottom: 5px;
}

.link_url {
    right: 14%;
    position: relative;
}

.checkbox-contact {
    margin-left: 12px;
}
.checkbox-contact .Checked-box {
    width: 20px;
    height: 18px;
}

.underline-text-all {
    word-break: break-all;
}
.underline-text {
    word-break: break-all;
}
.underline-text:hover {
    text-decoration: underline;
    color: #007bff;
}

/* Card Box*/
.user_card .contact_card {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}
.action_btn {
    height: 35px;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.title_name {
    margin: 0;
    color: white;
}
.contact_card {
    border-left: 1px solid #bac2af;
}
.contact_card .card_body {
    font-weight: 500;
    height: 140px;
    width: 334px;
}
.contact_card .user_name,
.contact_card .user_phone_number,
.contact_card .user_email {
    text-overflow: ellipsis;
    overflow: hidden;
}

.contact_card .user_email .email_address{
    font-size: 14px;
}

@media (max-width: 375px) {
    .card_grid {
        width: 100%;
    }
    .card_grid .MuiCardActions-spacing {
        flex-wrap: wrap;
    }
    .card_grid .MuiCardActions-spacing .btn {
        font-size: 11px;
    }
    .btnContainer {
        flex-direction: column-reverse;
        align-items: normal !important;
    }
    .styles_icon {
        width: 100%;
        justify-content: end;
    }
    .contact_card .card_body {
        height: auto;
        width: 100%;
        word-break: break-all;
    }
    .distribute_btn {
        margin-left: 0px !important;
    }
}
.iconchange {
    width: 35px !important;
    height: 35px !important;
}

.contact_card .MuiCardActions-spacing {
    border-top: 1px solid #b4b6b0;
}
.card_grid .MuiPaper-rounded {
    border-radius: 0px !important;
}
.contact_card {
    box-shadow: 6px 6px 1px 1px rgba(0 0 120 / 20%) !important;
    border-top: none;
}

/* Card Box*/
.card_header {
    background: #7030a0;
    color: white;
}
.card_header_danger {
    background: #7030a0;
    color: white;
}
.card_header .MuiTypography-h5 {
    font-size: 20px;
}
.contact_card .MuiCardContent-root {
    font-weight: 500;
}
.button_contact .MuiSvgIcon-root {
    height: 40px !important;
    width: 40px !important;
}
.tiles {
    border: 1px solid #898686;
    border-radius: 5px;
}
.tile-img {
    height: 16px;
    width: 20px;
    cursor: pointer;
}
.hzview-icon {
    cursor: pointer;
    width: 40px !important;
    height: 40px !important;
}
.card_header_danger .edit_btn_contact:hover {
    background: white !important;
}
.card_header_danger,
.card_header {
    padding: 8px !important;
}
.card_header_danger .edit_btn_contact,
.card_header_danger .delete_btn_contact,
.card_header .edit_btn_contact,
.card_header .delete_btn_contact {
    background: white;
    border: none;
    color: black;
    font-weight: 500;
    padding: 2px 20px;
    text-decoration: none !important;
    cursor: pointer;
}

.card_header_danger .edit_btn_contact:hover {
    background: white !important;
}
.card_header_dangerBody {
    background: #00a3e8;
}
.card-footer-contact {
    min-height: 56px;
}
.card-footer-contact .shareIcon {
    cursor: pointer;
}
.card-footer-contact button {
    font-size: 15px;
}
.SharedWith-Verbiage-Title {
    padding-bottom: 10px;
}

.genie_parent {
    position: absolute;
    left: -10%;
    top: 50%;
    transform: translateY(-50%);
}
.GenieMen {
    left: -13%;
}
.GenieMen img {
    width: 203px;
    height: 240px;
}
.contact_description {
    font-size: 20px;
    font-weight: 400;
}

@media (max-width: 1450px) {
    .genie_parent {
        left: -12%;
    }
}
@media (max-width: 1200px) {
    .genie_parent {
        position: relative;
        left: 0;
        top: 0;
        transform: translateY(0);
        display: flex;
        justify-content: center;
        margin: 10px 0;
    }
}
.genieDetailsModal .modal-dialog {
    max-width: 625px;
}
.distributed-Instructions {
    border: 1px solid rgb(0 0 0 / 45%);
    padding: 8px;
    font-weight: bold;
}
.contactGenieModalContainer .modal-content {
    border: 1px solid #007bff;
}
.contactGenieModalContainer .modal-dialog {
    max-width: 840px;
}
.Send-Invitation-Confirmation-Modal .modal-content {
    border: 1px solid #007bff;
}
.contactGenieModalContainer hr {
    margin-top: 4px;
    margin-bottom: 4px;
}
.contact_data_table .card_grid {
    width: 351px;
}

@media (max-width: 576px) {
    .New-Contact-form-data {
        margin: 0 !important;
    }
    .checkbox-flex {
        flex-wrap: wrap;
    }
    .contact-order-person {
        width: 100% !important;
        padding-left: 15px;
        justify-content: flex-start !important;
    }
    .button_contact > div {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    .button_contact .btn-success {
        margin-right: 0 !important;
    }
    .font-weight-bold a {
        word-break: break-word;
    }
    .contact_data_table .card_grid {
        width: 100%;
    }
}
.genie_contact {
    position: relative;
    z-index: 9;
}
.Contact-model .modal-dialog {
    float: right;
    margin-right: 15px;
}
.flexible-modal {
    z-index: 999999 !important;
    height: fit-content!important;
    border: 5px solid rgb(25 178 236 / 20%) !important;
}
.flexible-modal-drag-area {
    background: rgb(25 178 236 / 20%) !important;
}
.header_contact_suggestin {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}
.header_Contact {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    position: relative;
}
.close_icon {
    position: absolute;
    z-index: 999999;
    right: -1px;
    top: -8px;
    cursor: pointer;
}
.start_icon {
    z-index: 999999;
    position: absolute;
    left: 11px;
    top: -2px;
    cursor: pointer;
}
.tooltip_Contact {
    z-index: 99999999999;
}
.invite_btn {
    border: none;
    display: flex;
    box-shadow: none;
}
.invite_btn .MuiCircularProgress-indeterminate {
    width: 26px !important;
    height: 26px !important;
}

.share-container {
    display: flex;
    justify-content: center;
}
.share-container img {
    width: 18px;
}
.share-container .notify-button {
    background-color: #0957c1;
    border: none;
    font-size: 15px;
    width: 50%;
}
.share-container img,
.custom-btn img {
    width: 18px;
}


.white-spaces-break{
    white-space: break-spaces;
}
.diamond {
    width: 24px;
    height: 24px;
    background-color: green;
    transform: rotate(45deg);
    position: relative;
}

.link {
    color: #4385F3;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    font-weight: 600;
    font-size: 13px;
}

.link:hover {
    text-decoration: underline;
}
.transaction-failed-support p{
    margin-bottom: 5px!important;
    font-size: 18px;
    font-weight: 600;
    display: flex;
}
.transaction-failed-support span{
    width: 120px;
    display: flex;
}