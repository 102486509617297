.inroduction_details .inroduction_heading {
    font-family: 'Poppins', sans-serif;
    margin-bottom: 46px;
}

.introduction_section {
    width: 100%;
    height: 100%;
    background-image: url("../../../assest/images/background.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.inroduction_image{
    max-width: 100%;
}

.inroduction_heading h1 {
    text-align: center;
    max-width: 1177px;
    margin: auto;
    font-size: 62px;
    color: #000;
    font-weight: 600;
    line-height: 80px;
}

.inroduction_heading h1 span {
    text-transform: capitalize;
    background:  linear-gradient(to right, #007BFF, #92BCEA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.inroduction_heading p {
    margin: auto;
    text-align: center;
    max-width: 814px;
    color: #777777;
    font-size: 18px;
    padding-top: 23px;
}

.inroduction_details_btn{
    margin-bottom: 39px;
}

.inroduction_details_btn .btn_outlinebtn{
    border: 1px solid #007BFF;
    border-radius: 40px;
    background-color: #fff;
    padding: 12px 17px;
    font-weight: 500;
    background:  linear-gradient(to right, #007BFF, #92BCEA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    font-size: 18px;
}

.inroduction_details_btn .btn_primarybtn{
    background-color:#007BFF !important;
    color: #fff;
    border-radius: 40px;
    margin-left: 20px;
    font-weight: 600;
    font-size: 20px;
    padding: 12px 24px;
}

.security_banner{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    background-image:  linear-gradient(to right, #007BFF, #92BCEA);
    /* background-color: #007BFF; */
}

.security_banner h4{
    color: #fff;
    font-size: 28px;
    font-weight: 600px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
}
.security_banner .security_banner_image img{
    width: 100%;
    margin-right: 63px;
}

@media screen and (max-width: 1200px){
    .security_banner .security_banner_image img{
        margin-right: 0px;
    }
}

@media screen and (max-width: 768px) {
    .inroduction_heading h1{
        width: 100%;
        font-size: 40px;
        line-height: 45px;
    }
    .security_banner h4 {
        font-size: 18px;
    }
    .inroduction_heading p {
        font-size: 16px;
    }
}

@media screen and (max-width: 567px) {
    .inroduction_details_btn{
        text-align: center;
    }
    .inroduction_details_btn .btn{
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }
    .inroduction_details_btn .btn_primarybtn {
        margin-left: 0;
        font-size: 14px;
    }
}