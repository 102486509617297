.signup_form {
    width: 100%;
    height: 100vh;
    min-height: 920px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../../../assets/images/LandingPageNew/coverSignup.png") no-repeat center;
    background-size: cover;
    padding: 0 16px;
}

.signup_form .form_title {
    font-weight: 700;
    font-size: 40px;
    color: #282828;
    margin-bottom: 40px;
}

.signup_form .form_content {
    width: 450px;
    background: white;
    filter: drop-shadow(0px 9px 20px rgba(0, 0, 0, 0.25));
    padding: 20px 30px;
    border-radius: 25px;
    margin-right: -64%;
}


.signup_form .pass_show_signup {
    right: 45px;
    top: 20px;
}
.signup_form .bottom-link{
    text-align: center;
    margin-top: 10px;
}
.signup_form .bottom-link a{
    font-size: 18px;
}
.signup_form .MuiSvgIcon-root {
    width: 25px !important;
    height: 25px !important;
}

.signup_form .form_content .form-control {
    border: none;
    border-bottom: 1px solid #DADADA;
    border-radius: 0;
    font-size: 14px !important;
    padding: 15px 10px;
    height: auto;
    box-shadow: none;
}

.signup_form .form_content .PhoneInputInput {
    border-bottom: 1px solid #DADADA;
}

.signup_form .form_content .googleLoginBtn{
    padding: 7px !important;
}
.signup_form .form_content .googleLoginBtn,
.signup_form .form_content .kep-login-facebook {
    box-shadow: none !important;
    font-family: 'Inter' !important;
    height: 56px !important;
}
.signup_form .form_content .kep-login-facebook {

}

.signup_form .form_content .kep-login-facebook {
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #282828 !important;
    text-transform: capitalize;
}

.signup_form .form_content .PhoneInputInput {
    outline: none;
}

.signup_form .form_content .btn {
    font-size: 16px;
    padding: 15px;
}
@media (max-width: 1244px ) {
    .signup_form .form_content {
        margin-right: 0;
    }
}

@media (max-width: 768px) {
    .signup_form {
        min-height: 780px;
    }

    .signup_form .form_content {
        padding: 40px 40px;
    }

    .signup_form .form_title {
        font-size: 30px;
        margin-bottom: 20px;
    }

}

@media (max-width: 576px) {
    .signup_form .form_content {
        padding: 30px 20px;
    }
}











































