.reviews_card{
    width: 100%;
    min-width: 200px;
    max-width: 480px;
    border: 0px solid #C9C9C9;
    border-radius: 15px;
    padding: 10px 30px;
    font-weight: bold;
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
}

.reviews_card svg{
    height: 25px !important;
    width: 25px !important;
}

.profile_photo{
    width: 50px;
    height: 50px;
    border: 0 solid transparent;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    color: white;
}

@media  (max-width: 789px) {
    .reviews_card{
        margin: auto;
        margin-bottom: 20px;
    }
}
