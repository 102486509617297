.contact_introduction_section{
    font-family: 'Poppins', sans-serif;
    padding-top: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
    padding-bottom: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
}

.contact_introduction_section .introduction_left{
    margin: auto 0;
}
.introduction_left h3 {
    color: var(--black);
    font-size: calc(28px + (36 - 28) * ((100vw - 300px) / (1920 - 300)));
    font-weight: 600;
    text-transform: capitalize;
}
.introduction_left h3 span{
    text-transform: capitalize;
    background:  linear-gradient(to right, #007BFF, #92BCEA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.introduction_left p{
    color: #808080;
    font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1920 - 300)));
    font-style: normal;
    font-weight: 400;
}

@media (max-width: 768px){
    .contact_introduction_section {
        padding: 40px 20px;
    }
}