.introduction_container {
    width: 100%;
    height: fit-content;
    padding: 64px 0;
    /*background-image: url("../assets/images/background.png");*/
    background-size: cover;
    background-repeat: round;
    text-align: center;
}

.introduction_content .represent_heading {
    color: #FF238C;
    font-size: 20px;
    font-weight: 500;
    word-wrap: break-word;
    font-family: 'Inter', sans-serif;
    padding-top: 22px !important;
}

.introduction_content .heading {
    max-width: 1016px;
    color: #101010;
    font-size: 48px;
    font-family: 'Bai Jamjuree', sans-serif;
    font-weight: 600;
    text-transform: capitalize;
    line-height: 66px !important;
    word-wrap: break-word;
    margin-top: 0px !important; ;
}

.introduction_content .introduction_description {
    max-width: 794px;
    color: #797979;
    font-size: 18px;
    font-family: 'Bai Jamjuree', sans-serif;
    font-weight: 400;
    text-transform: capitalize;
    line-height: 35px;
}

.introduction_images {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px;
    margin-top: 56px;
}

@media (max-width: 768px) {
    .introduction_container {
        padding: 64px 10px;
    }

    .introduction_content .heading {
        font-size: 38px;
        line-height: 38px;
    }

    .introduction_content .introduction_description {
        font-size: 15px;
        font-weight: 400;
        line-height: 25px;
    }

    .wish_image img {
        width: 100%;
    }
}

@media (max-width: 576px){
    .introduction_content .represent_heading {
        font-weight: 400;
        font-size: 14px;
    }
    .introduction_content .heading {
        font-size: 30px;
        line-height: 40px;
    }
}