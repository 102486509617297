.navbar {
    background: white;
    padding: 20px 80px;
    box-shadow: 0px 4px 60px rgba(0, 0, 0, 0.07)
}

.header-btn {
    padding: 18px 46px;
    font-size: 16px;
    font-weight: 600;
    word-wrap: break-word;
    border-radius: 12px;
    color: #FFF;
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
}
.navbar-brand {
    padding: 0;
    img {
        width: 260px;
    }
}
.btn-light {
    background: #EDF5FE;
    color: #008EFF;
    margin-left: 16px;
}
.main_menu_list{
    gap: 30px;
}

.menu_item{
    list-style: none;
}

.mobile_menu .header_logo{
    padding: 20px 15px;
}

.mobile_menu .btn{
    padding: 0;
    text-align: start;
}

@media (max-width: 768px) {
    .navbar {
        padding: 20px 26px;
    }
        .main-header{
            padding: 20px 15px;
        }
        .main_menu_list{
            display: none !important;
        }
        .main-header .menu_bar {
            display: block;
        }
}

@media (max-width: 576px) {
    .navbar {
        padding: 20px 15px;
    }
    .new_landing_page .navbar-text {
       display: none;
    } 
}