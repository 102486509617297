.custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 2rem;
    color: #000; /* Change this to your desired arrow color */
    z-index: 2;
    cursor: pointer;
    outline: none; /* Remove default outline */
}

.custom-arrow-prev {
    left: -50px; /* Adjust the value to position the arrow outside the image */
}

.custom-arrow-next {
    right: -50px; /* Adjust the value to position the arrow outside the image */
}

.carousel-root {
    position: relative;
}

.carousel .slide {
    background: none; /* Remove white background */
}

.carousel .control-dots {
    bottom: -36px; /* Adjust the position of the dots */
}

.carousel .control-dots .dot{
    border: 4px solid #b1d7ff  !important;
    width: 13px;
    height: 13px;
    opacity: 1;
    box-shadow: none;
}

.carousel .control-dots .selected{
    background-color: #b1d7ff   ;
}

.carousel.carousel-slider {
    overflow: unset !important;
}

.carousel-imges img{
    width: 264px !important;
    height: 400px;
}

@media only screen and (max-width: 380px){
    .custom-arrow-prev {
        display: none !important;
    }

    .custom-arrow-next {
        display: none !important;
    }

    .carousel .control-dots{
        display: block !important;
    }

}
