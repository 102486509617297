:root {
    --primary: #007BFF;
    --secondary: #74788d;
    --dark: #16192c;
    --white: #ffff;
    --primary-gry: linear-gradient(90deg, hsla(211, 100%, 50%, 1) 0%, hsla(212, 57%, 91%, 1) 100%);
    --danger: #F9329C;
}

/* Header css start */
.webcopy_main_header {
    padding: 20px 36px;
}

.webcopy_main_header .menu_item_book a{
    color: var(--primary)   !important;
}
.webcopy_main_header .menu_item_book a{
    color: var(--primary)   !important;
}
.webcopy_main_header .menu_item a {
    font-size: 18px;
}

.webcopy_main_header .header_menu_btn {
    padding: 8px 26px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border-radius: 10px;
    border: 1px solid var(--primary);
    cursor: pointer;
}

.webcopy_main_header .header_menu_btn:hover {
    background-color: rgba(0, 123, 255, .8) !important;
    border: 1px solid var(--primary);
}

.webcopy_main_header .header_menu_outlinebtn {
    padding: 8px 26px;
    font-size: 16px;
    border: 1px solid var(--primary);
    color: var(--primary);
    background-color: transparent;
    border-radius: 10px;
    cursor: pointer;
}

.webcopy_main_header .header_menu_outlinebtn:hover {
    background-color: #007bff;
    color: #fff;
}

/* Header css end */

.section_container {
    padding: 3rem 2rem;
    max-width: 100%;
    position: relative;
}

.main_title {
    color: var(--dark);
    margin-bottom: 0.5rem;
    font-weight: 500;
    font-size: calc(26px + (35 - 26) * ((100vw - 300px) / (1920 - 300)));
    font-weight: 700;
    text-transform: capitalize;
    background: linear-gradient(to right, #007BFF, #92BCEA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.sub_title {
    color: var(--secondary);
    font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1920 - 300)));
    margin-top: 1rem;
}

.for_you_list {
    list-style: none;
    margin-top: 2rem;
}

.button_primary {
    font-size: 18px;
    font-weight: 500;
    padding: 10px 26px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border-radius: 10px;
    border: 1px solid var(--primary);
    cursor: pointer;
}

.Buy {
    padding: 2px 45px 2px 45px;
}

.button_primary:hover {
    background-color: rgba(0, 123, 255, .8);
    color: var(--white);
    color: var(--white);
}

.button_primary_outline {
    padding: 8px 26px;
    font-size: 16px;
    border: 1px solid var(--primary);
    color: var(--primary);
    background-color: transparent;
    border-radius: 10px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
}

.button_primary_outline:hover {
    background-color: #007bff;
    color: #fff;
}

.home_webcopy_section .inroduction_details .card{
    border-radius: 1rem;
}

.for_you_list li {
    padding: 1rem 0;
    font-size: 18px;
    font-weight: 400;
    position: relative;
    margin-left: 3.5rem;
    color: var(--secondary);
}

.for_you_list li span {
    position: absolute;
    left: -60px;
    width: 45px;
    top: 18px;
    height: 45px;
    border-radius: 10px;
    background-color: rgba(0, 123, 255, .1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.for_you_list li span svg {
    width: 18px !important;
    height: 18px !important;
    fill: var(--primary);
}

.for_you_content_img img {
    //outline: 6px solid #8ac3ff;
    outline-offset: -25px;
}

.quote_section {
    padding: 2rem;
    background-image: url('../../../src/assets/images/homeWeb/bg1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.quote_box_content {
    max-width: 60%;
    margin: auto;
}

.quote_box_content h6 {
    color: #bab7b7;
    font-weight: 600;
    margin-top: 1rem;
}

.quote_box_content h4 {
    color: var(--white);
    line-height: 36px;
    font-weight: 400;
    font-style: italic;
    text-wrap: pretty;
}

.quote_box_content svg {
    fill: var(--primary) !important;
}

.quote_box_container {
    position: relative;
    border-radius: 15px;
}

.avtar-img {
    display: flex;
    justify-content: center;
}

.avtar-img span {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 50%;
    outline: 2px solid var(--white);
    outline-offset: 2px;
    /* background-color: rgba(0, 123, 255, .1); */
    background-color: var(--white);
    font-size: 28px;
    font-weight: 600;
    color: var(--primary);
}

.quote_icon {
    width: 60px !important;
    height: 60px !important;
    font-size: 500%;
}



.side_box_section {
    padding: 1rem 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../../../src/assets/images/homeWeb/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.secure_section {
    background-image: url('../../../src/assets/images/homeWeb/bg2.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
}

/* .side_box_section h2 {
    position: relative;
    font-size: calc(26px + (35 - 26) * ((100vw - 300px) / (1920 - 300)));
    font-weight: 500;
    color: var(--primary);
} */

.side_box_section p {
    font-size: 18px;
    margin-top: 1rem;
    margin-top: 1rem;
    color: var(--secondary);
    margin-bottom: 0 !important;
}
.strong_title {
    font-weight: 600;
}

.for_you_content p {
    font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1920 - 300)));
    color: var(--secondary);
    margin-top: 1rem;
    font-weight: 400;
}


.support_card {
    padding: 16px 20px;
    border: none;
    background-image: url('../../assets/images/homeWeb/bg-7.png');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%
}

.support_card:hover {
    background-color: rgba(0, 123, 255, .2);
}

.support_card.danger_card:hover {
    background-color: rgba(249, 50, 156, .2);
}

.support_card.danger_card {
    background-image: url('../../assets/images/homeWeb/bg-6.png');
}

.support_card.danger_card .card-body h4 {
    color: var(--danger);
}

.support_card .card-body h4 {
    font-size: 26px;
    font-weight: 500;
    color: var(--primary);
}

.support_card .card-body p {
    margin-top: 0.5rem;
    margin-bottom: 0 !important;
    font-size: 18px;
    color: var(--secondary);
}

.equal-card {
    height: calc(100% - 1.25rem);
}

@media screen and (max-width:1200px){
    .webcopy_main_header .main_menu_list .menu_item {
        padding: 14px 18px;
    }
}

@media screen and (max-width:992px) {
    .webcopy_main_header .header_menu_button {
        display: none;
    }
}
@media screen and (max-width: 576px) {
    .webcopy_main_header{
        padding: 20px 15px;
    }
    .webcopy_main_header .navbar-brand img{
        width: 200px !important;
    }
}