.free-trial{
    padding-top: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
    padding-bottom: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
}

.outline-btn{
    border-radius: 40px;
    color: var(--white);
    padding: 10px 30px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.6px;
    border: 1px solid;
}
.bg-grediant{
    background: linear-gradient(to right, #007BFF, #92BCEA);
    color: var(--black);
}
.section-title h2{
    font-size: calc(24px + (36 - 24) * ((100vw - 300px) / (1920 - 300)));
    font-weight: 700;
    color: var(--black);

}
.section-title p{
    font-size: calc(18px + (20 - 18) * ((100vw - 300px) / (1920 - 300)));
    color: var(--black);
}
.free-trial-right{
    display: flex;
    align-items: center;
}
.free-trial-right ul{
    list-style: none;
    display: flex;
    align-items: center;
    gap: 30px;
}
.text-white{
    color: var(--white);
}

@media screen and (max-width: 768px) {
    .free-trial-right ul img{
        margin-top: 10px;
    }
    }