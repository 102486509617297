.helper_container {
    height: fit-content;
    padding: 56px 0;
}

.helper_content .helper_heading {
    color: #101010;
    font-size: 48px;
    font-family: 'Bai Jamjuree', sans-serif;
    font-weight: 600;
    text-transform: capitalize;
    word-wrap: break-word;
}

.helper_content .helper_description {
    color: #797979;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Bai Jamjuree', sans-serif;
    text-transform: capitalize;
    word-wrap: break-word;
    margin-top: 16px;
}

.helper__video_content .helper_video_container {
    width: 900px;
    height: fit-content;
    margin-top: 40px;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 22px 64px 0px rgba(0, 0, 0, 0.07);
    border: none;
}

.helper_video_container .helper_video_header {
    background: #0F0F11;
    padding: 18px;
    border-radius: 16px 16px 0px 0px;
}

.helper_video_container .helper_video_frame {
    padding: 28px;
}

.helper_video video {
    width: 100%;
    height: 100%;
    border-radius: 8px;
}

.helper_button .helper_btn {
    width: 196px;
    background: #FF238C;
    border-radius: 12px;
    padding: 18px 45px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    margin-top: 26px;
}

.helper_btn:hover {
    color: white;
    background: #FF238C;
}

@media (max-width: 768px) {
    .helper_container {
        padding: 56px 10px;
    }

    .helper_content .helper_heading {
        font-size: 30px;
    }

    .helper_content .helper_description {
        font-size: 15px;
        font-weight: 400;
        line-height: 25px;
    }

    .helper__video_content .helper_video_container .helper_video_frame {
        padding: 15px;
    }
}

@media (max-width: 576px) {
    .helper_content .helper_heading {
        font-size: 28px;
    }
}