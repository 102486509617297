.main_footer{
    background: #f2f4f7;
    padding: 28px 28px 0;
}
.main_footer .logo_img{
    margin-bottom: 20px;
}
.social_media_icons{
    margin-bottom: 24px;
}
.social_media_icons ul{
    list-style: none;
    display: flex;
}
.social_media_icons ul .social_media_items{
    padding: 0 10px;
}
.social_media_icons ul .social_media_items a{
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #008EFF;
    display: flex;
    align-items: center;
    justify-content: center;
}
.social_media_icons ul .social_media_items svg{
    color: white;
}
.footer_menu {
    padding: 20px;
    border-bottom: 1px solid #CACACA;
}
.footer_menu ul{
    list-style: none;
    display: flex;
}
.footer_menu ul li:not(:last-child){
    padding-right: 52px;
}
.footer_menu ul li a{
    font-size: 22px;
    color: #404040;
}
.copyright{
    padding: 15px;
}

.header_menu{
    display: flex;
    justify-content: space-between;
    padding:20px;
}

.header_menu ul{
    list-style: none;
    display: flex;
}

.header_menu ul li{
    margin:0px 30px;
}

.header_menu ul li a {
    color: #000000;
    font-size: x-large;
    font-weight: 600;
}

 @media (min-width: 600px) and (max-width: 1024px){
    .header_menu ul li a{
      font-size: 15px;
    }
}

@media (min-width: 300px) and (max-width: 768px){
    .header_menu ul li{
        margin:0px 11px;
    }
    .Newheading{
    text-align: center;
    font-weight: 600;
    }
    .Newheading span{
    margin-left: 0;
    }
}

@media (max-width: 425px){
    .header_menu ul li a{
      font-size: 0px;
    }
}

@media (max-width: 768px) {
    .footer_menu ul li:not(:last-child){
        padding-right: 30px;
    }
    .footer_menu ul li a{
        font-size: 20px;
    }
}