.main-header {
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    padding: 36px;
}

.main-header .main_menu_list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.header_logo a img{
    width: 200px;
}

.all-books-img{
    width: 100%;
    max-width: 500px;
}

.booksImgs{
  width: 100%;
  max-width: 500px;
}

.selection-main-div{
    gap: 10px;
}

.selection-main-div .active{
    border: 2px solid #47c5f3;
}

.model-select-book-img{
    width: 100px !important;
}

.organizer_inroduction_heading h4{
    font-size: 30px;
    font-weight: 600;
    color: black;
    padding: 15px 0px ;
}

.main-header .main_menu_list .menu_item {
    text-align: center;
    padding: 14px 25px;
    text-decoration: none;
    display: inline;
}

.book-details-Model .modal-title{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    padding: 0px 10px;
}

.book-details-Model-content-title{
   color: #ff157f;
   font-size: 16px;
    padding-bottom: 15px;
}

.book-details-Model-content-discription{
    font-size: 15px;
    padding-bottom: 10px;
}

.book-details-Model-content-discription span{
    color: #ff157f;

}

.book-details-Model-title{
    font-weight: 700;
    letter-spacing: 1px;
    color: white;
}

.menu_item a {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.menu_item.active a {
    color: #92BCEA;
    font-weight: bold;
}

.menu_item .header_menu_outlinebtn {
    border: 1px solid #007BFF;
    border-radius: 40px;
    padding: 12px 38px;
    color: #000;
    font-weight: 600;
    font-size: 16px;
}

.menu_item .header_menu_btn {
    background-color: #007BFF !important;
    color: #fff;
    border-radius: 40px;
    padding: 12px 30px;
    margin-left: 20px;
    font-weight: 600;
    font-size: 16px;
    border: 0;
}

@media (max-width: 1440px){
   
    .main-header .main_menu_list .menu_item {
        padding: 14px 16px;
    }
}

@media (max-width: 1200px) {
    .main-header .main_menu_list .menu_item {
        padding: 14px 6px;
    }
    .inroduction_details_video video {
        width: 100%;
        height: 100%;
    }
    
}

@media (max-width: 992px){
    .main-header{
        padding: 26px;
    }
    .menu_item .header_menu_outlinebtn {
        border-radius: 40px;
        padding: 8px 24px;
    }
    .menu_item .header_menu_btn {
        padding: 12px 18px;
        margin-left: 10px;
    }
    .main-header{
        padding: 20px 15px;
    }

    .main_menu_list{
        display: none;
    }
    .main-header .menu_bar {
        display: block;
    }
}


@media (max-width: 567px) {
    .organizer_inroduction_heading h1 {
        font-size: 27px;
    }

    .organizer_inroduction_heading h4{
        font-size: 21px;
    }

    .model-select-book-img{
     width: 50px !important;
    }
}




