@import url('https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css');
.home_webcopy_section {
    width: 100%;
    height: 100%;
}
.slick-list{
 overflow-x: hidden;
}
.slick-list .slick-track{
    display: flex;
}
.slick-next:before, .slick-prev:before {
    color: #0f0f11;
}
.home_book_section .home_section {
    padding: 4rem 4rem 2rem;
}


/* .home_webcopy_section .home_section .inroduction_heading {
    max-width: 80%;
    width: 100%;
} */

.home_book_section .side_box_img {
    width: 100%;
    max-width: 550px;
    margin: auto;
    margin-bottom: 10px;
}
.home_book_section .side_box_img img{
    height: auto;
}
.home_book_section .side_box_img button{
    max-width: 190px;
    width: 100%;
}

.home_book_section .home_section .inroduction_heading h6 span {
    font-weight: 700;
    /* font-size: 34px; */
    font-size: calc(28px + (34 - 28) * ((100vw - 300px) / (1920 - 300)));
    line-height: 1.4;
    color: #010038;
    text-transform: capitalize;
    background: linear-gradient(to right, #007BFF, #92BCEA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.home_book_section .home_section .inroduction_heading{
    text-align: center;
}
.home_book_section .home_section .inroduction_heading h6 {
    font-weight: 500;
    color: gray;
    /* font-size: 32px; */
    font-size: calc(22px + (32 - 22) * ((100vw - 300px) / (1920 - 300)));
    line-height: 1.4;
}

@media screen and (max-width:1440px) {
    .home_book_section .home_section {
        padding: 3rem;
    }
    .webcopy_main_header .menu_item a {
        font-size: 15px !important;
    }
}

@media screen and (max-width:1199px) {
    .home_book_section .inroduction_heading {
        margin: auto;
        text-align: center;
    }

    .home_book_section .helper_video_container {
        max-width: 60%;
        margin: auto;
    }

    .storage_section_container .col-xl-4,
    .support_section_container .col-xl-4 {
        margin: 0.5rem 0;
    }
    
   .home_book_section .helper_video_container{
    margin-top: 1.5rem;
   }
}


@media screen and (max-width: 991px) {
    .home_book_section .inroduction_heading {
        max-width: 100%;
    }

    .side_box_section {
        padding: 1rem 0 !important;
        background-image: none !important;
    }

    .quote_section .quote_box_content {
        max-width: 100%;
    }

    .secure_section .row:nth-child(1) {
        flex-direction: column-reverse;
    }

    .secure_section img {
        max-width: 65%;
        display: block;
        margin: auto;
    }

    .section_container {
        padding: 2rem 1rem !important;
    }

    .secure_section .button_primary_outline {
        margin: auto;
        display: block;
    }
}

@media screen and (max-width: 768px) {
    .home_book_section .home_section .inroduction_heading h2 {
        font-size: 40px;
        line-height: 53px;
    }

    .home_book_section .home_section .inroduction_heading h3 {
        font-size: 22px;
    }

    .home_book_section .helper_video_container {
        max-width: 100%;
    }

    .home_book_section .home_section {
        padding: 1rem;
    }
}

@media screen and (max-width: 575px) {
    .home_book_section .home_section .container-fluid,
    .home_book_section .home_section {
        padding: 0;
    }

    .home_book_section .home_section .inroduction_heading h2 {
        font-size: 32px;
        line-height: 42px;
    }

    .quote_box_content h4,
    .home_book_section .home_section .inroduction_heading h3 {
        font-size: 20px;
    }

    .inroduction_details .inroduction_heading {
        text-align: left;
    }

    .section_container {
        padding: 3rem 1rem !important;
    }

    .section_container {
        padding: 1rem 1rem !important;
    }

    .side_box_img {
        margin-top: 20px;
    }
}

@media screen and (max-width: 400px) {
    .secure_section img {
        max-width: 100%;
    }
    .home_book_section .side_box_img button{
        font-size: 14px;
        padding: 5px 15px;
    }

    .storage_section_container .support_card,
    .support_section_container .support_card {
        padding: 16px 0;
    }

    .storage_section_container .support_card .card-body p,
    .support_section_container .support_card .card-body p {
        font-size: 16px;
    }

    .section_container .side_box_section p {
        font-size: 16px;
        margin-top: 1rem;
    }

    .secure_section .button_primary_outline {
        padding: 8px 7px;
        font-size: 15px;
    }

    .support_section_container h2 {
        font-size: 25px;
    }

    .secure_section .mt-5,
    .support_section_container .sub_title,
    .support_section_container .mb-5 {
        margin-bottom: 1rem !important;
    }

    .home_book_section .home_section .button_primary {
        padding: 6px 12px;
        font-size: 15px;
    }

    .home_book_section .helper_video_container {
        margin-top: 15px;
    }

    .quote_box_content h4 {
        font-size: 18px;
    }
    .quote_box_container {
        padding: 0;
    }
    .secure_section .sub_title {
        margin-top: 1rem;
    }
}