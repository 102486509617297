.findmyplan_trial{
    padding-top: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
    padding-bottom: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
}

.bg_grediant{
    font-family: 'Poppins', sans-serif;
    background-image: url("../../../assest/images/trail-image.svg");
    background-size: cover;
}

@media (max-width: 768px){
    .findmyplan_trial{
        padding: 40px 20px;
    }
}