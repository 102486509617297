.main-header {
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    padding: 36px;
}

.main-header .main_menu_list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}
.header_logo a img{
    width: 200px;
}

.main-header .main_menu_list .menu_item {
    text-align: center;
    padding: 14px 25px;
    text-decoration: none;
    display: inline;
}

.menu_item a {
    font-size: 16px;
    color: #000;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.menu_item.active a {
    color: #92BCEA;
    font-weight: bold;
}

.menu_item .header_menu_outlinebtn {
    border: 1px solid #007BFF;
    border-radius: 40px;
    padding: 12px 38px;
    color: #000;
    font-weight: 600;
    font-size: 16px;
}

.menu_item .header_menu_btn {
    background-color: #007BFF !important;
    color: #fff;
    border-radius: 40px;
    padding: 12px 30px;
    margin-left: 20px;
    font-weight: 600;
    font-size: 16px;
    border: 0;
}
.inroduction_heading .new_heading{
    font-size: 42px;
    font-weight: 700;
}
.inroduction_heading .peace_of_mind{
    border: 4px solid #000000;
  border-radius: 10px;
}
.inroduction_heading .home_intro{
    line-height: 60px;
    color: var(--dark);
    margin-bottom: 0.5rem;
    font-size: calc(26px + (35 - 26) * ((100vw - 300px) / (1920 - 300)));
    font-weight: 700;
    text-transform: capitalize;
    background: linear-gradient(to right, #007BFF, #92BCEA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.inroduction_heading .home_intro svg{
  font-size: calc(26px + (35 - 26) * ((100vw - 300px) / (1920 - 300)));
  width: calc(26px + (35 - 26) * ((100vw - 300px) / (1920 - 300))) !important;
  height: calc(26px + (35 - 26) * ((100vw - 300px) / (1920 - 300))) !important;
}
.inroduction_heading .home_intro_text{
    max-width: 80%;
  text-align: left;
}
.menu_item_book a {
    color: #ff0f61 !important;
    font-weight: 600 !important;
}


@media (max-width: 1200px) {
    .main-header .main_menu_list .menu_item {
        padding: 14px 6px;
    }
    .inroduction_details_video video {
        width: 100%;
        height: 100%;
    }
}

@media (max-width: 992px){
    .main-header{
        padding: 26px;
    }
    .menu_item .header_menu_outlinebtn {
        border-radius: 40px;
        padding: 8px 24px;
    }
    .menu_item .header_menu_btn {
        padding: 12px 18px;
        margin-left: 10px;
    }
    .inroduction_heading .new_heading{
        font-size: 32px;
    }
}

@media (max-width: 768px){
    .main-header{
        padding: 20px 15px;
    }

    .main_menu_list{
        display: none;
    }
    .main-header .menu_bar {
        display: block;
    }
    .inroduction_heading .new_heading{
        font-size: 30px;
    }
}




