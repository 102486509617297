.MuiInputBase-input {
    height: auto !important;
}

.MuiOutlinedInput-multiline {
    padding: 17.5px 14px !important;
}

.MuiOutlinedInput-root {
    border-radius: 2px !important;
}

.plan {
    display: flex;
    justify-content: center;
    font-size: 28px;
}

.radiobtn {
    /*margin-left: 20px;*/
}

.PrivateSwitchBase-root-2 {
    padding: 2px !important;
}

.card-plan-radio .MuiFormControl-root {
    margin: 3px !important;
}

.description {
    width: 500px;
    height: 300px;
}

.MuiFormControl-fullWidth {
    width: 94% !important;
}

.card-plan-radio .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #c4c4c4 !important;
    border-width: 2px !important;
    border-radius: 50px;
}

.card-plan-radio .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #c4c4c4 !important;
}

/*.title-text {*/
/*    height: 80px;*/
/*}*/
.planDescription {
    font-size: 19px;
    font-weight: 500;
}


.payment-section-data .BackBtn {
    margin-left: 305px;
}

.main-container-for-description .MuiOutlinedInput-multiline {
    padding: 9px 5px !important;
}
.main-container-for-description  {
    border: 1px solid rgb(0 0 0 / 30%) !important;
    border-radius: 15px;
    padding: 10px;
}
.MainRadioBtn label {
    margin-bottom: 0;
}

.paymentBackBtn {
    background-color: #7030a0 !important;
    color: white;
    padding-left: 15px;
    text-transform: capitalize;
}


.backBtn {
    background-color: #7030a0;
    color: #fff;
}

.packDescriptionData {
    margin: 7px;
    max-height: 370px;
    padding: 7px;
    overflow: scroll;
    overflow-x: hidden;
    border: 0.5px solid #e0dddd;
    border-radius: 3px;
    line-height: 1.2;
}

.packDescriptionDataForPayment {
    max-height: 370px;
    padding: 7px;
    overflow: scroll;
    overflow-x: hidden;
    border: 0.5px solid #e0dddd;
    border-radius: 3px;
    line-height: 1.2;
}

@media (max-width: 1875px) {
    .mainCardDiv {
        margin-left: 155px;
    }
}

@media (max-width: 1680px) {
    .mainCardDiv {
        margin-left: 60px;
    }

}

@media (max-width: 1440px) {
    .mainCardDiv {
        margin-left: 190px;
    }
}

@media (max-width: 1200px) {
    .mainCardDiv {
        margin-left: 85px;
    }
}

@media (max-width: 1183px) {
    .mainCardDiv {
        margin-left: 80px;
    }

}

@media (max-width: 992px) {
    .mainCardDiv {
        margin-left: 0;
    }

    .center {
        justify-content: center;
    }
}


@media (max-width: 576px) {
    .packDescriptionData {
        margin-top: 29px;
    }

}

.radiobtn {
    padding: 4px;
}

.plandescription {
    margin-top: 22px;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1), 0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    padding: 30px 40px;
}
@media (max-width: 1100px) {
    .plandescription{
        width: 100%;
    }
}
.payment-Standard-Features{
    margin-top: 25px;
}
.payment-Standard-Features .payment-Standard-inner{
   display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.payment-Standard-Features .payment-Standard-inner .payment-Standard-description{
    margin-left: 15px;
}

.plandescription .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #c4c4c4;
}

.plandescription .MuiOutlinedInput-root {
    padding: 0 !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #c4c4c4 !important;
}

.plandescription .MuiFormControl-fullWidth {
    width: 100% !important;
}

.plan-details-section .MuiInputBase-formControl {
    margin-top: 0px !important;
}

.MuiPopover-paper {
    max-height: 331px !important;
}
.mainCardDiv .service_content{
    padding: 70px 0;
}
.mainCardDiv .submin_div{
    margin-right: 100px;
}
.service_plan_table{
    max-width: 800px;
    margin: auto;
}
.column-line{
    width: 208px;
}

.plus-s-and-h{
    color: black;
    font-weight: 500;
}
@media (min-width: 1200px) {
    .mainCardDiv .paymnet-popup {
        max-width: 1450px;
    }
}
.paymnet-popup {
    margin: 0 auto;
}

@media (max-width: 992px) {
    .mainCardDiv .main_div_sarvice {
        flex-wrap: wrap;
    }
    .mainCardDiv .service_content{
        padding: 70px 15px 0;
    }
    .mainCardDiv .submin_div{
        margin-right: 0;
        width: 100%;
    }
    .mainCardDiv .main-container-for-description{
        max-width: 100% !important;
    }
}

@media (max-width: 576px) {

    .main_div_sarvice {
        width: 100%;
    }
    .checkbox_seriveplan {
        flex-wrap: wrap;
    }
    .choose_serive_chackbox {
        width: 100%;
    }
    .choose_serive_chackbox_yearly {
        width: 100%;
        margin: 0 !important;
    }
    .radiobtn {
        margin-left: 0px !important;
        padding: 0px !important;
    }
}

.servicePlanFeatureBox{
    max-width: 1000px;
    margin: auto;
    margin-top: 28px;
}


