.faq_section{
    font-family: 'Poppins', sans-serif;
    padding-top: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
    padding-bottom: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
}

.faq-accordion .accordion-header .accordion-button{
    background-color: #d9eafc;
    border-radius: 15px;
}

.faq-accordion .accordion-header{
    font-size: 20px;
    font-weight: 400;
    color: #282828;
}
.faq-accordion .accordion-body{
    padding: 20px;
    color: #555555;
    font-size: 18px;
    font-weight: 400;
}

@media (max-width: 768px){
    .faq-accordion .accordion-body{
        font-size: 14px;
        padding: 10px;
    }
}