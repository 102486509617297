.footer_details_section {
    padding: 80px 0px;
}
.footer_details img{
    width: 190px;
}
.footer_details  .social_icon img{
    width: 35px;
}

.footer_details p {
    font-family: 'Poppins', sans-serif;
    color: #808080;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    margin-top: 21px;
}

.footer-menu-list {
    list-style-type: none;
    margin-top: 21px;
}

.footer-menu-list .menu-list-title {
    color: #2E2E2E;
    line-height: 24px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 700;
}

.footer-menu-list .menu-list_item {
    margin-top: 24px;
    color: #808080;
    line-height: 32px;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-weight: 400;
}

.footer_bottom {
    margin-top: 10px;
}

.footer_bottom p {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    line-height: 32px;
    font-weight: 400;
}

.footer_bottom p span {
    background: linear-gradient(to right, #007BFF, #92BCEA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
}

.footer_bottom .footer_bottom_link a {
    color: #2E2E2E;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    padding: 0 40px;
}

@media (max-width: 1200px) {
    .footer_bottom {
        min-width: 100%;
    }
    .footer_bottom .footer_bottom_link a {
        padding: 0 14px;
        font-size: 16px;
    }
}

@media (max-width: 992px) {
        .footer_details p,  
        .footer-menu-list .menu-list_item {
            font-size: 14px;
        }
    }

    @media (max-width: 768px){
        .footer-menu-list .menu-list_item{
            margin-top: 10px;
        }
        .footer_details_section {
            padding: 40px 0px;
        }
    }
@media (max-width: 567px) {
.footer_bottom .footer_bottom_link a {
        padding: 0 8px;
        font-size: 13px;
    }
}