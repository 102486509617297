.why_choose_section{
    padding-top:50px;
    padding-bottom: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
}

.why_choose_details .details_title h2 {
    text-align: center;
    font-size: 36px;
    font-family: 'Poppins', sans-serif;
    color: #000;
    font-weight: 600;
    line-height: 54px;
    margin-bottom: 20px;
}

.features_detailsbox .features_details_card {
    min-height: 100%;
    background-image: linear-gradient(to right, #007BFF, #92BCEA);
    border: none;
    border-radius: 30px;
    text-align: center;
}

.features_details_card .features_details_image {
    margin-top: 12px;
}

.features_details h4 {
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 26px;
    font-weight: 600;
    line-height: 39px;
    margin-top: 28px;
}

.features_details p {
    max-width: 360px;
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    line-height: 22px;
    font-weight: 300;
    margin: auto;
}


.fingertips-content .fingertips-image {
    box-shadow: rgba(0, 0, 0, 0.2);
    padding-right: 60px;
}

.fingertips-image img {
    width: 100%;
}

.fingertips-details {
    margin-top: 25px;
    padding-left: 20px;
}

.fingertips-details h2 {
    font-size: 36px;
    font-family: 'Poppins', sans-serif;
    color: #000;
    font-weight: 600;
    line-height: 54px;
}

.fingertips-details h2 span {
    font-weight: 700;
    background: linear-gradient(to right, #007BFF, #92BCEA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.fingertips-details p {
    max-width: 660px;
    color: #808080;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
}


.fingertips-details-badge .pils-badge {
    /* max-width: 712px; */
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-style: italic;
    font-size: 12px;
    line-height: 20px;
    color: #555555;
    background-color: #F3F3F3;
    border-radius: 18px;
    padding: 4px 12px;
    margin-right: 20px;
    margin-top: 18px;
}

.care-details h2 {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 34px;
    line-height: 54px;
}

.care-details h2 span {
    font-weight: 700;
    background: linear-gradient(to right, #007BFF, #92BCEA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.care-details p{
    max-width: 595px;
    font-family: 'Poppins', sans-serif;
    color: #808080;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
}

.video_content {
    position: relative;
}

.video_content::before {
    content: '';
    width: 100%;
    height: calc(100% - 5px);
    position: absolute;
    text-align: center;
    border-radius: 15px;
    border: 2px solid transparent;
    background: linear-gradient(to right, #007BFF, #92BCEA);
    -webkit-mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}
.ktmwcares_img  {
    width: 100%;
    height: fit-content;
    border-radius: 15px;
}


@media screen and (max-width: 768px) {
    .care-details h2 ,
    .details_title h2,
    .fingertips-details h2{
        font-size: 28px !important;
        line-height: 40px ;
    }
    .care-details p,
    .fingertips-details p{
        font-size: 16px ;
    }
    .fingertips-details {
        margin-top: 0;
    }
}

@media screen and (max-width: 567px){
    .fingertips-details-badge .pils-badge {
        margin-right: 8px;
    }
}