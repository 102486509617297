.how_to_video_container{
    display: flex;
    max-width: 1200px;
    margin: auto;
    gap: 50px;
    padding: 10px 50px;

}
.how_to_video_container:not(:last-child){
    border-bottom: 3px solid #c4c4c4;
}
.how_to_video{
    border-radius: 10px;
}
.how_to_header{
    display: none;
}

@media only screen and (max-width: 768px) {
    .how_to_header{
        display: block;
    }
    .how_body_to_header{
        display: none;
    }
}
.video-container{
    position: relative;
}
.video-play-btn{
    background: white;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    position: absolute;
    cursor: pointer;
}
.inner-round{
    background: #7030a0;
    width: 50px;
    height: 50px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.video-play-btn i {
    color: white;
}

.how-to-container .modal-dialog{
    max-width: fit-content;
    margin: auto;
}