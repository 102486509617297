.main_header {
    display: flex;
    justify-content: space-between;
    padding: 20px 110px;
    align-items: center;
}
.text-black {
    color: black !important;
}
.font-bold {
    font-weight: bold !important;
}


.h-60 {
    height: 15rem !important; /* 60 * 0.25rem (Tailwind spacing scale) */
}

.w-60 {
    width: 15rem !important; /* 60 * 0.25rem */
}
.main_header .menu_list .btn {
    padding: 13px 55px;
    font-weight: 600;
}
.main_header .Landing_login_btn {
    color:#008EFF;
    background: #f2f4f7;
}
.main_header .logo_img {
    width:266px;
    cursor: pointer;
}
.menu_bar{
    display: none;
}

@media (max-width: 992px) {
    .main_header{
        padding: 20px 70px;
    }
    .main_header .logo_img{
        width: 50%;
    }
    .main_header .menu_list  {
        display: flex;
        justify-content: end;
    }
    .main_header .menu_list .btn {
        padding: 13px 20px;
        width: 100px;
    }
}

@media (max-width: 768px) {
    .main_header{
        padding: 20px 45px;
    }
    .main_header .menu_list{
        display: none;
    }
    .menu_bar{
        display: block;
    }
}

@media (max-width: 576px) {
    .main_header{
        padding: 20px 15px;
    }
    .menu_list{
        display: none;
    }
    .menu_bar{
        display: block;
    }
    .Landing_signup_btn{
        display: block;
    }
    .mobile_menu .MuiListItem-gutters:not(:last-child){
        border-bottom: 1px solid #CACACA;
    }
}