.plans_container {
    background-image: url("../assets/images/plans_background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-size: 100% 415px;
    background-position: bottom;
    padding: 80px 0;
}

.plans_content_container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
}

.plans_content_container .plans_card {
    width: 522px;
    border-radius: 22px;
    background: #FFF;
    box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.07);
    border: none;
}

.plans_card .plans_card_description {
    padding-left: 25px;
    margin-bottom: 0;
}

.plans_card .plans_heading {
    color: #101010;
    font-family: 'Bai Jamjuree', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 24px;
}

.plans_card .plans_items {
    color: #797979;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    font-family: 'Bai Jamjuree', sans-serif;
    line-height: normal;
    text-transform: capitalize;
    margin-top: 10px;
}

.plans_items::marker {
    font-size: 25px;
}

.plans_card .storages_inner_box {
    Width: 100%;
    height: 257px;
    border-radius: 12px;
    background: #F9F9F9;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
}

.plans_button .outline-primary-btn {
    width: 100%;
    border-radius: 12px;
    border: 1px solid #FF238C;
    background: #FEFEFE;
    color: #FF238C;
    font-size: 16px;
    font-style: normal;
    font-family: 'Bai Jamjuree', sans-serif;
    font-weight: 600;
    line-height: normal;
    padding: 16px;
    margin-top: 24px;
}

.plans_button .outline-btn {
    width: 100%;
    color: #008EFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-radius: 12px;
    border: 1px solid #008EFF;
    font-family: 'Bai Jamjuree', sans-serif;
    background: #FEFEFE;
    padding: 16px;
    margin-top: 24px;
}



@media (max-width: 768px) {
    .plans_container {
        padding: 80px 10px;
    }
}