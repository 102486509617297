.plan_list_table{
    padding-top: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
    padding-bottom: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
}

.plan_list_table .container-fluid{
    max-width: 100%;
    overflow: visible;
}
.pricing_switch{
    display: flex;
    justify-content: center;
    gap: 20px;
}
.pricing_switch p{
    font-size: 16px;
    color: #808080;
    line-height: 32px;
    font-weight: 400;
}

.pricing_switch .react-switch-bg{
    background: linear-gradient(to right, #007BFF, #92BCEA) !important; 
}


.plans-table thead th {
    vertical-align: middle;
    border-bottom: 1px solid #CCCCCC;
}
.plans-table tbody .pricing_table{
    font-family: 'DM Serif Display', serif;
    color: #909494 !important;
    font-weight: 600;
    font-size: 16px !important;
}
.plans-table tbody .pricing_table_header{
    font-family: 'DM Serif Display', serif;
    color: #909494 !important;
    font-weight: 600;
    font-size: 18px !important;
}

.plans-table thead,td,th,tr,
.plans-table tbody,td,th,tr{
    text-align: center;
    border: 1px solid #CCCCCC;
    color: #2E2E2E;
    font-size: 14px;
}

.plans-table .bg-header{
    background-color: #d9eafc;
}

.bg-header .pricing-title{
    font-size: 18px;
    font-weight: 500;
}

.plans-table thead h4{
    color: var(--black);
    font-size: calc(18px + (24 - 18) * ((100vw - 300px) / (1920 - 300)));
    font-weight: 600;
    text-transform: capitalize;
}

.plans-table thead p{
    color: #808080;
    font-size: 14px;
    font-weight: 400;
}
.plans-table thead .price{
    font-size: calc(28px + (48 - 28) * ((100vw - 300px) / (1920 - 300)));
    font-weight: 700;
    background: linear-gradient(to right, #007BFF, #92BCEA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.plans-table thead .price span{
    font-size: 20px;
    font-weight: 500;
}

.plans-table tbody td{
    color: #2E2E2E;
    font-size: 14px !important;
    font-weight: 400;
    padding: 16px;
}

.plans-table tbody th h4{
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
}

.plans-table tbody th:first-child,
.plans-table tbody td:first-child{
    text-align: start;
}

.grediant-outline-btn{
    position: relative;
    color: var(--white);
    padding: 10px 30px;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 0.6px;
    background: linear-gradient(to right, #007BFF, #92BCEA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.grediant-outline-btn::before{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 40px;
    left: 0;
    top: 0;
    border: 2px solid transparent;
    background: linear-gradient(to right, #007BFF, #92BCEA);
    box-shadow:rgba(0, 0, 0, 0.2);
    -webkit-mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}
