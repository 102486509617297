.about_page_section h2 {
    text-transform: capitalize;
    background: linear-gradient(to right, #007BFF, #92BCEA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
    font-size: calc(26px + (35 - 26) * ((100vw - 300px) / (1920 - 300)));
    line-height: 1.4;
}

.about_page_section h5 {
    color: var(--primary);
}

.about_page_section .sub_title {
    margin-top: 1.5rem;
}

.about_page_section {
    padding: 2rem 10rem;
}

.our_story_list {
    list-style: none;
    margin-top: 1rem;
}

.our_story_list li {
    position: relative;
    font-size: 18px;
    color: var(--secondary);
    padding: 0.2rem 0;
    margin-left: 2rem;
}

.our_story_list li span {
    position: absolute;
    top: 10px;
    left: -32px;
    border: 2px solid var(--primary);
    width: 22px !important;
    height: 22px;
    border-radius: 50px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.our_story_list li span svg {
    width: 14px !important;
    height: 14px !important;
}

.our_story_list li::marker {
    font-size: 24px;
}

.about_quote_box_container {
    padding: 2rem 0;
}

.about_quote_section .quote_box_content svg {
    fill: #ed6c02 !important;
    width: 35px !important;
    height: 35px !important;
}

.about_quote_section .quote_box_content h4 {
    margin-bottom: 1rem;
}

.private_storage_section h2 {
    color: var(--dark);
    font-weight: 500;
    font-size: calc(26px + (35 - 26) * ((100vw - 300px) / (1920 - 300)));
    line-height: 1.4;
    padding-bottom: 2rem;
    text-align: center;
    max-width: 70%;
    margin: auto;
}

.private_storage_section h2 span {
    text-transform: capitalize;
    background: linear-gradient(to right, #007BFF, #92BCEA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

.side_image_section img{
    outline: 4px solid var(--white);
    outline-offset: -26px;
}

@media screen and (max-width:1500px) {
    .about_page_section {
        padding: 2rem 3rem;
    }
}

@media screen and (max-width:1200px) {
    .about_page_section .side_image_section img {
        width: 60%;
        display: block;
        margin: auto;
    }

    .private_storage_section .col-xl-4 {
        margin: 0.5rem 0;
    }
    .private_storage_section h2{
        max-width: 100%;
    }
    .side_image_section{
        margin-top: 1rem;
    }
}

@media screen and (max-width:991px) {

    .about_page_section .side_image_section img {
        width: 75%;
    }
}

@media screen and (max-width:768px) {
    .private_storage_section .mx-5 {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    .about_page_section h5{
        line-height: 1.4;
    }
}

@media screen and (max-width:575px) {
    .about_page_section .side_image_section img {
        width: 100%;
    }

    .trial_section p {
        font-size: 20px;
    }

    .about_page_section .sub_title {
        margin-top: 0.5rem;
    }

    .trial_section {
        padding: 30px 0 !important;
    }

    .trial_section p {
        font-size: 17px;
        margin-bottom: 20px;
    }

    .quote_section {
        padding: 1rem;
    }
    .about_page_section h2 {
        line-height: 50px;
    }
    .private_storage_section h2{
        text-align: left;
    }
}

@media screen and (max-width:400px) {
    .private_storage_section .support_card {
        padding: 16px 0;
    }

    .trial_section .button_primary {
        padding: 7px 16px;
        font-size: 14px;
    }

    .about_page_section .sub_title,
    .private_storage_section .support_card p,
    .about_page_section .our_story_list li {
        font-size: 16px;
    }

    .about_page_section .side_image_section {
        margin-top: 30px;
    }

    .private_storage_section {
        padding: 2rem 0 !important;
    }

    .quote_box_content h4 {
        line-height: 30px;
        font-size: 17px;
    }
}