.shop_web_introduction_section {
    padding: 2rem 10rem;
}

.shop_web_introduction_section .img-fluid {
    width: 450px;
}

.asked_questions_section {
    background-image: url('../../../assets/images/shopWeb/background.3d4b6772.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.asked_questions_section .faq .accordion-button::after {
    filter: brightness(1000);
}

.asked_questions_section .faq {
    margin-top: 2rem;
    background-color: transparent;
}

.asked_questions_section .faq .MuiPaper-root {
    background-color: transparent;
}

.asked_questions_section .faq .accordion-button {
    gap: 0.5rem;
    font-weight: 500 !important;
}


.storage_box_list {
    margin-top: 2rem;
}

.storage_box_list .storage_box {
    height: 100%;
    padding: 1rem;
    font-size: 16px;
    color: var(--dark);
    position: relative;
    display: flex;
    gap: 1rem;
    background-color: rgba(0 ,123, 255,.1);
    border: 1px solid #dcdcdc;
    border-radius: 10px;
}

.storage_box_list .storage_box p {
    margin-bottom: 0.5rem;
}

.storage_box_list .storage_box .storage_box_icon {
    width: 80px;
    height: 80px;
    align-content: center;
    text-align: center;
    flex-shrink: 0;
}

.storage_box_icon img {
    width: 40px;
    height: 40px;
}



.trial_section {
    background-image: url('../../../assets/images/shopWeb/pattern.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 3rem 4rem;
}

.trial_section p {
    font-size: 24px;
    color: var(--white);
}



.process_steps_section {
    padding: 2rem 0;
}

.process_steps_section .process_steps_box {
    padding: 20px;
}

.process_steps_section .process_steps_box h2 {
    font-size: 60px;
    font-weight: 700;
    line-height: 90px;
    color: var(--primary);
}

.process_steps_section .process_steps_box p {
    font-size: 18px;
    font-weight: 500;
    color: var(--secondary);
}

.header_section h3 {
    color: gray;
    line-height: 1.4;
    font-weight: 500;
}

.header_section h3 span {
    font-weight: 700;
    text-transform: capitalize;
    background: linear-gradient(to right, #007BFF, #92BCEA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.header_content_section {
    margin-top: 2.5rem;
    list-style: none;
}

.header_content_section li span {
    position: absolute;
    top: 10px;
    left: -32px;
    border: 2px solid var(--primary);
    width: 22px !important;
    height: 22px;
    border-radius: 50px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header_content_section li {
    position: relative;

    padding: 0.2rem 0;
    margin-left: 2rem;

}

.header_content_section li h5 {
    font-size: 18px;
    color: var(--secondary);
    line-height: 26px;
}

.header_content_section li span svg {
    width: 14px !important;
    height: 14px !important;
}

.storage_card {
    height: 100%;
    border-radius: 0;
    border: 1px solid #a7d1ff;
    margin-top: 2rem;
    border-radius: 10px;
}


.storage_card.danger_card {
    background-color: rgba(249, 50, 156, .1);
    border-bottom: 2px solid var(--danger);
}


.storage_card.danger_card .button_primary {
    background-color: var(--danger);
    border: 1px solid var(--danger);
    color: var(--white);
}

.storage_card.danger_card .card-body h4 {
    color: var(--danger);
}

.storage_card .card-body h4 {
    font-size: 26px;
    font-weight: 500;
    color: var(--primary);
}

.storage_card .card-body p {
    margin: 0.5rem 0;
    font-size: 16px;
    line-height: 24px;
    color: var(--dark);
}

.storage_details_table button,
.storage_card button {
    padding: 2px 10px;
    font-size: 12px;
}

.storage_details_table.table-bordered td,
.storage_details_table.table-bordered th {
    border: 1px solid #c5e1ff;
    
}

.storage_details_table {
    margin-top: 2rem;
    background-color: rgba(0 ,123, 255,.1);
}

.storage_details_table h4 {
    font-size: 24px;
    font-weight: 500;
    color: var(--primary);
    text-align: start;
    white-space: nowrap;
}

.storage_details_table th {
    padding: 1rem 1.25rem;
}

.storage_details_table td {
    padding: 1.25rem;
    font-size: 16px;
    line-height: 24px;
    color: var(--dark);
    text-align: left !important;
    vertical-align: top;
}

.process_section_container {
    background-color: var(--dark);
}

.process_section_container .main_title {
    color: var(--white);
}

.process_section_container .sub_title {
    margin-bottom: 0;
}

.process_steps_list {
    margin-top: 2rem;
}

.process_steps_content {
    justify-content: start !important;
}

.process_steps_content,
.process_steps {
    width: 100%;
    height: 100%;
    padding: 20px;
    margin-bottom: 0;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, .1);
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
}

.process_steps P {
    margin-bottom: 0;
    font-size: 16px;
}

.process_steps_content {
    position: relative;
}

.storage_box p {
    position: relative;
    margin-left: 1.5rem;
}

.storage_box svg {
    position: absolute;
    top: 8px;
    left: -20px;
    width: 10px !important;
    height: 10px !important;
    fill: var(--danger);
}

.custom_card{
    display: flex;
    align-items:flex-start;
    justify-content: center;
    margin:20px -15px 0;
}
.custom_card .single_card{
    max-width: 33.33%;
    flex: 0 0 33.33%;
    height:100%;
    padding:0 15px;
    display: flex;
}

.custom_card .single_card .main_card_content{
    padding: 16px 20px;
    border: none;
    background-image: url('../../../assets/images/homeWeb/bg-7.png');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
}
.custom_card .single_card.pink_content .main_card_content{
    background-image: url('../../../assets/images/homeWeb/bg-6.png');
}
.custom_card .single_card .main_card_content .card_h4{
    max-width: 310px;
    margin: 0 auto;
    text-align: center;
    padding: 20px 20px 0 20px;
    color: var(--primary);
}
.custom_card .single_card.pink_content .main_card_content .card_h4{
    color: var(--danger);
}
.custom_card .single_card .main_card_content .card_para{
    margin-top: 15px;
    padding: 0 20px 20px 20px;
    font-size: 18px;
    color: var(--secondary);
}
.main_card_content .card_para .card_para_in button{
    max-width: 75px;
    width:100%;
    padding: 2px 10px;
    font-size: 12px;
    margin-left: 0!important;
    flex-grow: 1;
}
.custom_para_main{
    max-width: 1000px;
}
.card_para_in{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.card_para_in p{
    margin: 0;
}
.card_para_bold{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 16px;
}
.custom_card_tabel{
    width: 100%;
}
.custom_card_tabel td{
    border:none;
    font-weight: 600;
    text-align: left;
}
.custom_card_tabel tr{
    border: none;
}
.table_button{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.table_button button{
    margin: 10px 0!important;
    max-width: 75px;
    width: 100%;
    padding: 2px 10px;
    font-size: 12px;
    flex-grow: 1;

}
.section_container.storage_options_section{
    max-width:1440px;
    margin: 0 auto;
}
.table_button.litte_wide button{
    max-width: 150px;
    min-width:  150px;
    margin-left: 10px !important;
}

@media screen and (max-width:1550px) {
    .storage_box_list .storage_box {
        flex-direction: column;
    }
}

@media screen and (max-width:1440px) {
    .shop_web_introduction_section {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width:1200px) {
    .custom_card .single_card{
        max-width: 100%;
        flex: 0 0 100%;
    }
    .custom_card{
        flex-direction: column;
        gap: 20px;
    }
    .custom_div{
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {
    .shop_web_introduction_section {
        padding: 2rem 1rem;
    }

    .storage_card {
        margin-top: 0;
    }
}

@media screen and (max-width: 992px) {
    .storage_options_section .col-lg-4 {
        margin-top: 1rem;
    }
}
@media screen and (max-width: 576px) {
    .storage_details_table thead {
        display: none;
    }
    .storage_details_table h4{
        white-space: normal;
    }
    .storage_details_table.table-bordered td{
        margin: calc(1px * -1);
    }
    .custom_card .single_card .main_card_content .card_para{
         margin-top: 15px;
         padding: 0;
         font-size: 14px;
     }
    .custom_card .single_card .main_card_content .card_h4{
        font-size: 22px;
    }
    .table_button.litte_wide button{
        max-width: 100px;
        min-width:  100px;
    }

}
@media screen and (max-width: 567px) {
    .section_container {
        padding: 2rem 1rem;
    }

    .faq {
        padding: 0;
    }

    .process_steps_content {
        margin-top: 0.5rem;
    }

    .process_steps_list {
        margin-top: 1rem;
    }

    .asked_questions_section .container {
        padding: 0;
    }

    .storage_box svg {
        left: -20px;
    }

    .storage_box p {
        margin-left: 1.5rem;
    }

    .storage_details_table {
        display: flex;
        flex-direction: column;
    }



    .storage_details_table th,
    .storage_details_table td {
        display: block;
        padding: 0.5rem;
    }
    .storage_details_table h5{
        white-space: wrap;
        color: var(--primary);
    }
    .storage_details_table span{
        font-size: 14px;
    }
    .storage_details_table button{
        font-size: 10px;
    }
    .storage_details_table td:nth-child(2),
    .storage_details_table td:nth-child(3){
        min-width: 100%;
    }
}