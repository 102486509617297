.asset-sheet-page{
    display: none;
}
.asset-sheet-page label{
    font-weight: bold;
    font-size: 24px;
}
.asset-sheet-page .form-control{
    font-size: 19px !important;
}

.asset-sheet-page .header .title{
    color: #0070c0;
    font-size: 44px;
    font-weight: bold;
    margin: 0;
}
.asset-sheet-page .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.asset-sheet-page .rich_text_html_lg p,
.asset-sheet-page .rich_text_html p{
    margin: 0;
    font-size: 20px;
}
.asset-sheet-page .rich_text_html_lg{
    min-height: 190px;
    height: fit-content;
}
.asset-sheet-page .rich_text_html{
    min-height: 85px;
    height: fit-content;
}

.asset-sheet-page .asset-sheet-title{
    color: #0070c0;
    display: block;
    text-align: center;
    margin-top: 30px;
}

.asset-sheet-page .asset_info_card .title_info{
    color: #ff0000;
    font-weight: 600;
    margin-bottom: 24px !important;
}
.asset-sheet-page .asset_info_card .form-label{
    font-weight: 600;
    font-size: 22px;
}
.asset-sheet-page .asset_info_card{
    background-color: #fedee7;
    -webkit-print-color-adjust: exact;
    border: 1px solid #ced4da;
    margin: 20px 0;
    padding: 15px;
    border-radius: 5px;
}

.asset-sheet-page .line_text{
    padding: 5px 0;
    font-size: 20px;
    border-bottom: 2px solid #5881ca;
}