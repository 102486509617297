.section-title {
    text-align: center;
}

.section-title h2 {
    color: var(--black);
    font-size: calc(30px + (36 - 30) * ((100vw - 300px) / (1920 - 300)));
    font-weight: 600;
    text-transform: capitalize;
}

.section-title p {
    color: #808080;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: calc(34px + (30 - 24) * ((100vw - 300px) / (1920 - 300)));
}

.grediant-card {
    border-radius: 16px;
    background: linear-gradient(to right, #007BFF, #92BCEA);
    color: var(--white);
    padding: 30px;
    border: 0 !important;
}
.grediant-card-values{
    border-radius: 16px;
    background: linear-gradient(to right, #007BFF, #92BCEA);
    color: var(--white);
    padding: 30px;
    height: 290px;
}

.content-right {
    padding-left: 85px;
}

.value-card {
    padding: 0;
    font-family: 'Poppins', sans-serif;
}

.value-card img {
    position: absolute;
    height: 55px;
}

.content-right p {
    font-size: calc(16px + (16 - 16) * ((100vw - 300px) / (1920 - 300)));
    font-weight: 300;
    margin-bottom: 0;
}

.content-right h4 {
    font-size: calc(22px + (26 - 22) * ((100vw - 300px) / (1920 - 300)));
    margin-bottom: 15px;
}

@media (max-width: 768px) {
    .our_value .col-lg-6+.col-lg-6 {
        margin-top: 30px;
    }
}

@media (max-width: 567px) {

    .content-right {
        padding-left: 0px;
        padding-top: 60px;
    }
}