.faq-inner-content h5,.faq-inner-content p{
    display: flex;
}

.faq-inner-content h5 span{
    padding-left: 5px;
}

.themeBackgroundImage{
    background-image: url("../PrivatePeople3LandingNew/assest/images/background.svg");
    background-repeat:no-repeat;
    background-size:contain;
    background-position:top;
    height: 1264px;
    width: 99vw;
    position: absolute;
}

.faq-inner-content p span{
    padding-left: 11px;
}