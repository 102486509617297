.plan_list{
    padding-top: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
    padding-bottom: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
}

.plan_list_card {
    font-family: 'Poppins', sans-serif;
    min-height: 400px;
    padding: 4px;
    border: none;
    border-radius: 15px;
}

 .plan_bg_card{
    font-family: 'Poppins', sans-serif; 
    min-height: 400px;
    background:  linear-gradient(to right, #007BFF, #92BCEA);
    padding: 4px;
    border: none;
    border-radius: 15px;
}
 .plan_card_pricing{
     background:  linear-gradient(to right, #007BFF, #92BCEA);
     padding: 4px;
     border: none;
     border-radius: 15px;
 }
 .plan_bg_card_pricing{
     padding: 4px;
     border: 1px solid #007BFF;
     border-radius: 15px;
 }
 .plan_bg_card_pricing_text{
     color: white;
     font-size: calc(28px + (48 - 28) * ((100vw - 300px) / (1920 - 300)));
     font-weight: 700;
     text-transform: capitalize;
     text-align: center;
     margin-bottom: 0px;
 }
.plan_bg_card_pricing_text span{
    font-size: 20px;
    font-weight: 500;
 }
.plan_bg_card .card-title,  
.plan_bg_card .plan_list_menu ul li,
.plan_bg_card  .plan_list_menu ul li::before{
    color: #fff !important;
}


.plan_list_card::before {
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
    border-radius: 15px;
    border: 2px solid transparent;
    background: linear-gradient(to right, #007BFF, #92BCEA);
    box-shadow: rgba(0, 0, 0, 0.2);
    -webkit-mask:
        linear-gradient(#fff 0 0) padding-box,
        linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
}

.plan_bg_card .card-title ,
.plan_list_card .card-title {
    color: var(--black);
    font-size: calc(20px + (24 - 20) * ((100vw - 300px) / (1920 - 300)));
    font-weight: 600;
    text-transform: capitalize;
    line-height: 32px;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    padding: 24px;
}


.plan_list_menu ul{
   padding-left: 20px;
}

.plan_list_menu ul li {
    color: #808080;
    font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1920 - 300)));
    font-style: normal;
    font-weight: 400;
    padding-top: 20px;
    padding-left: 20px;
    list-style-type: none;
}


.plan_list_menu ul li::before {
    content: "✔";
    color: #000;
    position: absolute;
    left: 40px;
}

@media (max-width: 768px){
    .plan_list_card,
    .plan_bg_card{
        min-height: 100%;
    }
    .plan_bg_card .card-title, .plan_list_card .card-title {
        padding: 0px;
    }
    .feature_right,
    .feature_left{
        margin: 20px 0 !important;
    }

    .features_list img{
       width: 100%;
    }

}