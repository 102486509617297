.App {
    font-family: sans-serif;
}

.App > h1 {
    font-size: 22px;
    background: #222;
    color: white;
    padding: 3px;
    border-radius: 5px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
a.custom-back-child{
    color:black;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
}
a.custom-back-child:hover{
    text-decoration: underline;
    color:black;
}
.App > h1 > img {
    width: 20px;
}

.App > div > h2 {
    font-size: 18px;
    display: flex;
    justify-content: space-between;
}

.book {
    display: flex;
    padding: 12px;
    margin: 0 0 20px 0;
    transition: all 0.5s;
    border-bottom: 1px solid grey;
    width: 100%;
}

.book img {
    width: 190px;
    margin: 0 10px 0 0;
}

.cart_Page {
    display: flex;
    justify-content:space-around;
    padding: 20px 36px;
}
.Product_Section{
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
    padding: 0 15px;
}
.inner-product{
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
    border-radius: 15px;
    padding: 15px;
}
.Payment_card{
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding: 0 15px;
}
.cart_Page .cart_list_table tbody tr .cart_prices,
.cart_Page .cart_list_table thead .cart_prices{
    vertical-align: middle;
}

.custom-back-perent{
    position:relative;
}
.custom-back-child{
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 12px;
    left: 0;
}
.custom-promocode{
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    position: absolute;
    top: -45px;
    right: 0;
}
.custom-promocode input{
    width: 150px;
    flex-grow: 1;
    border: 1px solid gray;
}
.infinity_loader {
    display: flex;
    align-items: center;
    justify-content: center;
}

.infinity_loader i {
    font-size: 30px;
    animation: spin 2s linear infinite;
}
.sucess_text div{
    font-weight: 500;
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}
.custom-promocode span{
    font-weight: bold;
}
.custom_shipping{
    font-size:12px;
    font-weight:bold;
    width: 100%;
    margin: 0;
}
.Delete{
    bottom: 0;
    right: 0;
}
.shop_cart.button_primary{
    padding: 0 10px;
    border-radius: 5px;
    font-weight:400;
    font-size:14px;
}
.custom-count button:nth-child(2){
    width: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.custom-count button{
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.mobile_cart .outer-image img{
    max-width: 180px;
    width: 100%;
    max-height:180px;
    height:100%
}
.shiping_chage span{
    font-size: 16px;
    font-weight: bold;
}
.inner-product {
    padding-top:50px;
}

.shop_cart.button_primary:disabled {
    background-color: #d6d6d6;
    color: #a1a1a1;
    cursor: not-allowed;
    opacity: 0.65;
}

@media(min-width:768px){
    .mobile_cart{
        display: none;
    }
}
@media(max-width:1024px){
    .cart_Page{
        flex-direction: column;
    }
    .Product_Section, .Payment_card{
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
    }
    .inner-product h1{
        font-size: 24px;
        line-height: 1.4;
    }
    .custom-back-child{
        top: 7px;
    }
    .table thead th{
        font-size: 16px !important;
    }
    .table tbody tr td h5{
        font-size: 16px;
    }
    .Payment_card .container{
        max-width: 100%;
    }
    .Payment_card{
        margin-top: 20px;
    }
    .Payment_card h2{
        font-size: 24px;
        line-height: 1.4;
    }
    .cart-Table{
        margin-bottom: 20px;
    }
}
@media(max-width:767px){
    .cart-Table{
        display: none;
    }
    .inner-product {
        padding-top:60px;
    }
}

@media(max-width:660px){
    .cart-Table{
        max-width: 540px;
        width: 100%;
        overflow-x: scroll;
    }
    .custom-promocode{
        right:0;
        left: 0;
        top: -60px;
        justify-content:start;
    }
}
@media(max-width:576px){
    .cart_Page{
        padding: 20px 15px;
    }
    .shiping_chage,.shiping_chage span{
        font-size: 12px;
    }
}
@media(max-width:425px){
    .mobile_cart > div > div:first-child{
        flex-direction: column;
    }
    .outer-image{
        padding: 0 !important;
        align-self: center;
    }
    .mobile_cart h5{
        margin-top:10px;
    }
}