.main_footer {
    background: #f2f4f7;
    padding: 28px 28px 0;
}

.main_footer .logo_img {
    margin-bottom: 20px;
}

.social_media_icons {
    margin-bottom: 24px;
}

.social_media_icons ul {
    list-style: none;
    display: flex;
}

.social_media_icons ul .social_media_items {
    padding: 0 10px;
}

.social_media_icons ul .social_media_items a {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: #008EFF;
    display: flex;
    align-items: center;
    justify-content: center;
}

.social_media_icons ul .social_media_items svg {
    color: white;
}

.footer_menu {
    padding: 20px;
    border-bottom: 1px solid #CACACA;
}

.footer_menu ul {
    list-style: none;
    display: flex;
}

.footer_menu ul li:not(:last-child) {
    padding-right: 52px;
}

.footer_menu ul li a {
    font-size: 22px;
    color: #404040;
}

.copyright {
    padding: 15px;
}

.new_landing_page .main_footer {
    background: #F6FAFE;
}

.new_landing_page .main_footer .social_media_icons .social_media_items p {
    background: rgb(0, 123, 255);
    height: 30px;
    width: 30px;
    text-align: center;
    border-radius: 50px;
    line-height: 28px;
}

.new_landing_page .main_footer .copyright p{
    color: #000;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.542px;
}

@media (max-width: 768px) {
    .footer_menu ul li:not(:last-child) {
        padding-right: 0px;
    }
    .footer_menu ul {
       align-items: center;
      flex-direction: column;
    }
    .footer_menu ul li a {
        font-size: 20px;
    }
}