.our_story{
    padding-top: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
    padding-bottom: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
}

.discover_peace{
    width: 700px;
    border: 0px solid #C9C9C9;
    border-radius: 15px;
    padding: 10px 30px;
    font-weight: bold;
    box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
}

@media (max-width: 768px) {
    .about-right .section-title{
        width: 100%;
    }
    .our_story .col-lg-5{
        text-align: center;
        margin-top: 20px;
    }
}
