:root {
    --black: #000;
    --white: #fff;
}
.landing_header{
    background-color: var(--white);
    box-shadow: none;
    padding: 36px;
}

.header_menu_list{
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.header_menu_list .menu_list_item{
    font-family: 'Poppins', sans-serif;
    text-align: center;
    padding: 14px 25px;
    text-decoration: none;
    display: inline;
}

.menu_list_item a{
    font-size: 16px;
    color: var(--black);
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
}

.menu_list_item.active a{
    color: #92BCEA;
    font-weight: bold;
}

.menu_list_item .header_menu_outlinebtn{
    border: 1px solid #007BFF;
    border-radius: 40px;
    background-color: var(--white);
    padding: 12px 38px;
    color: var(--black);
    font-weight: 600;
    font-size: 16px;
}

.menu_list_item .header_menu_btn{
    background-image: linear-gradient(to right, #007BFF, #92BCEA);
    color: var(--white);
    border-radius: 40px;
    padding: 12px 30px;
    margin-left: 20px;
    font-weight: 600;
    font-size: 16px;
}

.bgheader-main{
    background: linear-gradient(to right, #007BFF, #92BCEA);
}
.bgheader-main .landing_header{
    background-color: transparent;
}
.menu_list_item.active a{
    color: var(--black)000;
}
.menu_list_item a{
    color: var(--white);
}
.menu_list_item .header_menu_outlinebtn{
    background-color: transparent;
    color: var(--white);
    border: 1px solid var(--white);
}
.menu_list_item .header_menu_btn{
    background: var(--white);
}
.menu_list_item .header_menu_btn span{
    background:linear-gradient(to right, #007BFF, #92BCEA);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.menu-title{
    padding-bottom: calc(40px + (80 - 40) * ((100vw - 300px) / (1920 - 300)));
    color: var(--white);
}

.menu-title h2{
    font-family: 'Poppins', sans-serif;
    font-size: calc(30px + (45 - 30) * ((100vw - 300px) / (1920 - 300)));
    letter-spacing: 1px;
}
.menu-title p{
    font-family: 'Poppins', sans-serif;
    font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1920 - 300)));
    letter-spacing: 0.6px;
}

/* .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1230px;
} */

@media (max-width: 1200px) {
    .landing_header .header_menu_list .menu_list_item {
        padding: 14px 10px;
    }
    .inroduction_details_video video {
        width: 100%;
        height: 100%;
    }
}

@media (max-width: 992px){
    .landing_header{
        padding: 26px;
    }
    .menu_list_item .header_menu_outlinebtn {
        border-radius: 40px;
        padding: 6px 30px;
    }
    .menu_list_item .header_menu_btn {
        padding: 6px 18px;
        margin-left: 10px;
    }
}


@media (max-width: 768px) {
    .landing_header .header_menu_list .menu_list_item {
        display: none;
    }
    .landing_header .menu_bar {
        display: block;
    }
}