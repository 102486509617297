.features_list{
    padding-top: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
    padding-bottom: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
}

.feature_right,
.feature_left {
    margin: auto 0;
    font-family: 'Poppins', sans-serif;
}

.feature_right h3,
.feature_left h3 {
    color: var(--black);
    font-size: calc(28px + (36 - 28) * ((100vw - 300px) / (1920 - 300)));
    font-weight: 600;
    text-transform: capitalize;
}
.feature_right h3 span{
    text-transform: capitalize;
    background:  linear-gradient(to right, #007BFF, #92BCEA);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.feature_right p,
.feature_left p {
    color: #808080;
    font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1920 - 300)));
    font-style: normal;
    font-weight: 400;
}

.feature-details-badge .pils-badge {
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    font-style: italic;
    font-size: 12px;
    line-height: 20px;
    color: #555555;
    background-color: #F3F3F3;
    border-radius: 18px;
    padding: 4px 12px;
    margin-right: 20px;
    margin-top: 18px;

}

.feature_left ul {
    margin-left: 20px;
    color: #808080;
    font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1920 - 300)));
    font-style: normal;
    font-weight: 400;
}

.feature_left ul li::marker {
    font-size: 1.5rem;
}

@media (max-width: 768px){
    .features_list {
        padding: 40px 20px;
    }
}