.contact_form_section{
    font-family: 'Poppins', sans-serif;
    padding-top: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
    padding-bottom: calc(40px + (70 - 40) * ((100vw - 300px) / (1920 - 300)));
}

.contact_form{
    background-color: #d9eafc;
    padding: 20px;
    border-radius: 24px;
}

.contact_form .form-control{
    padding: 24px;
    background-color: transparent;
    border-radius: 16px;
    border: 1px solid #007BFF !important;
}

.contact_form_btn{
   display: flex;
   justify-content: end;
}

.field-error{
    font-size: 12px;
    color: red;
    padding-left: 15px;
}

@media (max-width: 768px){
    .contact_form_section {
        padding: 40px 20px;
    }
}